import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import solidGauge from 'highcharts/modules/solid-gauge';
import styles from './sharedPageStyles.module.scss';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import {makeAColour} from "../functions";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);
solidGauge(Highcharts);

const CropGauge = (props) => {
  const effectDone = useRef(false);

  useEffect(() => {
    if (effectDone.current === true) {
    }

    return () => {
      effectDone.current = true;
    }
  }, []);

  const gaugeColour = makeAColour(props.GaugeColour);
  const targetText = `Max Crops Per Year: ${props.GaugeMax}`;

  const getChartOptions = () => {
    return {
      chart : {
        type : 'solidgauge',
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      title : {
        text : null,
      },
      pane: {
        center: ["50%", "50%"],
        size: "80%",
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#ededed',
          innerRadius: "70%",
          outerRadius: "100%",
          shape: "arc"
        }
      },
      tooltip: {
        enabled: false,
      },
      yAxis : {
        stops:[
          [0, gaugeColour],
        ],
        min: 0,
        max: props.GaugeMax,
        labels: {
          y: 15,
          distance: 20,
        },
        title : {
          text : props.Title,
          y: 10,
        },
        startOnTick: false,
        endOnTick: false,
        tickInterval: 0.2,
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      series: [{
        name: props.Title,
        data: [{
          name: props.Title,
          radius: 90,
          innerRadius: 70,
          y: props.GaugeValue,
        }],
        radius: '100%',
        rounded: false,
        dataLabels: {
          y: -15,
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:20px">{y}</span><br/>' +
            `<span style="font-size:12px;opacity:0.4">${targetText}</span>` +
            "</div>"
        }
      }],
    }
  };

  return (
    <div className={styles.gaugeContainer}>
      <HighchartsReact
        highcharts={Highcharts}
        options={getChartOptions()}
        constructorType={ 'chart' }
      />
    </div>
  );
};

CropGauge.propTypes = {
  GaugeColour: PropTypes.string.isRequired,
  GaugeMax: PropTypes.number.isRequired,
  GaugeValue: PropTypes.number.isRequired,
  Title: PropTypes.string.isRequired,
}

export default CropGauge;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Typography, Box, Divider } from '@mui/material';

import analysisDataHelper from '../data/analysisDataHelper';
import configHelper from '../data/configHelper';
import LoadingComponent from '../components/LoadingComponent';
import CheckboxGroup from '../components/CheckboxGroup';
import componentStyle from './SOIAnalysisContainer.module.scss';

class SOIAnalysisContainer extends Component {
    constructor(props) {
        super(props);

        this.modalStyle = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };

        this.state = {
            showModal: false,
            months: [],
            availablePhases: [],
            loadingState: 'loading',
            error: {}
        };
    }

    exceedingMaxScenarioCount() {
        var maxScenarios = configHelper.MAX_SCENARIOS;

        if (this.props.selectedPhases.length === 0) {
            return false;
        } else {
            // we are going to create a new scenario result for each different 'phase'
            // applied to an initial scenario.
            return (this.props.numberOfScenarios * this.props.selectedPhases.length) > maxScenarios;
        }

    }

    onSOIMonthSelectionChange = (event, value) => {
        let checked = value;
        let eventValue = checked ? event.target.value : "";
        let available_phases = [];

        if (checked) {
            available_phases = this.state.months.find(month => eventValue === month.key).available_phases;
        }

        this.setState({
            availablePhases: available_phases
        });

        this.props.handleSOISelectionChange('month', eventValue);
    }

    onSOIPhaseSelectionChange = (event, value) => {
        let checked = value;
        let eventValue = parseInt(event.target.value);

        let selectedPhases = this.props.selectedPhases;

        if (checked) {
            selectedPhases.push(eventValue);
        } else {
            selectedPhases = selectedPhases.filter(phase => phase !== eventValue);
        }

        this.props.handleSOISelectionChange('phases', selectedPhases)
    }

    handleSoiAnalysisApply = (event) => {
        event.preventDefault();
        this.setState({
            showModal: false
        });
        this.props.applySoiAnalysis(this.props.selectedMonth, this.props.selectedPhases);
    }

    handleSoiAnalysisClear = (event) => {
        event.preventDefault();
        this.props.handleSOISelectionClear();
    }

    getButtonVariant() {
        if (this.props.selectedMonth !== "" && this.props.selectedPhases.length > 0) {
            return "contained";
        } else {
            return "outlined";
        }
    }

    componentWillMount() {
        var self = this;
        analysisDataHelper.soiAnalysisOptions()
            .then(function(response) {
                // we want the months sorted by their index (in order from beginning to end of year)
                var months = response.data.soi_info.sort((a,b) => a.index - b.index);
                self.setState({
                    months: months,
                    loadingState: 'success'
                });
            })
            .catch(function(error) {
                self.setState({
                    loadingState: 'error',
                    error: error
                });
            });
    }

    haveOptionsLoaded() {
        if (this.state.loadingState === 'success' && this.state.months.length > 0) {
            return true;
        }
        return false;
    }

    render() {
        let hasPhases = false;
        if (this.props.selectedMonth !== '' &&  this.props.selectedPhases.length > 0) {
            hasPhases = true;
        }
        const clearButtonDisabled = hasPhases === false;
        const applyButtonDisabled = this.exceedingMaxScenarioCount() || hasPhases === false;

        return (
            <div className={componentStyle.container}>
                <Button
                    sx={{m: 1, p: .5,  width: 180}}
                    variant={this.getButtonVariant()}
                    disabled={this.props.disabled}
                    onClick={() => {this.setState({showModal: true})}}
                >
                    SOI
                </Button>
                <Modal
                    open={this.state.showModal}
                    onClose={() => {this.setState({showModal: false})}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={this.modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            SOI Analysis
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Select a single SOI month and single/multiple SOI phases and click "Apply" to reload the scenarios with SOI analysis applied.
                        </Typography>
                        <Divider sx={{margin: "1em 0"}} />
                        <CheckboxGroup
                            label={"SOI Months"}
                            controlStyling={{width: "50%"}}
                            options={!this.haveOptionsLoaded() ? [] : this.state.months.map((month) => { return month['key'] })}
                            optionLabels={!this.haveOptionsLoaded() ? [] : this.state.months.map((month) => { return analysisDataHelper.getSOIMonthLabel(month['key']) })}
                            horizontal={false}
                            isChecked={(option) => {return this.props.selectedMonth === option;}}
                            isDisabled={(option) => {return !this.props.selectedMonth === "" && this.props.selectedMonth !== option;}}
                            onChange={this.onSOIMonthSelectionChange}
                        />
                        <CheckboxGroup
                            label={"SOI Phases"}
                            controlStyling={{width: "50%"}}
                            options={this.props.selectedMonth ? this.state.availablePhases : []}
                            optionLabels={this.props.selectedMonth ? this.state.availablePhases.map((phase) => {return analysisDataHelper.getSOIPhaseLabel(phase)}) : []}
                            horizontal={false}
                            isChecked={(option) => {return this.props.selectedPhases.indexOf(option) !== -1;}}
                            onChange={this.onSOIPhaseSelectionChange}
                        />
                        { this.state.loadingState === 'loading' &&
                            <div>
                                <LoadingComponent
                                    fullwidth={true}
                                    state='loading'
                                />
                            </div>
                        }
                        {this.state.loadingState === 'error' &&
                            <div>
                                <LoadingComponent
                                    fullwidth={true}
                                    state='error'
                                    error={this.state.error}
                                />
                            </div>
                        }
                        { this.exceedingMaxScenarioCount() &&
                            <Typography variant="subtitle1">
                                Applying selected SOI analysis to your current list of scenarios would exceed the scenario limit. Please reduce
                                the number of SOI phase selections, or reduce the overall number of scenarios.
                            </Typography>
                        }
                        <Box sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                            <Button
                                variant={"outlined"}
                                sx={{marginRight: "1em"}}
                                disabled={clearButtonDisabled}
                                onClick={this.handleSoiAnalysisClear}
                            >
                                Clear SOI Analysis
                            </Button>
                            <Button
                                variant={"contained"}
                                disabled={applyButtonDisabled}
                                onClick={this.handleSoiAnalysisApply}
                            >
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }
}

SOIAnalysisContainer.propTypes = {
    disabled: PropTypes.bool,
    numberOfScenarios: PropTypes.number,
    selectedMonth: PropTypes.string,
    selectedPhases: PropTypes.arrayOf(PropTypes.number),
    handleSOISelectionChange: PropTypes.func,
    handleSOISelectionClear: PropTypes.func,
    applySoiAnalysis: PropTypes.func,
};

SOIAnalysisContainer.defaultProps = {
    disabled: false,
    selectedMonth: '',
    selectedPhases: [],
};

export default SOIAnalysisContainer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Tabs, Tab } from '@mui/material';

import CheckboxGroup from '../../components/CheckboxGroup';
import FASiteDataHelper from "../../data/FallowARM/FASiteDataHelper";
import MapWrapper from '../../components/MapWrapper';
import pageStyles from '../SiteSelectionStep.module.scss';
import sharedStyles from "../sharedPageStyles.module.scss";
import LoadingComponent from '../../components/LoadingComponent';

class FASiteSelectionStep extends Component {
  constructor(props) {
    super(props);
    this.mapChild = React.createRef();
    this.state = {
      // value: ? // this is set within self.setState in componentWillMount() - once the siteByState information has loaded
      sites: [],
      loadingState: 'loading',
      error: {},
      value: '',
    };
  }

  onTabChange = (event, value) => {
    this.setState({
      value: value,
    });
  };

  onSiteSelection = (event, value) => {
    let locationName = event.target.value;
    let locationCoordinates = this.state.siteLatLongInfo.find(element => element.site === locationName);
    this.mapChild.current.setMapCenter(locationCoordinates.lat, locationCoordinates.lng);
    this.props.handleSiteSelection(locationName, value);
  }

  isChecked = (site) => {
    return this.props.selectedSites.indexOf(site) !== -1;
  }

  isDisabled = (site) => {
    // Is this the checked checkbox ?
    return !this.isChecked(site) &&
      // Are we in singleSelectOnly mode ?
      this.props.singleSelectOnly &&
      // Has an option been selected ?
      this.props.selectedSites.length > 0;
  }

  handleMapIconClick = (event, value) => {
    let site = value.site;
    // should we action this click, because we are removing this site?
    if (this.isChecked(site)) {
      this.props.handleSiteSelection(site, false);
    }

    //should we action this click, because we can still add a site?
    if (!this.isDisabled(site) && !this.isChecked(site)) {
      this.props.handleSiteSelection(site, true);
    }

    // let us also change the active tab group, so the proper state is highlighted
    // when the map icon has been clicked
    this.onTabChange(null, value.state);
  }

  componentDidMount() {
    var self = this;
    FASiteDataHelper.sitesFromAPI()
      .then(function(response) {
        var sites = response.data.sites;
        var sitesByState = FASiteDataHelper.sitesByState(sites);
        var siteLatLongInfo = FASiteDataHelper.siteLatLongInfo(sites);
        self.setState({
          sites: sites,
          sitesByState: sitesByState,
          siteLatLongInfo: siteLatLongInfo,
          loadingState: 'success',
          value: Object.keys(sitesByState)[0] // let's just use the first one in the list
        });
      })
      .catch(function(error) {
        self.setState({
          loadingState: 'error',
          error: error
        });
      });
  }

  render() {
    return (
      <Box className={sharedStyles.scenarioStepSection}>
        {this.state.loadingState === 'loading' &&
          <div>
            <LoadingComponent
              fullwidth={true}
              state='loading'
            />
          </div>
        }
        {this.state.loadingState === 'error' &&
          <div>
            <LoadingComponent
              fullwidth={true}
              state='error'
              error={this.state.error}
            />
          </div>
        }
        {this.state.loadingState === 'success' &&
          <div>
            <Typography variant="h6">
              Site Selection
            </Typography>
            <Typography variant="body1" gutterBottom>
              Select site to analyse.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", alignItems: "stretch" }}>
              <Box sx={{ flexGrow: 0 }}>
                {this.state.loadingState === 'success' &&
                  <Tabs
                    orientation="vertical"
                    value={this.state.value}
                    variant="scrollable"
                    onChange={this.onTabChange}
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                  >
                    {Object.keys(this.state.sitesByState).map((state) => {
                      return (
                        <Tab value={state} key={state} label={state} />
                      )
                    })}
                  </Tabs>}
              </Box>
              <Box sx={{ flexGrow: 0, flex: 1 }}>
                {Object.keys(this.state.sitesByState).map((state) => {
                  return (
                    <div key={state} hidden={this.state.value !== state}
                         id={`vertical-tabpanel-${state}`}
                         className={pageStyles.siteSelectionSection}>
                      <Box overflow="auto" sx={{ flexGrow: 1, padding: "0.5em"}}>
                        <CheckboxGroup
                          options={this.state.sitesByState[state]}
                          horizontal={false}
                          isChecked={this.isChecked}
                          isDisabled={this.isDisabled}
                          onChange={this.onSiteSelection}
                        />
                      </Box>
                    </div>
                  )
                })}
              </Box>
              <Box overflow="auto" sx={{ flexGrow: 2, padding: "0.5em"}} className={pageStyles.hiddenMobile}>
                <MapWrapper
                  ref={this.mapChild}
                  markerPoints={this.state.siteLatLongInfo}
                  handleIconClick={this.handleMapIconClick}
                  selectedSites={this.props.selectedSites}
                  center={this.state.mapCenter}
                />
              </Box>
            </Box>
            <Box sx={{ width: "100%", marginTop: "1em" }}>
              <Typography variant="body1">
                Site Selected: {this.props.selectedSites.join(', ') || 'None'}
              </Typography>
              {this.props.singleSelectOnly && this.props.selectedSites.length > 0 && (
                <Typography variant="caption">
                  Only one site can be selected.
                </Typography>
              )}
            </Box>
          </div>
        }
      </Box>
    )
  }
}

FASiteSelectionStep.propTypes = {
  handleSiteSelection: PropTypes.func,
  selectedSites: PropTypes.array,
  singleSelectOnly: PropTypes.bool,
};

export default FASiteSelectionStep;

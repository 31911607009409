import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import InfoIcon from '@mui/icons-material/Info';
import styles from './InfoButton.module.scss';
import {Popper, Tooltip} from "@mui/material";
import {ClickAwayListener} from "@mui/base/ClickAwayListener";
import Fade from '@mui/material/Fade';
import {selectInfoItemByKey} from "../features/infoDataSlice";
import useWindowSize from "../ra_Helpers/useWindowSize";

const validPositions = ['bottom-end', 'bottom-start', 'bottom',
                        'left-end', 'left-start', 'left',
                        'right-end', 'right-start', 'right',
                        'top-end', 'top-start', 'top', 'auto'];

const InfoButton = (props) => {
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [placement, setPlacement] = useState('right-end');
  const [childNode, setChildNode] = useState(null);
  const windowSize = useWindowSize();

  const infoDetail = useSelector((state) => selectInfoItemByKey(state, props.InfoKey));

  useEffect(() => {
    if (props.Position) {
      if (props.Position !== '') {
        if (validPositions.indexOf(props.Position.toLowerCase()) !== -1) {
          setPlacement(props.Position.toLowerCase());
        }
      }
    }
    if (props.AnchorTo) {
      if (props.AnchorTo !== '') {
        const thisAnchor = document.getElementById(props.AnchorTo);
        if (thisAnchor) {
          setAnchorElement(thisAnchor);
        }
      }
    }
  }, [props.Position]);

  const handleClick = (event) => {
    setAnchorElement(event.currentTarget);
    const newOpen = !open;
    setOpen(newOpen);
    if (props.Timeout > 0) {
      setTimeout(() => handleClose(), props.Timeout * 1000);
    }
  };

  const contentWidth = {
    maxWidth: props.Width,
    minWidth: windowSize.width / 4,
  };

  const handleClose = () => {
    setAnchorElement(null);
    setOpen(false);
  };

  let content = null;
  let infoData = '';
  if (infoDetail) {
    infoData = infoDetail.infoDetail;
  } else {
    infoData = `Sorry additional information is missing (key: ${props.InfoKey})`;
  }

  content = (
    <div className={styles.content} style={{...contentWidth}}
         dangerouslySetInnerHTML={{__html: infoData}} />
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={styles.outer}>

        {props.children}

        {props.InfoKey !== '' && <div className={styles.topRight}>
          <InfoIcon
            style={{color: 'seagreen'}}
            fontSize="small"
            onClick={handleClick} />
        </div>}

        <Popper
          open={open}
          anchorEl={anchorElement}
          placement={placement}
          disablePortal={props.DisablePortal}
          transition
          sx={{zIndex:100}}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Tooltip title='If information appears off screen, try scrolling. Click info button again to close.' arrow>
                {content}
              </Tooltip>
            </Fade>
          )}
        </Popper>

    </div>
  </ClickAwayListener>
  );
}

InfoButton.propTypes = {
  InfoKey: PropTypes.string,
  Timeout: PropTypes.number,
  Width: PropTypes.string,
  Position: PropTypes.string,
  AnchorTo: PropTypes.string,
  DisablePortal: PropTypes.bool,
}


InfoButton.defaultProps = {
  InfoKey: '',
  Timeout: 0,
  Width: '30%',
  Position: 'right',
  AnchorTo: '',
  DisablePortal: false,
}

export default InfoButton;

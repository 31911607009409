import axiosClient from "./axiosClient";

class dataHelper {
    scenarioOptionsFromAPI(site, crop) {
        // TODO: Given a user might want to see the options for different crops across a site
        // before selecting, we might want cache the scenario options over time...
        return axiosClient.get('/scenarioOptions', {
            params: {
                site: site,
                crop: crop
            }
        });
    }

}

const scenarioOptionsDataHelper = new dataHelper();

export default scenarioOptionsDataHelper;
import React, {useState, useEffect, useRef} from 'react';
import IFrameWrapper from "../components/IFrameWrapper";

const CAMainPage = () => {
  const effectDone = useRef(false);

  useEffect(() => {
    if (effectDone.current === true) {
    }

    return () => {
      effectDone.current = true;
    }
  }, []);

  return (
    <IFrameWrapper Source="https://cacs.usqresearch.edu.au/climateARM/?theme=blank" />
  )
}

export default CAMainPage;

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import styles from './CheckboxList.module.scss';
import InfoButton from "./InfoButton";

// Options to be passed as an array of:
// {
//   display: string,
//   detail: string,
//   value: string,
// }
// Will be displayed as 'display (details)' and returns value.

const CheckboxList = (props) => {
  const [optionsArray, setOptionsArray] = useState([]);
  const [selected, setSelected] = useState([]);
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    // Columnise the options. Keep the order passed.
    const maxRows = Math.ceil(props.Options.length / props.Columns);

    // Setup multidimensional array of sites. This is controlled
    // by the passed Columns value.
    let optionArray = [];
    for(let r = 0; r < maxRows; r++) {
      const thisRow = [];
      for(let c = 0; c < props.Columns; c++) {
        const optionIndex = (c * maxRows) + r;
        if (optionIndex < props.Options.length) {
          thisRow.push(props.Options[optionIndex]);
        }
      }
      optionArray.push(thisRow);
    }
    setOptionsArray(optionArray);

  }, [props.Options, props.Columns]);

  // useEffect(() => {
  //   props.OnSelect(selected);
  // }, [selected]);

  const handleToggle = (value) => {
    let newSelected = [];

    if (props.MaxSelectCount === 1) {
      // Single selection only.
      newSelected = [value.toString()];
    } else {
      // Multiple selection is allowed,
      const currentIndex = props.Selected.indexOf(value.toString());
      if (currentIndex !== -1) {
        // Value already in the array so remove it.
        newSelected = props.Selected.filter(x => x.toString() !== value.toString());
      } else {
        // Cannot exceed MaxSelectCount.
        if (props.Selected.length >= props.MaxSelectCount) {
          // Do nothing. Already at limit.
        } else {
          // Limit not reached yet.
          newSelected = [...props.Selected];
          newSelected.push(value.toString());
        }
      }
    }
    props.OnSelect(newSelected);
  };

  //region Setup table styles
  const sxContainer = {
    width: "100%",
    height: "max_content",
    marginLeft: "auto",
    marginRight: "auto",
    padding: 4,
  };

  const borderStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderColor: "#000000",
    borderSpacing: 1,
    padding: '0px 16px',
    width: '100%',
  };
  //endregion

  const isSelected = (optionValue) => {
    let result = false;
    if (props.Selected.indexOf(optionValue.toString()) !== -1) {
      result = true;
    }
    return result;
  };

  const renderRow = (optionItemsArray, rowKey) => {
    const cellArray = optionItemsArray.map(optionItem => {
      return  renderCell(optionItem, rowKey);
    });

    return (
      <TableRow key={rowKey}>
        {cellArray}
      </TableRow>
    );
  };

  const renderCell = (optionItem, rowKey) => {
    const cellKey = rowKey + optionItem.value;
    let toDisplay = optionItem.display;
    if (optionItem.hasOwnProperty('detail')) {
      if (optionItem.detail !== '') {
        toDisplay += ` (${optionItem.detail})`;
      }
    }

    let content = (
      <FormControlLabel
        control = {
          <Checkbox
            color="primary"
            checked={isSelected(optionItem.value)}
            onChange={() => handleToggle(optionItem.value)}
          />
        }
        label={toDisplay}>
      </FormControlLabel>
    );

    // infokey was specified for this item.
    if (optionItem.hasOwnProperty('infokey')) {
      if (optionItem.infokey !== null  && optionItem.infokey !== '' ) {
        content = (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{display: 'inline-block'}}>
              <Checkbox
                color="primary"
                checked={isSelected(optionItem.value)}
                onChange={() => handleToggle(optionItem.value)}
              />
            </div>
            <div style={{display: 'inline-block'}}>
              <InfoButton
                InfoKey={optionItem.infokey}
                Timeout={0}
                Position='auto'
                DisablePortal={true}
              >
                <div>{toDisplay}</div>
              </InfoButton>
            </div>
          </div>
        );
      }
    }

    return (
      <TableCell
        key={cellKey}
        align="left" style={{...borderStyle}}>
        {content}
      </TableCell>
    );
  };



  return (
    <div id="checkcontainer" className={styles.container}>
      {props.Title !== '' && (<div style={{backgroundColor: props.TitleBackground}}>
        <Typography variant="h6" align="left">
          {props.Title}
        </Typography>}
      </div>)}
      <TableContainer  sx={{...sxContainer}}>
        <Table
          sx={{
            "&.MuiTable-root": {...borderStyle}
          }}
          size="small"
          aria-label="option list"
          stickyHeader
        >
          <TableHead>
            <TableRow key="header1" >
            </TableRow>
          </TableHead>
          <TableBody>
            {optionsArray.map((row, rowIndex) => {
              const rowKey = `clrow${rowIndex}`;
              return renderRow(row, rowKey);
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

CheckboxList.propTypes = {
  Options: PropTypes.arrayOf(Object).isRequired,
  Selected: PropTypes.array,
  Columns: PropTypes.number,
  Title: PropTypes.string,
  MaxSelectCount: PropTypes.number,
  OnSelect: PropTypes.func,
  TitleBackground: PropTypes.string,
  InsideModal: PropTypes.bool,
}


CheckboxList.defaultProps = {
  Selected: [],
  Columns: 1,
  MaxSelectCount: 1,
  OnSelect: () => {},
  TitleBackground: '#ffffff',
  InsideModal: false,
}

export default CheckboxList;

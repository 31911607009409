import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import {capitalise, ColourDarken, ColourLuminance, getOverallMinMax, makeAColour} from "../functions";

import styles from './sharedPageStyles.module.scss';
import InfoIcon from "../ra_images/infoIcon.png";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);

const bandColour = 'rgba(255,20,20,0.1)';

const LineChart = (props) => {
  const effectDone = useRef(false);
  const [chartData, setChartData] = useState([]);
  const [matchedSeasons, setMatchedSeasons] = useState([]);
  const [plotBands, setPlotBands] = useState(null);
  const [minY, setMinY] = useState(null);
  const [maxY, setMaxY] = useState(null);

  useEffect(() => {
    if (effectDone.current === true) {
      getChartData();
    }

    return () => {
      effectDone.current = true;
    }
  }, [props.ResultsData, props.ResultIndicator, props.SeasonalConditions]);

  const getCropColour = (cropname, withGradient = true, makeDarker = false) => {
    let result = null;

    const lcCropName = cropname.toString().trim().toLowerCase();
    const aCrop = props.Crops.find(c => c.Name.toLowerCase() === lcCropName);

    if (aCrop) {
      const startColour = makeAColour(aCrop.bg_colour);

      if (withGradient) {
        const stopColour = ColourLuminance(startColour, 0.2);
        const perShapeGradient = {x1: 0, y1: 0, x2: 1, y2: 1};
        const gradient = {
          linearGradient: perShapeGradient,
          stops: [[0, startColour], [1, stopColour]],
        };

        result = gradient;
      } else {
        result = makeAColour(startColour);

        if (aCrop.neverDarken === 0) {
          if (makeDarker) {
            result = ColourDarken(startColour, .1);
          }
        }
      }
    }

    return result;
  };

  const getChartData = () => {
    const xData = [];
    const xLabels = [];

    // console.log('getChartData', props.ResultsData);

    const foundCropsSet = new Set();
    let cropData = {};
    const seasonalMatches = [];

    let miny = null;
    let maxy = null;

    if (props.ResultsData) {
      //region Group the data by crop
      props.ResultsData.forEach(data => {

        const cropName = data.crop;

        if (cropName !== 'fallow') {
          foundCropsSet.add(cropName);

          //region Make sure cropData object has the crop property
          const cropSolidColour = getCropColour(cropName, false, true);
          if (!cropData.hasOwnProperty(cropName)) {
            cropData[cropName] = {data: [], color: cropSolidColour, name: capitalise(cropName)};
          }
          //endregion

          // Create a data point object. Use the current Result Indicator
          // property to grab a value from the correct column in the data.
          const dataValue = Math.round(data[props.ResultIndicator] * 1000) / 1000;

          //region Keep track of max/min data values.
          if (miny) {
            if (dataValue < miny) {
              miny = dataValue;
            }
          } else {
            miny = dataValue;
          }
          if (maxy) {
            if (dataValue > maxy) {
              maxy = dataValue;
            }
          } else {
            maxy = dataValue;
          }
          //endregion

          const point = [data.year, data[props.ResultIndicator]];
          cropData[cropName].data.push(point);

          // Check for matching seasonal conditions.
          if (props.SeasonalConditions.includes(data.condition)) {
            // Record this in seasonalMatches.
            const match = {
              year: data.year,
              season: data.season,
              condition: data.condition
            };
            seasonalMatches.push(match);
          }
        }
      });

      // Calculate max/min Y axis values.
      const scope = maxy - miny;
      const extend = Math.round(scope * .1);

      let lowerY = miny - extend;
      if (props.ResultIndicator === 'yield') {
        if (lowerY < 0) {
          lowerY = 0;
        }
      }

      setMinY(lowerY);
      setMaxY(maxy + extend);

      setMatchedSeasons(seasonalMatches);

      setChartData(cropData);
    }
  };

  const setupPlotBands = (matches) => {
    const arryBands = [];

    matches.forEach((m, index) => {

      let horizAlign = 'left';
      if (index === matches.length - 1) {
        horizAlign = 'center';
      }

      const friendlyName = `${m.year} ${m.season} ${m.season}`;

      const newBand = {
        color: bandColour,
        from: m.year - .3,
        to: m.year + .3,
        tooltipText: friendlyName,
      };

      arryBands.push(newBand);
    });

    return arryBands;
  };

  const getPlotBands = (matches) => {
    let myPlotBands = null;

    if (matches) {
      if (matches.length > 0) {
        myPlotBands = setupPlotBands(matches);
      }
    }

    return myPlotBands;
  };

  const getYAxisTitle = () => {
    let result = '';
    if (props.YAxisTitle === '') {
      result = capitalise(props.ResultIndicator);
    } else {
      result = props.YAxisTitle;
    }

    if (props.Units !== '') {
      result += ` (${props.Units})`;
    }

    return result;
  };

  const getTitle = () => {
    if (props.Title === '') {
      return 'Annual results: ' + capitalise(props.ResultIndicatorDesc);
    } else {
      return props.Title;
    }
  };

  const convertToSeries = (cropData) => {
    let results = [];

    if (cropData) {
      const cropKeys = Object.keys(cropData);

      cropKeys.forEach(k => {
        const seriesObj = cropData[k];
        seriesObj.marker = {symbol: 'circle', radius: 4, enabled: true};
        results.push(seriesObj);
      });

      results.push(
        {
          marker: {
            symbol: 'square',
            radius: 20,
          },
          type: 'scatter',
          name: 'Selected Seasons',
          color: bandColour,
        }
      );
    }

    return results;
  };

  const getChartOptions = () => {
    return {
      chart : {
        type : 'line',
        zoomType : 'x',
        spacingRight : 20,
      },
      title : {
        text : getTitle(),
      },
      credits: {
        enabled: false
      },

      rangeSelector : {
        selected : 3,
      },
      legend : {
        enabled : true,
      },
      xAxis : {
        enabled : true,
        type: 'datatime',
        maxZoom : 2,
        title : {
          text : "Year",
        },
        plotBands: getPlotBands(matchedSeasons),
      },
      yAxis : {
        min: minY,
        max: maxY,
        title : {
          text : getYAxisTitle(),
        }
      },
      tooltip : {
        headerFormat : '<span style="font-size:10px;font-weight:bold">{point.key}</span><table>',
        pointFormat : '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} ' + props.Units + '</b></td></tr>',
        footerFormat : '</table>',
        shared : true,
        useHTML : true,
        valueDecimals : 2
      },
      plotOptions: {
        series: {
          lineWidth: 3,
          label: {
            connectorAllowed: false,
          },
          events: {
            legendItemClick: function() {
              const xAxis = this.chart.xAxis[0];
              if (this.name === 'Selected Seasons') {
                if (this.visible) {
                  xAxis.update({
                    plotBands: []
                  });
                } else {
                  xAxis.update({
                    plotBands: getPlotBands(matchedSeasons)
                  });
                }
              }
            }
          }
        }
      },
      series: convertToSeries(chartData),
    }
  };

  return (
    <div className={styles.graphContainer}>
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={getChartOptions()}
          constructorType={ 'chart' }
        />
      </div>
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
};

LineChart.propTypes = {
  ResultsData: PropTypes.array.isRequired,
  ResultIndicator: PropTypes.string.isRequired,
  ResultIndicatorDesc: PropTypes.string.isRequired,
  Crops: PropTypes.array.isRequired,
  Units: PropTypes.string.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
  YAxisTitle: PropTypes.string,
  InfoKey: PropTypes.string,
};

LineChart.defaultProps = {
  Title: '',
  Explanation: '',
  YAxisTitle: '',
  InfoKey: '',
};

export default LineChart;

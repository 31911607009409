import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styles from "./TriangularDistScenario.module.scss";
import Tooltip from '@mui/material/Tooltip';
import {selectSite} from "../features/croparmSlice";
import {useDispatch, useSelector} from "react-redux";

const SEASON = {
    summer: 'Summer',
    winter: 'Winter',
};

const TriangularDistScenario = (props) => {
  const dispatch = useDispatch();

  const [scenario, setScenario] = useState({});
  const [minYield, setMinYield] = useState(0);
  const [maxYield, setMaxYield] = useState(999);
  const [expYield, setExpYield] = useState(0);
  const [description, setDescription] = useState('');
  const [descriptionChanged, setDescriptionChanged] = useState(false);
  const [yieldChanged, setYieldChanged] = useState(false);
  const [season, setSeason] = useState(SEASON.summer);

  const currentSiteId = useSelector(selectSite);

  useEffect(() => {
    if (props.DefaultSeason) {
      if (props.DefaultSeason !== '') {
        if (props.DefaultSeason === 'winter') {
          setSeason(SEASON.winter);
        } else {
          setSeason(SEASON.summer);
        }
      }
    }

  }, [props.DefaultSeason]);

  const onMinYieldChange = (e) => {
    setMinYield(Number(e.target.value));
    setYieldChanged(true);
  };

  const onMaxYieldChange = (e) => {
    setMaxYield(Number(e.target.value));
    setYieldChanged(true);
  };

  const onExpYieldChange = (e) => {
    setExpYield(Number(e.target.value));
    setYieldChanged(true);
  };

  const onDescriptionChange = (e) => {
    setDescriptionChanged(true);
    setDescription(e.target.value);
  };

  const onSeasonChange = (e) => {
    setSeason(e.target.value);
  };

  const handleCancelClick = () => {
      props.CancelClick();
  };

  //region Form validation
  let canSave = true;
  let yieldErrMsg = null;
  let yieldError = false;
  if (yieldChanged) {
    if (minYield < 0 || maxYield < 0 || expYield < 0) {
      canSave = false;
      yieldErrMsg = <p className={styles.yieldError}>Negative yield not allowed</p>;
      yieldError = true;
    }
    if (![maxYield, expYield].every(Boolean)) {
      canSave = false;
      yieldErrMsg = <p className={styles.yieldError}>Missing yield values</p>;
      yieldError = true;
    } else if (maxYield < minYield || expYield < minYield || expYield > maxYield) {
      canSave = false;
      yieldErrMsg = <p className={styles.yieldError}>Invalid yield range</p>;
      yieldError = true;
    }
  }

  let descErrMsg = null;
  let descError = false;
  if (descriptionChanged && description.trim() === '') {
    canSave = false;
    descErrMsg = <p className={styles.yieldError}>Missing description</p>;
    descError = true;
  }
  //endregion

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!yieldError && !descError) {
      const triScenario = {
        scenarioType: 'td',
        minYield,
        maxYield,
        expYield,
        season: season.toLowerCase(),
        description,
        siteId: currentSiteId,
      };

      props.SubmitClick(triScenario);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <div className={styles.container}>
        <p className={styles.details}>
          This type of scenario uses historical seasonal results to predict the likely
          result of other seasonal crops for which there are no simulation
          data.<br />
          <span className={styles.detailsBlue}>
            Note: The inclusion of a Triangular Distribution will restrict results to only show yield data.
          </span>
        </p>

        <div className={styles.inputs}>
          <TextField
            type="number"
            variant="outlined"
            value={minYield}
            onChange={onMinYieldChange}
            label="Min. Yield"
            error={yieldError}
            required />
        </div>
        <div className={styles.inputs}>
          <TextField
            type="number"
            variant="outlined"
            value={maxYield}
            onChange={onMaxYieldChange}
            label="Max. Yield"
            error={yieldError}
            required />
        </div>
        <div className={styles.inputs}>
          <TextField
            type="number"
            variant="outlined"
            value={expYield}
            onChange={onExpYieldChange}
            label="Expected Yield"
            error={yieldError}
            required />
        </div>
        {yieldErrMsg}
        <div className={styles.cropSeason}>
          <div className={styles.cropLabel} >
            Crop Type:
          </div>
          <div className={styles.cropType}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="season-group"
                value={season}
                onChange={e => onSeasonChange(e)} >
                <FormControlLabel value={SEASON.summer} control={<Radio />} label="Summer" labelPlacement="end" />
                <FormControlLabel value={SEASON.winter} control={<Radio />} label="Winter" labelPlacement="end" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <TextField
          className={styles.inputs}
          type="text"
          variant="outlined"
          value={description}
          onChange={onDescriptionChange}
          label="Description/Crop Name"
          required />
        {descErrMsg}

        <div className={styles.buttons}>
          <div className={styles.button}>
            <Button variant="outlined" color="secondary" onClick={handleCancelClick}>Cancel</Button>
          </div>
          <div className={styles.button}>
            <Tooltip title="Add to the scenario list">
              <Button variant="outlined" color="primary" type="submit" disabled={!canSave}>Submit</Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </form>
  );
};

TriangularDistScenario.propTypes = {
  CancelClick: PropTypes.func,
  SubmitClick: PropTypes.func,
  DefaultSeason: PropTypes.string,
};

TriangularDistScenario.defaultProps = {
  CancelClick: () => {},
  SubClick:  () => {},
  DefaultSeason: '',
};

export default TriangularDistScenario;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Typography, Box, Divider } from '@mui/material';

import analysisDataHelper from '../data/analysisDataHelper';
import configHelper from '../data/configHelper';
import LoadingComponent from '../components/LoadingComponent';
import CheckboxGroup from '../components/CheckboxGroup';
import componentStyle from './DroughtAnalysisContainer.module.scss';

class DroughtAnalysisContainer extends Component {
    constructor(props) {
        super(props);

        this.modalStyle = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '50%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            maxHeight: '80%',
            overflow: 'auto',
        };

        this.state = {
            showModal: false,
            // loadingState: 'loading',
            loadingState: 'success',
            error: {}
        };
    }

    exceedingMaxScenarioCount() {
        var maxScenarios = configHelper.MAX_SCENARIOS;

        if (this.props.selectedDroughts.length === 0) {
            return false;
        } else {
            // we are going to create a new scenario result for each different 'drought' applied to an initial scenario.
            return (this.props.numberOfScenarios * this.props.selectedDroughts.length) > maxScenarios;
        }

    }

    onDroughtSelectionChange = (event, value) => {
        event.preventDefault();
        let checked = value;
        let eventValue = event.target.value;

        let selectedDroughts = this.props.selectedDroughts;

        console.log(eventValue);
        if (checked) {
            selectedDroughts.push(eventValue);
        } else {
            selectedDroughts = selectedDroughts.filter(item => item !== eventValue);
        }

        this.props.handleDroughtSelectionChange(selectedDroughts);
    }

    handleDroughtAnalysisApply = (event) => {
        event.preventDefault();
        this.setState({showModal: false});
        this.props.applyDroughtAnalysis(this.props.selectedDroughts, this.props.DroughtType);
    }

    handleDroughtAnalysisClear = (event) => {
        event.preventDefault();
        this.props.handleDroughtSelectionClear();
    }

    getButtonVariant() {
        if (this.props.selectedDroughts.length > 0) {
            return "contained";
        } else {
            return "outlined";
        }
    }

    haveOptionsLoaded() {
        if (this.state.loadingState === 'success' && this.props.Droughts.length > 0) {
            return true;
        }
        return false;
    }

    render() {
        return (
          <div className={componentStyle.container}>
              <Button
                sx={{m: 1, p: .5,  width: 180}}
                variant={this.getButtonVariant()}
                disabled={this.props.disabled}
                onClick={() => {this.setState({showModal: true})}}
              >
                National Droughts
              </Button>
              <Modal
                open={this.state.showModal}
                onClose={() => {this.setState({showModal: false})}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={this.modalStyle}>
                  <div className={componentStyle.header}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                          Drought Analysis
                      </Typography>
                      <Typography id="modal-modal-description" sx={{mt: 2}}>
                          Select applicable droughts and click "Apply" to reload the scenarios with drought analysis
                          applied.
                      </Typography>
                      <Divider sx={{margin: "1em 0"}}/>
                  </div>
                  <div className={componentStyle.detail}>
                      <CheckboxGroup
                        label="National Droughts"
                        controlStyling={{width: "100%", overflowY: 'scroll', maxHeight: '80%'}}
                        options={!this.haveOptionsLoaded() ? [] : this.props.Droughts.map((drought) => {
                            return drought['DroughtName']
                        })}
                        optionLabels={!this.haveOptionsLoaded() ? [] : this.props.Droughts.map((drought) => {
                            return drought['FriendlyName']
                        })}
                        horizontal={false}
                        isChecked={(option) => {
                            return this.props.selectedDroughts.indexOf(option) !== -1;
                        }}
                        onChange={this.onDroughtSelectionChange}
                      />
                      {this.state.loadingState === 'loading' &&
                        <div>
                            <LoadingComponent
                              fullwidth={true}
                              state='loading'
                            />
                        </div>
                      }
                      {this.state.loadingState === 'error' &&
                        <div>
                            <LoadingComponent
                              fullwidth={true}
                              state='error'
                              error={this.state.error}
                            />
                        </div>
                      }
                  </div>
                  <div className={componentStyle.footer}>
                      {this.exceedingMaxScenarioCount() &&
                        <Typography variant="subtitle1">
                            Applying selected drought analysis to your current list of scenarios would exceed the
                            scenario limit. Please reduce
                            the number of drought selections, or reduce the overall number of scenarios.
                        </Typography>
                      }
                      <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                          <Button
                            variant={"outlined"}
                            sx={{marginRight: "1em"}}
                            disabled={this.props.selectedDroughts.length === 0}
                            onClick={this.handleDroughtAnalysisClear}
                          >
                              Clear Drought Analysis
                          </Button>
                          <Button
                            variant={"contained"}
                            disabled={this.exceedingMaxScenarioCount()}
                            onClick={this.handleDroughtAnalysisApply}
                          >
                              Apply
                          </Button>
                      </Box>
                  </div>
                </Box>
              </Modal>
          </div>
        )
    }
}

DroughtAnalysisContainer.propTypes = {
    disabled: PropTypes.bool,
    numberOfScenarios: PropTypes.number,
    selectedDroughts: PropTypes.arrayOf(PropTypes.string),
    handleDroughtSelectionChange: PropTypes.func,
    handleDroughtSelectionClear: PropTypes.func,
    applyDroughtAnalysis: PropTypes.func,
    Droughts: PropTypes.arrayOf(PropTypes.object),
};

DroughtAnalysisContainer.defaultProps = {
    Droughts: [],
}

export default DroughtAnalysisContainer;

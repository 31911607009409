import React,  { Component } from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';

import componentStyles from './BoxPlotGraph.module.scss';

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

class BoxPlotGraph extends Component {

    getScenarioLabels() {
        return this.props.scenarios.map((scenario) => {
            return "#" + scenario['Index'];
        });
    }

    getBoxPlotData() {
        return this.props.scenarios.map((scenario) => {
            return [
                scenario.data[this.props.dataField]['5_percent_quantile'],
                scenario.data[this.props.dataField]['25_percent_quantile'],
                scenario.data[this.props.dataField]['50_percent_quantile'],
                scenario.data[this.props.dataField]['75_percent_quantile'],
                scenario.data[this.props.dataField]['95_percent_quantile']
            ];
        });
    }

    getMeanData() {
        return this.props.scenarios.map((scenario) => {
            // returned same value twice as this represents two points
            // for a line - "from" and "to"
            return [
                scenario.data[this.props.dataField].mean,
                scenario.data[this.props.dataField].mean
            ];
        });
    }

    getColorOptions() {
        var baseColor = '#2F96B4';
        var perShapeGradient = {
            x1 : 0,
            y1 : 0,
            x2 : 1,
            y2 : 0,
        };
        const myStopColour = new Highcharts.Color(baseColor);
        // return {
        //     linearGradient : perShapeGradient,
        //     stops : [[0, baseColor], [1, Highcharts.Color(baseColor).brighten(0.3).get('rgb')]]
        // };
        return {
            linearGradient : perShapeGradient,
            stops : [[0, baseColor], [1, myStopColour.brighten(0.3).get('rgb')]]
        };
    }

    getUnits() {
        return this.props.scenarios[0].data[this.props.dataField].units;
    }

    getTitle() {
        var fullDescription = this.props.scenarios[0].data[this.props.dataField].fullDescription || this.props.dataField;
        var units = this.getUnits();
        if (fullDescription && units) {
            return [fullDescription, units].join(' - ');
        }
        return fullDescription;
    }

    getChartOptions() {
        var units = this.props.scenarios[0].data[this.props.dataField].units;
        return {
            chart: {
                type: 'boxplot'
            },
            title: {
                text: this.getTitle()
            },
            credits: false,
            legend: {
                enabled: false
            },
            xAxis: {
                categories: this.getScenarioLabels(),
                enabled : true,
                title: {
                    text: 'Scenario Number (see table below)'
                }
            },
            yAxis: {
                title: {
                    text: this.getTitle()
                }
            },
            series: [{
                name: 'Data',
                data: this.getBoxPlotData(),
                color: 'black',
                fillColor: this.getColorOptions(),
                tooltip: {
                    pointFormat : '<span style="color:{series.color};font-weight:bold">{series.name}</span><br/>' + '95% (Top Whisker) : {point.high} ' + this.getUnits() + '<br/>' + '75% (Top Box): {point.q3} ' + this.getUnits() + '<br/>' + '50% (Median - Black Line): {point.median} ' + this.getUnits() + '<br/>' + '25% (Bottom Box): {point.q1} ' + this.getUnits() + '<br/>' + '5% (Bottom Whisker): {point.low} ' + this.getUnits() + '<br/>',
                    valueDecimals : 1
                }
            }, {
                type: 'errorbar',
                data: this.getMeanData(),
                dashStyle: 'ShortDash',
                whiskerLength: '99%',
                color: 'red',
                tooltip: {
                    pointFormat: 'Mean: {point.low} ' + units + '<br/>',
                    valueDecimals: 2
                },
            }]
        }
    }

    render() {
        const chartOptions = this.getChartOptions();
        return (
            <div className={componentStyles.graphContainer}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    constructorType={ 'chart' }
                />
            </div>
        )
    }
}

BoxPlotGraph.propTypes = {
    scenarios: PropTypes.arrayOf(PropTypes.object),
    dataField: PropTypes.string,
};

export default BoxPlotGraph;

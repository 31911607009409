import React, {useEffect, useState, useRef} from "react";
import { Routes, Route, Link } from "react-router-dom";

import styles from './App.module.scss';

import NameHeader from './components/NameHeader';
import NavSection from './components/NavSection';
import CopyrightFooter from './components/CopyrightFooter';
import DisclaimerFooter from './components/DisclaimerFooter';
import MainPage from "./containers/MainPage";
import CopyrightPage from "./containers/CopyrightPage";
import DisclaimerPage from "./containers/DisclaimerPage";
import FAMainPage from "./containers/FallowARM/FAMainPage";
import RAMainPage from "./ra_containers/RAMainPage";
import CAMainPage from "./containers/CAMainPage";
import Maintenance from "./components/Maintenance";
import Training from "./components/Training";
// import IFrameWrapper from "./components/IFrameWrapper";
import ARMHome from "./containers/ARMHome";
import Acknowledgements from "./components/Acknowledgements";

const apps = [
  {
    order: 1,
    name: "ClimateARM",
    description: ("<p>ClimateARM is the new online version of the tool previously known as Rainman. The " +
      "StreamFlow analysis tool was developed by the department to assist the industry to improve " +
      "climate risk management. The tool provides the ability to analyse rainfall and other " +
      "climate variables at individual locations and taking into account seasonal patterns and " +
      "forecasts.</p>"
    ),
    image: "ClimateARM_300.jpg",
    link: "/ca",
  },
  {
    order: 2,
    name: "FallowARM",
    description: ("<p>FallowARM enables computation of the possible range of soil water and soil nitrogen that is " +
      "likely to accumulate over a fallow period for selectable sites. The stored soil water at the " +
      "fallow end will be a factor of the rainfall during the fallow (115 years of data), length of " +
      "fallow, starting time of the fallow and to a small extent, by surface cover. The soil nitrogen " +
      "at the end of the fallow will be influenced by the soil organic carbon level and fallow " +
      "rainfall.</p>\n <p>The data from this calculator can be used as a training tool or as input " +
      "into other calculators such as CropARM or NitrogenARM.</p>\n"
    ),
    image: "FallowARM_300.jpg",
    link: "/fa",
  },
  {
    order: 3,
    name: "CropARM",
    description: ("<p>CropARM was previously known as WhopperCropper. This tool facilitates discussion of " +
      "growers' exposure to risk when comparing different management inputs such as N fertiliser " +
      "applied and resource-based options such as stored soil water. The variability of the effect " +
      "of each input option can be shown alone or in combination with other inputs.</p> " +
      "<p>The simulations use over 120 years of climate records and the APSIM model to predict the " +
      "potential year-to-year variability in yields, rainfall, temperature effects and " +
      "crop growth.</p>"
    ),
    image: "CropARM_300.jpg",
    link: "/wc",
  },
  {
    order: 4,
    name: "RotationARM",
    description: ("<p><strong>Currently Experimental/Under development</strong></p>" +
      "<p>The RotationARM tool assists growers in their decision about which crop rotation " +
      "is most suitable to their circumstances. It guides farmers to compare the " +
      "performance of their current crop rotation to potential alternatives. The tool " +
      "simulates crop rotations across 60 years of climate records. It allows the comparison " +
      "of crop rotation options regarding various performance indicators, such as crop yield " +
      "and gross margins as well as further multi-dimensional criteria.</p>"
    ),
    image: "Rotation_300.png",
    link: "/ra",
  },
  {
    order: 5,
    name: "DeepP",
    description: ("<p><strong>Currently Under Maintenance</strong></p>" +
      "<p>The Deep-P Calculator aims to determine the optimum rate of phosphorus to be applied " +
      "at depth and the economic outcomes for a multi-season crop rotation. Inputs include: " +
      "location, soil PAWC, length of the cropping rotation, machinery and fertiliser costs, " +
      "crop value and nitrogen availability. Outputs include: the possible net economic benefit " +
      "over time, return on investment and time for pay-back.</p> <p>The tool is experimental. " +
      "The reliability of the outputs generated can be influenced by the usual unpredictable " +
      "factors influencing crop production including rainfall, heat stress and frost and unknown " +
      "nutrient and soil limitations which may impact the response. Research is on-going and " +
      "hence the results from the calculator are only a guide.</p>"
    ),
    image: "imgDeepP.jpg",
    link: "/dp",
  },
  {
    order: 6,
    name: "Education/Training",
    description: ("<p></p>"),
    image: "",
    link: "/train",
  },
];
//
// const  navItems = [
//   { label: 'Home', link: '/'},
//   { label: 'ClimateARM', link: '/ca'},
//   { label: 'FallowARM', link: '/fa'},
//   { label: 'CropARM', link: '/wc'},
//   // { label: 'NitrogenARM', link: 'http://armonline.com.au/#/ncalc'},
//   { label: 'Deep-P', link: '/dp'},
// ]

const App = () => {
  const effectDone = useRef(false);
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    if (effectDone.current === false) {
      const sortedApps = apps.sort((a, b) => a.order - b.order);

      const navs = [];
      navs.push({ label: 'Home', link: '/'});

      sortedApps.forEach((a, index) => {
        const id = a.name.replace("/", "") + index.toString();
        navs.push({label: a.name, link: a.link, id});
      });

      setNavItems([...navs]);
    }

    return () => {
      effectDone.current = true;
    }

  }, []);

  return (
    <div className={styles.app}>
      <div className={styles.nameHeaderSection}>
        <NameHeader></NameHeader>
        <NavSection navItems={navItems}></NavSection>
      </div>
      <div className={styles.mainSection}>
        <Routes>
          <Route path="/wc" element={<MainPage/>} />
          <Route path="/fa" element={<FAMainPage/>} />
          {/*<Route path="/ca" element={<IFrameWrapper Source="https://cacs.usqresearch.edu.au/climateARM/?theme=blank" />} />*/}
          <Route path="/ca" element={<CAMainPage/>} />
          <Route path="/ra" element={<RAMainPage/>} />
          <Route path="/copyright" element={<CopyrightPage/>} />
          <Route path="/disclaimer" element={<DisclaimerPage/>} />
          <Route path="/acknowledgements" element={<Acknowledgements/>} />
          <Route path="/dp" element={<Maintenance ToolName='DeepP Calculator'/>} />
          <Route path="/train" element={<Training />} />
          <Route path="/home" element={<ARMHome Apps={apps}/>} />
          <Route path="/" element={<ARMHome Apps={apps}/>} />
        </Routes>
        <div>
          <CopyrightFooter></CopyrightFooter>
        </div>
        <div className={styles.disclaimerSection}>
          <DisclaimerFooter></DisclaimerFooter>
        </div>
      </div>
    </div>
  );
};

export default App;

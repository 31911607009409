import React, {useState, useEffect, useRef} from 'react';
import sharedStyles from '../sharedPageStyles.module.scss';
import pageStyles from '../MainPage.module.scss';
import { Button, Typography, Container, Box, iconButtonClasses } from '@mui/material';
import FAScenarioContainer from './FAScenarioContainer';

const FAMainPage = () => {
  const effectDone = useRef(false);
  const [hideDescription, setHideDescription] = useState(false);
  const [hideStepper, setHideStepper] = useState(true);

  useEffect(() => {
    if (effectDone.current === true) {
    }

    return () => {
      effectDone.current = true;
    }
  }, []);

  const handleGetStarted = (event) => {
    setHideDescription(true);
    setHideStepper(false);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ width: '100%' }}>
        <div className={sharedStyles.descriptionArea}>
          <div className={sharedStyles.headerSection}>
            <Typography variant="h4">
              FallowARM   -  Fallow Analysis for Risk Management
            </Typography>
          </div>
          <div hidden={hideDescription}>
            <Typography variant="body1" gutterBottom>
              Fallowing is an important management tool for grain growers in the northern grains
              region. Fallowing allows soil water to be stored and nitrogen mineralisation to
              occur. To maximise the benefits of fallowing it is important for growers to understand
              how climate, soil type, organic matter levels and surface organic matter interact to
              affect soil water accumulation and nitrogen mineralisation.
            </Typography>
            <Typography variant="body1" gutterBottom>
              FallowARM is a simple software tool that facilitates simulation-aided discussion
              of fallow management strategies. The simulations utilise the 115-year climate
              record to predict the year-to-year variability in outcomes. The effect of
              different fallow starting conditions and fallow lengths can be compared side-by-side.
              The management factors that can be examined include:
            </Typography>
            {/* Material UI does have a <List> element, but that's a bit overkill for the below. */}
            <ul className={sharedStyles.itemList}>
              <li>Effect of stored soil water</li>
              <li>Fallow starting date</li>
              <li>Fallow length</li>
              <li>Soil carbon levels</li>
              <li>Initial surface organic matter</li>
            </ul>
            <Typography variant="body1" gutterBottom>
              Each factor contains 3 to 6 levels from which to choose. These levels can constitute the
              scenario comparison or any number of levels (acts like a input ‘rate’ trial) or these
              factors can be combined with multiple levels within any other factor (similar to
              a ‘factorial’ experiment).
            </Typography>
            <Typography variant="body1" gutterBottom>
              FallowARM has approximately 10 output options including stored soil water and nitrogen
              mineralisation. These values can be used as inputs in to the CropARM and NitrogenARM
              tools.
            </Typography>
          </div>
          <FAScenarioContainer hideBuilder={hideStepper}></FAScenarioContainer>
          <div className={pageStyles.buttonContainer} hidden={hideDescription}>
            <Button variant="contained" size="large" onClick={handleGetStarted}>Get Started</Button>
          </div>
        </div>
      </Box>
    </Container>
  )
}

export default FAMainPage;

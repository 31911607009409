import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftOutlined from '@mui/icons-material/ArrowCircleLeftOutlined';

import ResultTable from '../components/ResultTable';
import BoxPlotGraph from '../components/BoxPlotGraph';
import BarGraph from '../components/BarGraph';
import CumulativeDistributionGraph from '../components/CumulativeDistributionGraph';
import ProbabilityOfExceedanceGraph from '../components/ProbabilityOfExceedanceGraph';
import analysisDataHelper from '../data/analysisDataHelper';
import siteDataHelper from '../data/siteDataHelper';
import configHelper from '../data/configHelper';
import SOIAnalysisContainer from './SOIAnalysisContainer';
import DroughtAnalysisContainer from './DroughtAnalysisContainer';
import GrossMarginContainer2 from '../components/GrossMarginContainer2';
import SeasonConditionsContainer from './SeasonConditionsContainer';
import sharedStyles from './sharedPageStyles.module.scss';
import styles from './ScenarioResults.module.scss';
import BorderedSection from '../components/BorderedSection';

import { Box, MenuItem, Typography, FormGroup, FormControl, InputLabel, Select, Tooltip } from '@mui/material';

class ScenarioResults extends Component {
    constructor(props) {
        super(props);

        this.RESULT_DISPLAY_TYPES = [
            { value: 'table', label: 'Table'},
            { value: 'box_plot', label: 'Box Plot'},
            { value: 'bar_chart', label: 'Bar Chart'},
            { value: 'cumulative_distribution', label: 'Cumulative Distribution'},
            { value: 'probability_of_exceedance', label: 'Probability Of Exceedance'},
        ];

        this.FIELD_SELECTION_OPTIONS = [
            "wetYield",
            "dryYield",
            "PAW",
            "WUEBiomass",
            "WUEYield",
            "biomass",
            "cropFailure",
            "daysFrost",
            "daysHarvest",
            "daysHeat",
            "irrigationWater",
            "maxT2Flower",
            "maxT4Harvest",
            "maxT4Sow",
            "minT",
            "minT2Flower",
            "minT4Harvest",
            "minT4Sow",
            "mineralN",
            "protein",
            "rain",
            "waterUsed",
        ];

        // console.log('ScenarioResults', this.props);
        let displayField = this.FIELD_SELECTION_OPTIONS[0];
        if (this.props.originalScenarios.some((scenario) =>  scenario.ScenarioType === 'td')) {
            displayField = configHelper.TRIANGULAR_DISTIRBUTION_RESULT_FIELD
        }
        if (this.isModifierApplied('grossMargin')) {
            displayField = configHelper.GROSS_MARGIN_RESULT_FIELD;
        }

        this.state = {
            resultDisplayType: 'box_plot',
            scenarioFieldDisplayed: displayField,
            selectedMonth: "",
            selectedPhases: [],
            selectedDroughts: [],
            analysisDroughts: [],
            // Default - Akin to the previous 'Local Droughts' selection.
            seasonalConditionsList: [],
            selectedConditions: [],
            currentOverlay: 'National',
            crop: '',
        };
    }

    componentDidMount() {
        var self = this;
        this.setState({loading: true});

        // analysisDataHelper.getDroughtsList()
        //     .then(function(response) {
        //         // sort droughts using the Start Year of each drought.
        //         var droughts = response.data.droughts.sort((a,b) => a.StartYear - b.StartYear);
        //         self.setState({
        //             droughts,
        //             loading: false,
        //         });
        //     })
        //     .catch(function(error) {
        //         // TODO: What do we do in the case of an error here?
        //         // Not particularly expected...
        //     });
        analysisDataHelper.getCropSeasonList()
          .then(function(response) {
              // sort droughts using the Start Year of each drought.
              var cropSeasons = response.data.crops;
              self.setState({
                  crops: cropSeasons,
                  loading: false,
              });
          })
          .catch(function(error) {
              // TODO: What do we do in the case of an error here?
              // Not particularly expected...
          });
        siteDataHelper.sitesFromAPI()
          .then(function(response) {
              // sort droughts using the Start Year of each drought.
              var mySites = response.data;
              self.setState({
                  allSites: mySites,
              });
          })
          .catch(function(error) {
              // TODO: What do we do in the case of an error here?
              // Not particularly expected...
          });

        this.setState({seasonalConditionsList: [...analysisDataHelper.getSeasonalConditionsList()]});
        this.setState({selectedConditions: [...analysisDataHelper.getDefaultSeasonalConditions()]});

        if (this.props.Droughts) {
            this.setState({analysisDroughts: [...this.props.Droughts]});
        }

        if (this.props.originalScenarios) {
            if (this.props.originalScenarios.length > 0) {
                const thisCrop = this.props.originalScenarios[0].Crop;
                this.setState({crop: thisCrop});
            }
        }
        // this.refreshDroughts();
    }

    onChangeOverlay = (newOverlay) => {
        this.setState({currentOverlay: newOverlay});
        // this.refreshDroughts();
    }

    onSeasonalCondChange = () => {
        // this.refreshDroughts();
    }

    refreshDroughts = () => {
        switch (this.state.currentOverlay) {
            case 'Seasonal':
                break
            case 'National':
                this.setState({analysisDroughts: [...this.props.Droughts]});
                break
            default:
                // Nothing to do.
        }
    }

    hasSOISelected = () => {
        return (this.state.selectedMonth !== '' || this.state.selectedPhases.length > 0)
    }

    hasDroughtSelected = () => {
        return (this.state.selectedDroughts.length > 0);
    }

    getResultDisplay(displayType) {
        switch(displayType) {
            case 'table':
                return (
                    <div>
                        <ResultTable
                            scenarios={this.props.results}
                            dataField={this.state.scenarioFieldDisplayed}
                        />
                    </div>
                );
            case 'box_plot':
                return (
                    <div>
                        <BoxPlotGraph
                            scenarios={this.props.results}
                            dataField={this.state.scenarioFieldDisplayed}
                        />
                        <ResultTable
                            scenarios={this.props.results}
                            dataField={this.state.scenarioFieldDisplayed}
                        />
                    </div>
                );
            case 'bar_chart':
                // const firstResult = this.props.results[0];
                // const siteName = firstResult.Site;
                // const siteData = siteDataHelper.infoForSite(siteName, this.state.allSites.sites);
                // const siteId = siteData.id;

                return (
                    <div>
                        <BarGraph
                            scenarios={this.props.results}
                            dataField={this.state.scenarioFieldDisplayed}
                            NationalDroughts={this.state.analysisDroughts}
                            SOIActive={this.hasSOISelected()}
                            LocalDroughts={analysisDataHelper.convertYearsToDroughts(this.props.LocalDroughts)}
                            SeasonalConditionsList={this.state.seasonalConditionsList}
                            SelectedConditions={this.state.selectedConditions}
                            WaterBalanceData={analysisDataHelper.convertYearsToDroughts(this.props.WaterBalanceData)}
                        />
                        <ResultTable
                            scenarios={this.props.results}
                            dataField={this.state.scenarioFieldDisplayed}
                        />
                    </div>
                );
            case 'cumulative_distribution':
                return (
                    <div>
                        <CumulativeDistributionGraph
                            scenarios={this.props.results}
                            dataField={this.state.scenarioFieldDisplayed}
                        />
                        <ResultTable
                            scenarios={this.props.results}
                            dataField={this.state.scenarioFieldDisplayed}
                        />
                    </div>
                );
            case 'probability_of_exceedance':
                return (
                    <div>
                        <ProbabilityOfExceedanceGraph
                            scenarios={this.props.results}
                            dataField={this.state.scenarioFieldDisplayed}
                        />
                        <ResultTable
                            scenarios={this.props.results}
                            dataField={this.state.scenarioFieldDisplayed}
                        />
                    </div>
                );
            default:
                return null;
        }
    }

    getScenarioFieldSelector() {
        // if the 'grossMargin' modifier has been applied, our selector is limited to one field
        // console.log('getScenarioFieldSelector', this.isModifierApplied('grossMargin'));
        if (this.isModifierApplied('grossMargin')) {
            let grossMarginField = configHelper.GROSS_MARGIN_RESULT_FIELD;
            // this.setState({scenarionFieldDisplayed: configHelper.GROSS_MARGIN_RESULT_FIELD});
            // console.log('ScenarioFieldDisplayed set to ', configHelper.GROSS_MARGIN_RESULT_FIELD);
            return (
                <Tooltip title={"Gross Margin Analysis only provides one field to view results for."}>
                    <Select
                        value={this.state.scenarioFieldDisplayed}
                        label="Scenario Data Field"
                        disabled={true}
                    >
                        <MenuItem
                            value={grossMarginField}>
                                Gross Margin ($/ha)
                        </MenuItem>
                    </Select>
                </Tooltip>
            );
        }

        // if there are any scenarios created via triangualr distribution methods, our selector is limited to one field
        if (this.props.originalScenarios.some((scenario) =>  scenario.ScenarioType === 'td')) {
            let dryYieldField = configHelper.TRIANGULAR_DISTIRBUTION_RESULT_FIELD;
            return (
                <Tooltip title={"Scenarios created via Triangular Distribution methods only provide one field to view results for."}>
                    <Select
                        value={this.state.scenarioFieldDisplayed}
                        label="Scenario Data Field"
                        disabled={true}
                    >
                        <MenuItem
                            value={dryYieldField}>
                                Dry grain yield (t/ha)
                        </MenuItem>
                    </Select>
                </Tooltip>
            );
        }

        // by default we want all available fields returned
        return (
            <Select
                value={this.state.scenarioFieldDisplayed}
                label="Scenario Data Field"
                onChange={this.onScenarioFieldSelection}
            >
                {
                    this.FIELD_SELECTION_OPTIONS.map((fieldSelectionOption) => {
                        return (
                            <MenuItem
                                key={fieldSelectionOption}
                                value={fieldSelectionOption}
                            >
                                {fieldSelectionOption}
                            </MenuItem>
                        )
                    })
                }
            </Select>
        )
    }

    isModifierApplied(type) {
        if (type === 'grossMargin') {
            if (this.props.scenarios) {
                if (this.props.scenarios.length > 0) {
                    const firstScenario = this.props.scenarios[0];
                    if (firstScenario.hasOwnProperty("data")) {
                        if (firstScenario.data.hasOwnProperty(type)) {
                            return true;
                        }
                    }
                }
            }
        }

        if (this.props.modifiers.length > 0) {
            let modifier = this.props.modifiers.find(element => element.type === type);
            if (modifier) {
                return true;
            }
        }

        return false;
    }

    grossMarginDisabled() {

        let result = {
            'result': false,
            'reason': ''
        };

        // validation #1 - are there any 'td' scenarios in the results?
        let tdCheck = (scenario) =>  scenario.ScenarioType === 'td';
        if (this.props.results.some(tdCheck)) {
            result['result'] = true;
            result['reason'] = 'You cannot apply Gross Margin Analysis to scenarios created using Triangular Distribution methods.';
            return result;
        }

        // validation #2 - are there multiple crops across all scenarios
        let crops = new Set();
        this.props.results.forEach((scenario) => {
            crops.add(scenario.Crop);
        });
        let uniqueCrops = Array.from(crops);
        if (uniqueCrops.length > 1) {
            result['result'] = true;
            result['reason'] = 'You cannot apply Gross Margin Analysis with multiple crops across mutliple scenarios.';
        }

        return result;

    }

    handleDroughtSelectionChange = (newSelection) => {
        this.setState({
            selectedConditions: [],
            selectedDroughts: newSelection
        });
    }

    handleDroughtSelectionClear = () => {
        this.setState({
            selectedDroughts: []
        });
    }

    handleSOISelectionChange = (type, newSelection) => {
        if (type === 'month') {
            this.setState({
                selectedMonth: newSelection
            });
        } else if (type === 'phases') {
            this.setState({
                selectedPhases: newSelection
            });
        }
    }

    handleOverlayChange = (newOverlay) => {
        this.setState({currentOverlay: newOverlay});
    }

    handleSeasonalConditionsApply = (conditions) => {
        this.setState({
            selectedConditions: [...conditions],
            selectedDroughts: [],
        });
    }

    handleSeasonalConditionsClear = () => {
        this.setState({selectedConditions: []});
        this.props.clearSeasonalAnalysis();
    }

    handleSOISelectionClear = () => {
        this.setState({
            selectedMonth: "",
            selectedPhases: []
        });
    }

    handleGrossMarginApply = (scenarios) => {
        this.setState({
            scenarioFieldDisplayed: configHelper.GROSS_MARGIN_RESULT_FIELD
        });
        this.props.applyGrossMarginAnalysis(scenarios);
    }

    handleGrossMarginRemove = () => {
        this.setState({
            scenarioFieldDisplayed: this.FIELD_SELECTION_OPTIONS[0]
        });
        this.props.removeGrossMarginAnalysis();
    }

    onBackSelection = (event) => {
        event.preventDefault();
        this.setState({
            selectedMonth: "",
            selectedPhases: [],
            selectedDroughts: [],
            scenarioFieldDisplayed: this.FIELD_SELECTION_OPTIONS[0],
        })
        this.props.clearScenarioResults();
    }

    onScenarioFieldSelection = (event, value) => {
        this.setState({
            scenarioFieldDisplayed: event.target.value
        });
    }

    onViewTypeSelection = (event, value) => {
        this.setState({
            resultDisplayType: event.target.value
        });
    }

    render() {
        return (
            <div className={sharedStyles.resultsArea}>
                <Box sx={{ display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", alignItems: "center" }}>
                    <Box sx={{ flexGrow: 0 }}>
                        <Typography variant="h4">
                            <Tooltip title={"Returning to Scenario Creation step will clear all Analysis currently applied."}>
                                <IconButton onClick={this.onBackSelection} aria-label="back">
                                    <ArrowCircleLeftOutlined />
                                </IconButton>
                            </Tooltip>
                            Scenario Results
                        </Typography>
                    </Box>
                    <div style={{marginLeft: '12px'}} >
                        <BorderedSection title="Analysis">
                            <div className={styles.flexCol}>
                                <div className={styles.flexRow}>
                                    <SOIAnalysisContainer
                                        disabled={this.isModifierApplied('drought')}
                                        numberOfScenarios={this.props.originalScenarios.length}
                                        selectedMonth={this.state.selectedMonth}
                                        selectedPhases={this.state.selectedPhases}
                                        handleSOISelectionChange={this.handleSOISelectionChange}
                                        handleSOISelectionClear={this.handleSOISelectionClear}
                                        applySoiAnalysis={this.props.applySoiAnalysis}
                                    >
                                    </SOIAnalysisContainer>
                                    <SeasonConditionsContainer
                                      ScenarioCount={this.props.originalScenarios.length}
                                      SelectedConditions={this.state.selectedConditions}
                                      ApplySeasonalConditions={this.props.applySeasonalAnalysis}
                                      ClearSeasonalConditions={this.handleSeasonalConditionsClear}
                                    />

                                </div>
                                <div className={styles.flexRow}>
                                    <DroughtAnalysisContainer
                                            disabled={this.isModifierApplied('soi')}
                                            numberOfScenarios={this.props.originalScenarios.length}
                                            selectedDroughts={this.state.selectedDroughts}
                                            handleDroughtSelectionChange={this.handleDroughtSelectionChange}
                                            handleDroughtSelectionClear={this.handleDroughtSelectionClear}
                                            applyDroughtAnalysis={this.props.applyDroughtAnalysis}
                                            Droughts={this.state.analysisDroughts}
                                        ></DroughtAnalysisContainer>
                                    <GrossMarginContainer2
                                        Crop={this.state.crop}
                                        disabled={this.grossMarginDisabled()['result']}
                                        disabledMessage={this.grossMarginDisabled()['reason']}
                                        scenarios={this.props.originalScenarios}
                                        modifiers={this.props.modifiers}
                                        applyGrossMarginAnalysis={this.handleGrossMarginApply}
                                        removeGrossMarginAnalysis={this.handleGrossMarginRemove}
                                    ></GrossMarginContainer2>
                                </div>
                            </div>
                        </BorderedSection>
                    </div>
                    <Box sx={{ flex: 1, flexDirection: 'column', flexGrow: 1, columnGap: 15}}>
                        <FormGroup >
                            {/* Scenario Data Field */}
                            <FormControl sx={{ margin: "0 1em",  }}>
                                <InputLabel>Scenario Field</InputLabel>
                                {this.getScenarioFieldSelector()}
                            </FormControl>
                            <div style={{height: '6px'}}></div>
                            {/* View Type */}
                            <FormControl sx={{ margin: "0 1em", }}>
                                <InputLabel>View Type</InputLabel>
                                <Select
                                    value={this.state.resultDisplayType || null}
                                    label="View Type"
                                    onChange={this.onViewTypeSelection}
                                >
                                    {
                                        this.RESULT_DISPLAY_TYPES.map((resultDisplayType) => {
                                            return (
                                                <MenuItem
                                                    key={resultDisplayType.value}
                                                    value={resultDisplayType.value}
                                                >
                                                    {resultDisplayType.label}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Box>
                </Box>
                {(this.props.showResults && this.props.results.length > 0) &&
                    this.getResultDisplay(this.state.resultDisplayType)}
            </div>
        )
    }
}

ScenarioResults.propTypes = {
    originalScenarios: PropTypes.arrayOf(PropTypes.object),
    results: PropTypes.arrayOf(PropTypes.object),
    modifiers: PropTypes.arrayOf(PropTypes.object),
    clearScenarioResults: PropTypes.func,
    applySoiAnalysis: PropTypes.func,
    applyGrossMarginAnalysis: PropTypes.func,
    removeGrossMarginAnalysis: PropTypes.func,
    clearSoiAnalysis: PropTypes.func,
    applySeasonalAnalysis: PropTypes.func,
    clearSeasonalAnalysis: PropTypes.func,
    showResults: PropTypes.bool,
    Droughts: PropTypes.array,
    LocalDroughts: PropTypes.array,
    WaterBalanceData: PropTypes.array,
};

ScenarioResults.defaultProps = {
    WaterBalanceData: [],
    Droughts: [],
    LocalDroughts: [],
    applySeasonalAnalysis: () => {},
    clearSeasonalAnalysis: () => {},
};

export default ScenarioResults;

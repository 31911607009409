import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import parallelCoordinates from 'highcharts/modules/parallel-coordinates';
import {anycaseEquals} from "../functions";

import InfoButton from "./InfoButton";

import styles from './sharedPageStyles.module.scss';
import miscHelper from "../ra_Helpers/miscHelper";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);
parallelCoordinates(Highcharts);

const BWParallelChart = (props) => {
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const chartOpt = {
      chart: {
        type: 'spline',
        parallelCoordinates: true,
        parallelAxes: {
          labels: {
            style: {
              color: '#999999'
            }
          },
          gridLineWidth: 0,
          lineWidth: 2,
          showFirstLabel: true,
          showLastLabel: true
        },
      },
      title: {
        text: getTitle(),
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span>' +
          '{series.name}: <b>{point.formattedValue}</b><br/>'
      },
      plotOptions: {
        series: {
          accessibility: {
            enabled: false
          },
          animation: false,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false
              }
            }
          },
          states: {
            hover: {
              halo: {
                size: 0
              }
            }
          },
          events: {
            mouseOver: function () {
              this.group.toFront();
            }
          }
        }
      },
      xAxis: {
        categories: makeIndicatorCategories(props.Indicators),
        offset: 10,
      },
      yAxis: configureYAxis(),
      colors: ['rgba(11, 200, 200, 0.1)'],
      series: makeParallelChartSeries(props.SummaryData, props.CurrentIndicator),
    };

    // console.log(chartOpt);
    setChartOptions(chartOpt);

    console.log(props.Indicators);
  }, [props.SummaryData, props.Indicators, props.CurrentIndicator]);

  const indicatorDescription = (indicatorValue) => {
    let result = '';

    const ind = props.Indicators.find(i => anycaseEquals(i.value, indicatorValue));
    if (ind) {
      console.log('BWParallel', ind);
      result = ind.display;
    } else {
      console.log(`BWParallel - no indicator found for ${indicatorValue} `);
    }

    return result;
  };

  const getTitle = () => {
    if (props.Title === '') {
      return 'Multi-criteria performance';
    } else {
      return props.Title;
    }
  };

  const configureYAxis = () => {
    let yaxis = [];

    if (props.Indicators) {
      props.Indicators.forEach(ind => {
        const indicator = ind.value;


        let tooltipFormat = '{value}';
        if (ind.units !== '') {
          tooltipFormat = `{value} (${ind.units})`;
        }

        const axisConfig = {
          name: indicator,
          labels: {
            format: '{value}',
            align: 'right',
            x: -5,
          },
          ticks: 8,
          tickWidth: 1,
          tickLength: 3,
          tooltipValueFormat: tooltipFormat,
          reversed: ind.axisReverse,
        }

        if (ind.hasOwnProperty('minAxisValue')) {
          axisConfig.min = ind.minAxisValue;
        }

        yaxis.push(axisConfig);
      });
    }

    return yaxis;
  };

  const makeIndicatorCategories = (indicators) =>  {
    const categories = [];

    if (indicators) {
      indicators.forEach(indicator => {
        let catDisplay = indicator.display;
        if (indicator.units !== '') {
          catDisplay += ' (' + indicator.units + ')';
        }
        categories.push(catDisplay);
      });
    }

    return categories;
  }

  const makeSeriesArray = (obj, seriesName) => {
    let ary = [];

    if (obj) {
      props.Indicators.forEach(ind => {
        const indicator = ind.value;
        if (obj[indicator] !== null) {
          const roundedValue = Math.round(obj[indicator] * 1000) / 1000;
          ary.push(roundedValue);
        } else {
          ary.push(null);
        }
      });
    }

    return ary;
  };

  const makeParallelChartSeries = (summaryData, current_indicator) => {
    const series = [];

    if (summaryData) {
      // console.log(summaryData);
      if (summaryData.hasOwnProperty("ReferenceRotation")) {
        const dataSeriesObj = {
          name: 'Reference rotation',
          color: 'blue',
          data: [...makeSeriesArray(summaryData.ReferenceRotation)],
          shadow: false,
        };
        series.push(dataSeriesObj);
      }

      const indicators = miscHelper.sectionCIndicators();
      const indicatorObj = indicators.find(i => anycaseEquals(i.value, current_indicator));

      if (indicatorObj) {
        // Flip the description for some indicators.
        // Some indicators the lowest value is the best...
        const indDesc = indicatorObj.display.toLowerCase();
        let bestDescription = `Highest ${indDesc} rotation`;
        if (indicatorObj.axisReverse) {
          bestDescription = `Lowest ${indDesc} rotation`;
        }
        let worstDescription = `Lowest ${indDesc} rotation`;
        if (indicatorObj.axisReverse) {
          worstDescription = `Highest ${indDesc} rotation`;
        }

        // Best for indicator.
        const bestRotation = summaryData.BestWorstList.find((b) => b.Indicator === current_indicator && b.BestWorst === 'BEST');
        if (bestRotation) {
          // console.log('Best', bestRotation);
          const dataSeriesObj = {
            name: bestDescription,
            color: 'green',
            data: [...makeSeriesArray(bestRotation)],
            shadow: false,
          };
          series.push(dataSeriesObj);
        }

        // Best for indicator.
        const worstRotation = summaryData.BestWorstList.find((b) => b.Indicator === current_indicator && b.BestWorst === 'WORST');
        if (worstRotation) {
          // console.log('Worst', worstRotation);
          const dataSeriesObj = {
            name: worstDescription,
            color: 'red',
            data: [...makeSeriesArray(worstRotation)],
            shadow: false,
          };
          series.push(dataSeriesObj);
        }
      }
    }

    return series;
  };

  return (
    <div className={styles.graphContainer}>
      <div>
        {props.SummaryData && <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          constructorType={'chart'}
        />}
      </div>
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
}

BWParallelChart.propTypes = {
  SummaryData: PropTypes.object.isRequired,
  Indicators: PropTypes.array.isRequired,
  CurrentIndicator: PropTypes.string.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
  InfoKey: PropTypes.string,
};

BWParallelChart.defaultProps = {
  Title: '',
  Explanation: '',
  InfoKey: '',
};

export default BWParallelChart;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Typography, Box, Divider } from '@mui/material';

import analysisDataHelper from '../data/analysisDataHelper';
import configHelper from '../data/configHelper';
import LoadingComponent from '../components/LoadingComponent';
import CheckboxList from '../components/CheckboxList';
import componentStyle from './SeasonConditionsContainer.module.scss';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const conditionsList = analysisDataHelper.getSeasonalConditionsList();

const SeasonConditionsContainer = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [loadingState, setLoadingState] = useState('loading');
  const [error, setError] = useState({});
  const [selectedConditions, setSelectedConditions] = useState([]);
  // const [conditionsList, setConditionsList] = useState([]);

  useEffect(() => {
    setLoadingState('loading');

    // setConditionsList([...analysisDataHelper.getSeasonalConditionsList()]);
    // Probably won't need to set initial selected conditions. Will normally be using
    // the defaults.
    if (props.SelectedConditions) {
      if (props.SelectedConditions.length !== 0) {
        // Note: We don't care if this prop has changed, we only ever want it
        // to setup initial selected conditions.
        setSelectedConditions([...props.SelectedConditions]);
      }
    } else {
      setSelectedConditions([...analysisDataHelper.getDefaultSeasonalConditions()]);
    }

    setLoadingState('success');
  }, [props.SelectedConditions]);

  const exceedingMaxScenarioCount = () => {
    let result = false;
    const maxScenarios = configHelper.MAX_SCENARIOS;

    if (selectedConditions && selectedConditions.length > 0) {
      // we are going to create a new scenario result for each different 'phase'
      // applied to an initial scenario.
      if (props.ScenarioCount) {
        if ((props.ScenarioCount * selectedConditions.length) > maxScenarios) {
          result = true;
        }
      }
    }

    return result;
  };

  // const getAnalysisStatus = () => {
  //   return props.modifiers.some((modifier) => modifier.type === 'grossMargin');
  // };

  const handleApply = () => {
    // event.preventDefault();
    // console.log('Apply conditions using', selectedConditions);
    setShowModal(false);
    props.ApplySeasonalConditions(selectedConditions);
  };

  const handleClear = () => {
    // event.preventDefault();
    setShowModal(false);
    setSelectedConditions([]);
    props.ClearSeasonalConditions();
  };

  const handleSelectionChange = (selectionAry) => {
    console.log('SeasonConditionsContainer - handleSelectionCHange', selectionAry);
    setSelectedConditions([...selectionAry]);
  };

  if (loadingState === 'loading') {
    return (
      <div>
        <LoadingComponent
          fullwidth={true}
          state='loading'
        />
      </div>
    );
  }

  if (loadingState === 'error') {
    return (
      <div>
        <LoadingComponent
          fullwidth={true}
          state='error'
          error={error}
        />
      </div>
    );
  }

  return (
    <div className={componentStyle.container}>
      <Button
        sx={{m: 1, p: .5, width: 180}}
        variant="outlined"
        onClick={() => setShowModal(true)}
      >
        Selected Seasons
      </Button>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Selected Seasons
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Select the conditions you are interested in and click "Apply".
              Years meeting these conditions will be highlighted.
          </Typography>
          <Divider sx={{margin: "1em 0"}} />
          <CheckboxList
            Options={conditionsList}
            Selected={selectedConditions}
            Columns={1}
            MultiSelect={true}
            OnSelect={handleSelectionChange}
          />
          { exceedingMaxScenarioCount() &&
            <Typography variant="subtitle1">
              Applying selected Seasonal Conditions to your current list of scenarios would
              exceed the scenario limit. Please reduce the number of Selected Seasons
              or reduce the overall number of scenarios.
            </Typography>
          }
          <Box sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
            <Button
              variant={"outlined"}
              sx={{marginRight: "1em"}}
              onClick={() => handleClear()}
            >
              Clear selections
            </Button>
            <Button
              variant={"contained"}
              onClick={handleApply}
              disabled={exceedingMaxScenarioCount() || (selectedConditions.length === 0)}
            >
              Apply
            </Button>
          </Box>
        </Box>
        </Modal>
    </div>
  );
}

SeasonConditionsContainer.propTypes = {
  ScenarioCount: PropTypes.number.isRequired,
  SelectedConditions: PropTypes.array,
  ApplySeasonalConditions: PropTypes.func,
  ClearSeasonalConditions: PropTypes.func,
};

SeasonConditionsContainer.defaultProp = {
  SelectedConditions: [],
  ApplySeasonalConditions: () => {},
  ClearSeasonalConditions: () => {},
};

export default SeasonConditionsContainer;

import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import pageStyles from './RAMainPage.module.scss';
import {useDispatch, useSelector} from "react-redux";
import ExploreStep from "./ExploreStep";
import CompareStep from "./CompareStep";
import {Button} from '@mui/material';
import styles from './RotationStepper.module.scss';
import {selectSite, selectPAWC, selectSiteDetail} from "../features/sitesSlice";
import {
  selectBaseRotationObj,
  selectNitrogenLevel,
  selectSeasonalConditions,
  selectSectionCIndicators,
  selectSectionCIndicator,
  selectSectionCIndicatorObj} from "../features/rotationSlice";
import {selectSowingRule} from "../features/sowingRulesSlice";
import {
  fetchExploreData,
  fetchCropLevelResults,
  fetchRotationLevelResults,
  updatePerformanceIndicators,
  selectCropLevelStatus,
  selectRotationLevelStatus,
  selectExploreStatus, selectPerformanceIndicators
} from "../features/resultsSlice";
import TextList from "../ra_components/TextList";

import Results from "./Results";

// const steps = ['Reference Results', 'Explore Results', 'Compare Results'];
const steps = ['Reference Results'];

const Analysis = (props) => {
  const dispatch = useDispatch();

  const [dataErrors, setDataErrors] = useState([]);

  // State for next/previous buttons.
  const [currentStep, setCurrentStep] = useState(0);
  const [showPrevious, setShowPrevious] = useState(false);
  const [ShowNext, setShowNext] = useState(true);
  const [previousLabel, setPreviousLabel] = useState('');
  const [nextLabel, setNextLabel] = useState('');

  const siteId = useSelector(selectSite);
  const siteDetails = useSelector(selectSiteDetail);
  const baseRotation = useSelector(selectBaseRotationObj);
  const pawc = useSelector(selectPAWC);
  const sowingRuleId = useSelector(selectSowingRule);
  const nitrogenLevel = useSelector(selectNitrogenLevel);
  const seasonalConds = useSelector(selectSeasonalConditions);
  const cIndicators = useSelector(selectSectionCIndicators);
  const cIndicator = useSelector(selectSectionCIndicator);
  const cIndicatorObj = useSelector(selectSectionCIndicatorObj);
  const performanceIndicators = useSelector(selectPerformanceIndicators);
  const currentCropLevelStatus = useSelector(selectCropLevelStatus);
  const currentRotationLevelStatus = useSelector(selectRotationLevelStatus);
  const currentExploreStatus = useSelector(selectExploreStatus);

  const currentStepName = steps[currentStep];
  // console.log('Analysis Step Name', currentStepName);

  const load_cropLevel = () => {
    const fetchParms = {
      RotationId: baseRotation.id,
      SowingRuleId: sowingRuleId,
      NLevel: nitrogenLevel,
      siteId: siteId,
      pawc: pawc,
    };
    dispatch(fetchCropLevelResults({parms: fetchParms}));
  };

  const load_rotationLevel = () => {
    const fetchParms = {
      RotationId: baseRotation.id,
      Indicators: [...cIndicators],
      Indicator: cIndicatorObj.value,
      SowingRuleId: sowingRuleId,
      NLevel: nitrogenLevel,
      siteId: siteId,
      pawc: pawc,
      SeasonalCond: seasonalConds,
    };
    dispatch(fetchRotationLevelResults({parms: fetchParms}));
  };

  const load_exploreRotations = () => {
    // console.log('Loading - Explore Rotations data');
    const fetchParms = {siteId, pawc};
    console.log('Explore Rotations: fetchParms', fetchParms);
    dispatch(fetchExploreData({parms: fetchParms}));
  };

  useEffect(() => {
    if (currentStepName.toLowerCase() === 'reference results') {
      if (currentCropLevelStatus === 'idle') {
        load_cropLevel();
      }
      if (currentRotationLevelStatus === 'idle') {
        load_rotationLevel();
      }
    }

    if (currentStepName.toLowerCase() === 'explore results') {
      if (currentExploreStatus === 'idle') {
        load_exploreRotations();
      }
    }

    configureButtons();
    props.OnStepTitleChange(currentStepName);
  }, [dispatch, currentCropLevelStatus, currentRotationLevelStatus, currentStepName]);

  const nextDisabled = () => {
    return false;
  };

  const isLastStep = () => {
    const result = (currentStep === (steps.length - 1));
    return result;
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      const newStep = currentStep - 1;
      setCurrentStep(newStep);
    }
    configureButtons();

    props.OnStepTitleChange(currentStepName);
  };

  const handleNext = () => {
    if (!isLastStep()) {
      const newStep = currentStep + 1;
      setCurrentStep(newStep);
    }
    configureButtons();
    props.OnStepTitleChange(currentStepName);
  };

  const handlePerformanceIndicatorChange = (newIndicators) => {
    if (Array.isArray(newIndicators)) {

    }

  };

  const configureButtons = () => {
    const previous = (currentStep > 0);
    const next = (currentStep < (steps.length - 1));

    let prevLabel = '';
    let nxtLabel = '';
    if (previous) {
      prevLabel = `< ${steps[currentStep - 1]}`;
    }
    if (next) {
      nxtLabel = `${steps[currentStep + 1]} >`;
    }

    setShowNext(next);
    setShowPrevious(previous);
    setNextLabel(nxtLabel);
    setPreviousLabel(prevLabel);
  };

  // const steps = ["Results", "Explore rotations", "Compare rotations"];
  const getStepContent = (step) => {
    switch(currentStepName.toLowerCase()) {
      case 'reference results':
        return <div>
          <Results />
        </div>
      case 'explore results':
        return <div>
          <ExploreStep PerformanceIndicators={performanceIndicators} />
        </div>
      case 'compare results':
        return <div>
          <CompareStep />
        </div>
    }
  };

  return (
    // <Container maxWidth="lg">
    <div className={pageStyles.container}>
      <div style={{ width: '100%' }}>
        <div className={styles.middleContainer}>
          {getStepContent(currentStep)}
        </div>
        <div className={styles.lowerContainer}>
          <div>
            {dataErrors.length > 0 && <TextList Items={dataErrors} AsErrors={true} ListTitle="Errors (correct befor proceeding)" />}
          </div>
          <div className={styles.buttonContainer}>
            {previousLabel !== '' && <Button onClick={() => handlePrevious()}>{previousLabel}</Button>}
            {nextLabel !== '' && <Button
              disabled={nextDisabled()}
              onClick={(event) => handleNext(event)}>
              {nextLabel}
            </Button>}
          </div>
        </div>
      </div>
    </div>
  );
}

Analysis.propTypes = {
  OnStepTitleChange: PropTypes.func,
};

Analysis.defaultProps = {
  OnStepTitleChange: () => {},
};

export default Analysis;

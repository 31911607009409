import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

// import whiteMarkerIcon from '../images/plant_wh.png'
// import greenMarkerIcon from '../images/plant_gr.png'
import styles from "./MapWrapper.module.scss";
import SiteMarker from "./SiteMarker";
const MAPAPIKEY = "AIzaSyADAGVt9qRvU0dDw_OMLDd87htpBbCQkBA";

// const selectedSiteMarkerStyle = {
//     position: 'absolute',
//     width: 20,
//     height: 20,
//     left: -20 / 2,
//     top: -20 / 2,
//     zIndex: 1,
// };
//
// const unselectedSiteMarkerStyle = {
//     position: 'absolute',
//     width: 20,
//     height: 20,
//     left: -20 / 2,
//     top: -20 / 2,
//   };
//
// class SiteMarker extends Component {
//
//     render() {
//         let markerImage = whiteMarkerIcon;
//         let markerZIndex = 0;
//         if (this.props.selected) {
//           markerImage = greenMarkerIcon;
//           markerZIndex = 1;
//         }
//
//         return (
//           <div className={styles.markerStyle}>
//               <span className={styles.circleText} title={this.props.site}>
//                 <img src={this.props.selected ? greenMarkerIcon : whiteMarkerIcon}/>
//               </span>
//           </div>
//         );
//
//         // if (this.props.selected) {
//         //     return (
//         //         <div style={selectedSiteMarkerStyle} onMouseOver={}>
//         //             <img src={greenMarkerIcon}/>
//         //         </div>
//         //     )
//         // } else {
//         //     return (
//         //         <div style={unselectedSiteMarkerStyle}>
//         //             <img src={whiteMarkerIcon}/>
//         //         </div>
//         //     )
//         // }
//     }
// }
//
// SiteMarker.propTypes = {
//     site: PropTypes.string,
//     selected: PropTypes.bool,
// }
//

const createMapOptions = (maps) => {
    return {
        zoomControlOptions: {
            position: maps.ControlPosition.RIGHT_CENTER,
            style: maps.ZoomControlStyle.SMALL
        },
        mapTypeControlOptions: {
             position: maps.ControlPosition.TOP_RIGHT
        },
        mapTypeControl: false,
        mapTypeId: 'satellite',
    };
}

class MapWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            center: {
                lat: -30,
                lng: 136
            },
            zoom: 3.5
        };
    }

    setMapCenter = (lat, lng) => {
        this.setState({
            center: {
                lat: lat,
                lng: lng,
            },
            zoom: 6
        });
    }

    handleMarkerClick = (event, value) => {
        this.setMapCenter(value.lat, value.lng);
        this.props.handleIconClick(event, value);
    }

    render() {
        return (
            <GoogleMapReact
                bootstrapURLKeys={{key: MAPAPIKEY}}
                center={this.state.center}
                zoom={this.state.zoom}
                options={createMapOptions}
                //yesIWantToUseGoogleMapApiInternals={true}
                //onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                onChildClick={this.handleMarkerClick}
            >
                {this.props.markerPoints.map((markerConfig) => {
                    return (
                        <SiteMarker
                            key={markerConfig.site}
                            lat={markerConfig.lat}
                            lng={markerConfig.lng}
                            site={markerConfig.site}
                            state={markerConfig.state}
                            selected={this.props.selectedSites.indexOf(markerConfig.site) !== -1}
                        />
                    )
                })}
            </GoogleMapReact>
        )
    }
}

MapWrapper.propTypes = {
    markerPoints: PropTypes.arrayOf(PropTypes.object),
    handleIconClick: PropTypes.func,
    selectedSites: PropTypes.array,
    center: PropTypes.object,
};

export default MapWrapper;

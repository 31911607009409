import React, { Component } from 'react';
import { Typography, LinearProgress, Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import pageStyles from "./LoadingComponent.module.scss";

class LoadingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        };
    }

    getErrorString(error) {
        if (typeof error === 'object' && error !== null) {
            // we are likely dealing with a scenario submit error here...
            let reason = error['reason'] || 'UNKNOWN_REASON';
            let scenario = error['scenario'] || {};
            delete scenario['data'];
            return JSON.stringify(scenario) + " - " + reason;
        }
        // we are assuming by default this will be a string
        return error;
    }

    getErrorDetails() {

        let errors = [];
        let initialError = this.props.error;

        if (initialError.hasOwnProperty('code') && initialError.hasOwnProperty('message')) {
            // this may be an AxiosError (e.g. network problem) rather than an error
            // returned by the API
            let code = initialError.code;
            let message = initialError.message;
            errors.push(code + ': ' + message);
        }

        if (initialError.hasOwnProperty('response')) {
            // we have been sent back something from the API

            if (initialError.response.hasOwnProperty('data')) {
                let errorData = initialError.response.data;
                // we sometimes send multiple errors or a single error
                // and that will be defined in 'errors' or 'error' respectively
                if (errorData.hasOwnProperty('errors')) {
                    errorData.errors.forEach(error => {
                        let errorString = this.getErrorString(error);
                        errors.push(errorString);
                    })
                } else if (errorData.hasOwnProperty('error')) {
                    errors.push(errorData.error);
                } else {
                    errors.push("An unknown error has occurred.");
                }
            } else {
                errors.push("An unknown error has occurred.");
            }
        }

        return errors;

    }

    render() {
        let styles = [pageStyles.spaced];
        if (this.props.fullwidth) {
            styles.push(pageStyles.fullWidth);
        }
        return (
            <div className={styles.join(' ')}>
                {this.props.state === 'loading' &&
                    <div>
                        <Typography variant="body1" align="center">
                            Loading, please wait...
                        </Typography>
                        <LinearProgress />
                    </div>
                }
                {this.props.state === 'error' &&
                    <div>
                        {this.props.dismissable ?
                            <Collapse in={this.state.open}>
                                <Alert
                                severity="error"
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        this.setState({open: false})
                                    }}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                                >
                                <AlertTitle>Error</AlertTitle>
                                {this.getErrorDetails().map((error, idx) => {
                                    return (
                                        <li key={`alertert${idx}`}>{error}</li>
                                    )
                                })}
                                </Alert>
                            </Collapse>
                        :
                            <Alert
                                severity="error"
                            >
                                <AlertTitle>Error</AlertTitle>
                                {this.getErrorDetails().map((error, index) => {
                                    return (
                                        <li key={`alerterr${index}`}>{error}</li>
                                    )
                                })}
                            </Alert>
                        }
                    </div>
                }
            </div>
        )
    }
}

LoadingComponent.propTypes = {
    fullwidth: PropTypes.bool,
    dismissable: PropTypes.bool,
    state: PropTypes.string,
    error: PropTypes.object,
};

export default LoadingComponent;

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Typography, Checkbox, FormControlLabel, Collapse} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LoadingComponent from "./LoadingComponent";
import {cropsToAbbrev, makeAColour} from "../functions";

//region Setup different table styles
const sx2ColBorder = {
  borderRightWidth: "1px",
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  borderBottomWidth: 0,
  padding: 0,

};

const sxNoBottom = {
  borderBottomWidth: 0,
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxNoBottom4 = {
  borderBottomWidth: 0,
  paddingRight: 4,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
};

const sxBottom = {
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: '#000000',
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxBottomRight = {
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: '#000000',
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxBottomRight1 = {
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: '#000000',
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  paddingRight: 1,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 1,
};

const sxRight = {
  borderBottomWidth: 0,
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxRight1 = {
  borderBottomWidth: 0,
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  paddingRight: 1,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 1,
};

const sxId = {
  maxWidth: '35px',
  borderBottomWidth: 0,
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  paddingRight: 1,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 1,
};

const sxContainer = {
  // width: "max-content",
  width: 'auto',
  height: 500,
  marginLeft: "auto",
  marginRight: "auto",
  padding: '20px',
};
//endregion

const RotationList = (props) => {
  const effectDone = useRef(false);
  const [rotations, setRotations] = useState([]);
  const [filteredListCount, setFilteredListCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.Rotations) {

      setLoading(true);

      // Convert the crop filter string to abbreviations.
      let filter = '';
      if (props.CropFilter) {
        if (props.CropFilter !== '') {
          filter = cropsToAbbrev(props.CropFilter, props.Crops);
          // console.log('RotationList abbrev crops', props.CropFilter, filter);
        }
      }

      if (filter === '') {
        // No filter = all rotations.
        setRotations([...props.Rotations]);
        setFilteredListCount(props.Rotations.length);
      } else {
        // Apply AND filter to the list passed as prop.
        let cropChecker = (arr, target) => target.every(v => arr.includes(v));

        // const there = checker(rot.crops, filter)
        const filterCrops = filter.split(",")
        const filteredRotations = [];
        props.Rotations.forEach(rotn => {
          // console.log(rot);
          const rotationCrops = rotn.RotationName.split("_");
          if (cropChecker(rotationCrops, filterCrops)) {
            filteredRotations.push(rotn);
          }
        });

        setRotations([...filteredRotations]);
        setFilteredListCount(filteredRotations.length);
      }

      setLoading(false);
    }

  }, [props.Rotations, props.CropFilter, props.CurrentRotation]);

  const handleToggle = (value) => {
    let newSelected = [];

    if (props.MaxSelectCount === 1) {
      // Single selection only.
      newSelected = [value.toString()];
    } else {
      // Multiple selection is allowed,
      const currentIndex = props.Selected.indexOf(value.toString());
      if (currentIndex !== -1) {
        // Value already in the array so remove it.
        newSelected = props.Selected.filter(x => x.toString() !== value.toString());
      } else {
        // Cannot exceed MaxSelectCount.
        if (props.Selected.length >= props.MaxSelectCount) {
          // Do nothing. Already at limit.
        } else {
          // Limit not reached yet.
          newSelected = [...props.Selected];
          newSelected.push(value.toString());
        }
      }
    }
    props.OnSelect(newSelected);
  };

  const isSelected = (optionValue) => {
    let result = false;
    if (props.Selected.indexOf(optionValue.toString()) !== -1) {
      result = true;
    }
    return result;
  };

  if (loading) {
    return <LoadingComponent State="loading" />
  }

  const renderCrop = (cropName, rowIndex, colIndex, withBorder) => {
    const cellKey = `row${rowIndex}c${cropName}_${colIndex}`;
    const lcCrop = cropName.toLowerCase().trim();

    let cropObj = null;
    if (props.Crops) {
      cropObj = props.Crops.find(c => c.Name.toLowerCase().trim() === lcCrop);
      // console.log(cropObj);
    }

    const cellStyle = {};
    if (cropObj) {
      cellStyle.backgroundColor = makeAColour(cropObj.bg_colour);
      cellStyle.color = makeAColour(cropObj.fg_colour);
      cellStyle.padding = "0px 3px";
      cellStyle.margin = 0;

      if (withBorder) {
        cellStyle.borderRightWidth = 1;
        cellStyle.borderRightStyle = "solid";
        cellStyle.borderRightColor = "#000000";
      }

      return (
        <TableCell key = {cellKey} align="center" style={cellStyle}>
          {lcCrop}
        </TableCell>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {props.Title !== '' && <Typography variant="h5" align="left">{props.Title}</Typography>}
      <TableContainer component={Paper} sx={{...sxContainer}}>
        <div>Rotations in list: {filteredListCount}</div>
        <Table sx={{width: "80%",
                    "&.MuiTable-root": {
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#000000",
                      borderSpacing: 1,
                    }
                  }}
               size="small"
               aria-label="rotation list"
        >
          <TableHead sx={{
            top: 0,
            zIndex: 1,
            "& .MuiTableCell-root": { backgroundColor: "background.paper" },
          }}>
            <TableRow key="header1" >
              <TableCell align="center" sx={{...sxId}}> </TableCell>
              <TableCell colSpan="2" align="center" sx={{...sx2ColBorder}}>Year 1</TableCell>
              <TableCell colSpan="2" align="center" sx={{...sx2ColBorder}}>Year 2</TableCell>
              <TableCell colSpan="2" align="center" sx={{...sx2ColBorder}}>Year 3</TableCell>
              <TableCell colSpan="2" align="center" sx={{...sx2ColBorder}}>Year 4</TableCell>
              <TableCell align="center" sx={{...sxNoBottom}}>Targeted</TableCell>
              <TableCell align="center" sx={{...sxNoBottom}}>Summer/winter</TableCell>
              <TableCell align="center" sx={{...sxNoBottom}}>Planned time</TableCell>
            </TableRow>
            <TableRow sx={{maxHeight: 15}} key="header2" >
              <TableCell align="center" sx={{...sxBottomRight1}}>#</TableCell>
              <TableCell align="center" sx={{...sxBottom}}>Summer</TableCell>
              <TableCell align="center" sx={{...sxBottomRight}}>Winter</TableCell>
              <TableCell align="center" sx={{...sxBottom}}>Summer</TableCell>
              <TableCell align="center" sx={{...sxBottomRight}}>Winter</TableCell>
              <TableCell align="center" sx={{...sxBottom}}>Summer</TableCell>
              <TableCell align="center" sx={{...sxBottomRight}}>Winter</TableCell>
              <TableCell align="center" sx={{...sxBottom}}>Summer</TableCell>
              <TableCell align="center" sx={{...sxBottomRight}}>Winter</TableCell>
              <TableCell align="center" sx={{...sxBottom}}>crops/year</TableCell>
              <TableCell align="center" sx={{...sxBottom}}>crop ratio</TableCell>
              <TableCell align="center" sx={{...sxBottom}}>in fallow</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rotations.map((row, rowIndex) => {
              // const rowKey = `ROW${rowIndex + 2}`;
              const rowKey = `ROW${row.id}`;
              // const isSelected = Number(row.id) === props.CurrentRotation;
              const rowLabel = row.id.toString();
              return (
                <TableRow
                  hover
                  key={rowKey}
                  onClick={(event) => handleToggle(row.id)}
                >
                  <TableCell key={rowKey + 'x0'} sx={{...sxId}}>
                    <FormControlLabel
                      control = {
                        <Checkbox style={{paddingRight: '2px'}}
                          color="primary"
                          checked={isSelected(row.id)}
                        />
                      }
                      label={rowLabel}>
                    </FormControlLabel>
                  </TableCell>
                  {renderCrop(row.Yr1_Summer, rowIndex, 1, 0)}
                  {renderCrop(row.Yr1_Winter, rowIndex, 2, 1)}
                  {renderCrop(row.Yr2_Summer, rowIndex, 3, 0)}
                  {renderCrop(row.Yr2_Winter, rowIndex, 4, 1)}
                  {renderCrop(row.Yr3_Summer, rowIndex, 5, 0)}
                  {renderCrop(row.Yr3_Winter, rowIndex, 6, 1)}
                  {renderCrop(row.Yr4_Summer, rowIndex, 7, 0)}
                  {renderCrop(row.Yr4_Winter, rowIndex, 8, 1)}
                  <TableCell key={rowKey + 'x1'} align="right" sx={{...sxNoBottom4}}>{row.TargetedCrops}</TableCell>
                  <TableCell key={rowKey + 'x2'} align="right" sx={{...sxNoBottom4}}>{row.CropRatio}</TableCell>
                  <TableCell key={rowKey + 'x3'} align="right" sx={{...sxNoBottom4}}>{row.PlannedTimeFallow}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

RotationList.propTypes = {
  Rotations: PropTypes.arrayOf(PropTypes.object).isRequired,
  Crops: PropTypes.arrayOf(PropTypes.object).isRequired,
  Title: PropTypes.string,
  MaxSelectCount: PropTypes.number,
  CropFilter: PropTypes.string,
  OnSelect: PropTypes.func,
  ShowResults: PropTypes.bool,
  Selected: PropTypes.array,
}


RotationList.defaultProps = {
  Title: 'Rotation List',
  CropFilter: '',
  OnSelect:  () => {},
  ShowResults: false,
  CurrentRotation: 0,
  MaxSelectCount: 1,
  Selected: [],
}

export default RotationList;


import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItem from '@mui/material/List';
import styles from './Training.module.scss';

const TrainingLine = (props) => {
  const [lines, setLines] = useState([]);

  useEffect(() => {
    if (props.Value) {
      setLines(props.Value.split(','));
    }
  }, []);

  const shortLabel = props.Label.split('').filter(char => char.match(/^[a-zA-Z]$/));
  const lineKey = `line_${shortLabel.join('')}_${props.Index}`;

  return (
    <div className={styles.itemContainer} key={lineKey}>
      <div className={styles.detailLeft} >
        <Typography variant="subtitle1" key={`${lineKey}A`}>
          {props.Label}
        </Typography>
      </div>
      <div className={styles.detailRight}>
        <List>
          {lines.map((line, i) => {
            return (
              <ListItem disablePadding key={`${lineKey}B_${i}`}>
                <Typography variant="button" sx={{p: 0, m: 0, fontWeight: 700}}>
                  {props.IsURL && <a href={line} target="_blank" rel="noreferrer">{line}</a>}
                  {!props.IsURL && <div>{line}</div>}
                </Typography>
              </ListItem>
            )
          })}
        </List>
      </div>
    </div>
  );
}

TrainingLine.propTypes = {
  Label: PropTypes.string.isRequired,
  Value: PropTypes.string.isRequired,
  IsURL: PropTypes.bool,
  Index: PropTypes.number.isRequired,
}

TrainingLine.defaultProps = {
  IsURL: false,
}

export default TrainingLine;

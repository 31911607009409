import React, {useState} from 'react';
import { PropTypes } from 'prop-types';
import { FormControl, FormControlLabel, FormGroup, FormLabel, Checkbox } from '@mui/material';

import pageStyles from "./CheckboxGroup.module.scss";

const CheckboxGroup2 = (props) => {
    return (
      <FormControl fullWidth sx={props.controlStyling}>
        <FormGroup row={props.horizontal} className={pageStyles.container}>
          <div className={pageStyles.container}>
            <div className={pageStyles.label}>
              { props.label && <FormLabel sx={props.labelStyling}>{props.label}</FormLabel>}
            </div>
            <div className={pageStyles.content}>
              {
                props.options.map((option, index) => {
                  return (
                    <FormControlLabel
                      key={option}
                      checked={typeof props.isChecked !== 'undefined' ? props.isChecked(option) : null}
                      disabled={typeof props.isDisabled !== 'undefined' ? props.isDisabled(option) : null}
                      onChange={typeof props.onChange !== 'undefined' ? props.onChange : null}
                      control={<Checkbox data-update-index={props.updateIndex} value={option} />}
                      label={typeof props.optionLabels !== 'undefined' ? props.optionLabels[index] : option}
                    />
                  )
                })
              }
            </div>
          </div>
        </FormGroup>
      </FormControl>
    );
};

CheckboxGroup2.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  optionLabels: PropTypes.array,
  horizontal: PropTypes.bool,
  controlStyling: PropTypes.object,
  labelStyling: PropTypes.object,
  isChecked: PropTypes.func,
  isDisabled: PropTypes.func,
  onChange: PropTypes.func,
  updateIndex: PropTypes.string,
}

CheckboxGroup2.defaultProps = {
  autoSelectSingleEntry: true,
}

export default CheckboxGroup2;

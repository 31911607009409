import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TriangularDistScenario from "./TriangularDistScenario";

const TriangularDialog = (props) => {
  const {open} = props;

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = (value) => {
    props.onSubmit(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Triangular Distribution Parameters</DialogTitle>
      <TriangularDistScenario CancelClick={handleClose} SubmitClick={handleSubmit} DefaultSeason={props.DefaultSeason} />
    </Dialog>
  );
}

TriangularDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  DefaultSeason: PropTypes.string,
};

TriangularDialog.defaultProps = {
  DefaultSeason: '',
};

export default TriangularDialog;

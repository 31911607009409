import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {makeAColour} from "../functions";

const CropList = (props) => {
  const effectDone = useRef(false);
  const initDone = useRef(false);
  const [cropsArray, setCropsArray] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (effectDone.current === false) {
      // Columnise the crops.
      if (props.Crops.length > 0 && props.Columns > 0) {
        const maxRows = Math.ceil(props.Crops.length / props.Columns);
        // Fallow is not displayed.
        const filterCrops = props.Crops.filter(c => c.Name.toLowerCase().trim() !== 'fallow');

        const sortedCrops = filterCrops.toSorted((a, b) => a.Name.localeCompare(b.Name));

        // Setup multidimensional array of crops. This is controlled
        // by the passed Columns value.
        let cropArray = [];
        for (let r = 0; r < maxRows; r++) {
          const thisRow = [];
          for (let c = 0; c < props.Columns; c++) {
            const cropIndex = (c * maxRows) + r;
            if (cropIndex < sortedCrops.length) {
              thisRow.push(sortedCrops[cropIndex]);
            }
          }
          cropArray.push(thisRow);
        }

        if (!initDone.current) {
          // console.log('initialising selected crops');
          setSelected([...props.SelectedCrops]);
          initDone.current = true;
        }

        setCropsArray(cropArray);
        // console.log('CropList useEffect cropArray', cropArray);
      }
    }

    return () => {
      effectDone.current = true;
    }
  }, [props.Crops, props.Columns]);

  const handleToggle = (cropname) => {
    const currentIndex = selected.indexOf(cropname);
    const newSelected = [...selected];

    if (currentIndex < 0) {
      newSelected.push(cropname);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected([...newSelected]);
    props.CropSelect(newSelected);
  };

  //region Setup table styles
  const sxContainer = {
    width: "max-content",
    height: "max_content",
    marginLeft: "auto",
    marginRight: "auto",
  };
  //endregion

  const isSelected = (cropName) => {
    return selected.indexOf(cropName.toLowerCase()) === -1 ? false : true;
  };

  const renderRow = (cropItemsArray, rowKey) => {
    // console.log(cropItemsArray);
    const cellArray = cropItemsArray.map(cropItem => {
      return renderCell(cropItem, rowKey);
    });

    return (
      <TableRow key={rowKey}>
        {cellArray}
      </TableRow>
    );
  };

  const renderCell = (cropItem, rowKey) => {
    const lcCrop = cropItem.Name.toLowerCase();
    const cellKey = rowKey + lcCrop;

    const cellStyle = {
      backgroundColor: makeAColour(cropItem.bg_colour),
      color: makeAColour(cropItem.fg_colour),
      padding: "0px 3px",
      margin: 0,
    };

    return (
      <TableCell
        key={cellKey}
        align="left"
        style={cellStyle}>
        <FormControlLabel
          control = {
            <Checkbox
              color="primary"
              checked={isSelected(lcCrop)}
              onChange={() => handleToggle(lcCrop)}
            />
          }
          label={lcCrop}>
        </FormControlLabel>
      </TableCell>
    );
  };

  return (
    <div style={{flex: 1, width: '100%'}}>
      {props.Title !== '' && <Typography variant="h5" align="left">{props.Title}</Typography>}
      <TableContainer component={Paper} sx={{...sxContainer}}>
        <Table
          sx={{
            width: "60%",
            "&.MuiTable-root": {
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#000000",
              borderSpacing: 1,
            }
          }}
          size="small"
          aria-label="rotation list"
          stickyHeader
        >
          <TableHead>
            <TableRow key="header1" >
            </TableRow>
          </TableHead>
          <TableBody>
            {cropsArray.map((row, rowIndex) => {
              const rowKey = `clrow${rowIndex}`;
              return renderRow(row, rowKey);
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

CropList.propTypes = {
  Crops: PropTypes.array.isRequired,
  Columns: PropTypes.number,
  CropSelect: PropTypes.func,
  Title: PropTypes.string,
  SelectedCrops: PropTypes.array,
}


CropList.defaultProps = {
  Columns: 2,
  Title: 'Crop List',
  CropSelect: () => {
  },
  SelectedCrops: [],
}

export default CropList;

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from "./RotationStepper.module.scss";
import {Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import pageStyles from "./Results.module.scss";
import CompareSectionA from "./CompareSectionA";
import CompareSectionB from "./CompareSectionB";
import CompareSectionC from "./CompareSectionC";

//region Defined styles Accordion components
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={4} square={false} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  borderRadius: '10px',
  marginTop: '2px',
  backgroundColor: 'lightgray',
  color: 'black',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.4rem', color: '#fefefe'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',   Commenting this out puts icons on the right
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  fontWeight: 'bold',
  color: '#fefefe',
  marginLeft: '10px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, .7)',
  padding: theme.spacing(0),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
//endregion

const CompareStep = (props) => {
  const [expandedAry, setExpandedAry] = useState(['sectionA', 'sectionB', 'sectionC']);
  const effectDone = useRef(false);

  useEffect(() => {
    if (effectDone.current === true) {
    }

    return () => {
      effectDone.current = true;
    }
  }, []);

  const inExpanded = (panelName) => {
    if (expandedAry.indexOf(panelName) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const toggleExpanded = (panelName) => {
    let newExpanded;
    if (expandedAry.indexOf(panelName) !== -1) {
      // In the array so remove it.
      newExpanded = expandedAry.filter(x => x !== panelName);
    } else {
      newExpanded = [...expandedAry, panelName];
    }

    setExpandedAry(newExpanded);
  };

  return (
    <div style={{padding: 2}}>
      <Accordion
        expanded={inExpanded('sectionA')}
        onChange={() => toggleExpanded('sectionA')}>
        <AccordionSummary aria-controls="sectionA-content" id="sectionA-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20}>
            A. Select a preferred crop rotation
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{maxHeight: '145px'}}>
          <div className={pageStyles.tableContainer}>
            <CompareSectionA />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={inExpanded('sectionB')}
        onChange={() => toggleExpanded('sectionB')}>
        <AccordionSummary aria-controls="sectionB-content" id="sectionB-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20}>
            B. Crop-level results
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CompareSectionB />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={inExpanded('sectionC')}
        onChange={() => toggleExpanded('sectionC')}>
        <AccordionSummary aria-controls="sectionC-content" id="sectionC-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20}>
            C. Rotation-level results
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CompareSectionC />
        </AccordionDetails>
      </Accordion>

    </div>
  );
}

export default CompareStep;

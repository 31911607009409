import React from 'react';
import PropTypes from 'prop-types';
import CropGauge from "../ra_components/CropGauge";
import styles from "../ra_components/sharedPageStyles.module.scss";

const PlantingsChart = (props) => {

  const haveTitle = (props.Title !== '');

  return (
    <div className={styles.graphContainer}>
      { haveTitle && <div className={styles.plantingTitle}>{props.Title}</div>}
      <div>
        { props.Plantings.map((p, index) => {
          const plantKey = `PChartKey${index}`;
          const plantDivKey = `PDivKey${index}`;
          const plantingId = `PChartId${index}`;
          return (
            <div className={styles.gaugeContainer} id={plantingId} key={plantDivKey}>
              <CropGauge
                key={plantKey}
                GaugeColour={p.GaugeColour}
                GaugeMax={p.GaugeMax}
                GaugeValue={p.GaugeValue}
                Title={p.Title}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
};

// Passed array is expected with this structure:
// {
//  GaugeColour: color (string),
//  GaugeMax: maximum planting intensity (number),
//  GaugeValue: achieved planting intensity (number),
//  Title: name of rotation (string),
// }
PlantingsChart.propTypes = {
  Plantings: PropTypes.array.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
};

PlantingsChart.defaultProps = {
  Title: '',
  Explanation: '',
};

export default PlantingsChart;

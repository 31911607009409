import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Grid, Tooltip} from "@mui/material";
import ResultIndicatorModal from "../ra_components/ResultIndicatorModal";
import SeasonalCondModal from "../ra_components/SeasonalCondModal";
import styles from "../ra_components/sharedPageStyles.module.scss";
import InfoButton from "../ra_components/InfoButton";
import BoxChart from "../ra_components/BoxChart";
import LineChart from "../ra_components/LineChart";
import {
  getRotationError,
  getRotationStatus, selectCrops,
  selectResultIndicator,
  selectResultIndicatorObj,
  selectResultIndicators,
  selectSeasonalConditions, updateResultIndicator
} from "../features/rotationSlice";
import miscHelper from "../ra_Helpers/miscHelper";

const seasonalCond = miscHelper.seasonalClimateConditions();

const CompareSectionB = (props) => {
  const effectDone = useRef(false);
  const dispatch = useDispatch();

  const currentUnits = '';
  const yearlyResults = [];
  const currentResultIndicator = '';
  const currentResultIndicatorDesc = '';
  const seasonalConditions = [];

  const crops = useSelector(selectCrops);
  const allIndicators = useSelector(selectResultIndicators);

  const selectableIndicators = useMemo(() => {
    return allIndicators.filter(r => r.selectable);
  }, [allIndicators]);

  useEffect(() => {
    if (effectDone.current === true) {
    }

    return () => {
      effectDone.current = true;
    }
  }, []);


  const handleResultIndicatorChange = (index, new_indicator) => {
    // In this instance the index can be ignored.
    console.log('SectionBResults', new_indicator);
    if (new_indicator) {
      if (new_indicator.length > 0) {
        dispatch(updateResultIndicator(new_indicator));
      }
    }
  };

  const handleSeasonalConditionsChange = (conditions) => {
    const display = [];

    if (conditions) {
      if (conditions.length > 0) {
        conditions.forEach(c => {
          const condObj = seasonalCond.find(s => s.value = c);
          if (condObj) {
            display.push(condObj.display);
          }
        });
      }
    }
    // console.log(conditions);
    return display;
  };

  return (
    <Box >
      <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
          <div>
            <ResultIndicatorModal
              CurrentIndicator={currentResultIndicator}
              CurrentIndicatorDesc={currentResultIndicatorDesc}
              SelectableIndicators={selectableIndicators}
              OnResultIndicatorChange={handleResultIndicatorChange}
              Label='Result Indicator'
              LabelBefore={false}
              WithBorder={false}
            />
          </div>
          <div style={{marginLeft: '10px'}}>
            <SeasonalCondModal OnSeasonalConditionsChange={handleSeasonalConditionsChange} />
          </div>
        </Box>
        <div style={{margin: '5px'}}>
          <Tooltip title="Click to export this section">
            <Button
              sx={{minWidth: '180px', maxHeight: '32px'}}
              variant="outlined"
              size="small"
            >
              Export to PDF
            </Button>
          </Tooltip>
        </div>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="boxplotcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_box_plot" Position='auto' AnchorTo="boxplotcontainer">
                <BoxChart
                  ResultsData={yearlyResults}
                  ResultIndicator={currentResultIndicator}
                  ResultIndicatorDesc={currentResultIndicatorDesc}
                  SeasonalConditions={seasonalConditions}
                  Crops={crops}
                  Units={currentUnits}
                  InfoKey="box_plot"

                />
              </InfoButton>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div id="linechartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer">
                <LineChart
                  ResultsData={yearlyResults}
                  ResultIndicator={currentResultIndicator}
                  ResultIndicatorDesc={currentResultIndicatorDesc}
                  SeasonalConditions={seasonalConditions}
                  Crops={crops}
                  Units={currentUnits}
                />
              </InfoButton>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div id="linechartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer">
                <LineChart
                  ResultsData={yearlyResults}
                  ResultIndicator={currentResultIndicator}
                  ResultIndicatorDesc={currentResultIndicatorDesc}
                  SeasonalConditions={seasonalConditions}
                  Crops={crops}
                  Units={currentUnits}
                />
              </InfoButton>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div id="linechartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer">
                <LineChart
                  ResultsData={yearlyResults}
                  ResultIndicator={currentResultIndicator}
                  ResultIndicatorDesc={currentResultIndicatorDesc}
                  SeasonalConditions={seasonalConditions}
                  Crops={crops}
                  Units={currentUnits}
                />
              </InfoButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default CompareSectionB;

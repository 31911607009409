import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {updateFertiliser} from "../features/pricesSlice";
import {Box, TextField, Tooltip, Typography} from "@mui/material";

const FertiliserPrices = (props) => {
  const dispatch = useDispatch();
  const effectDone = useRef(false);
  const [fertilisers, setFertilisers] = useState([]);

  useEffect(() => {
    if (effectDone.current === false) {
      if (props.Fertilisers) {
        setFertilisers([...props.Fertilisers]);
      }
    }

    return () => {
      effectDone.current = true;
    }
  }, [props.Fertilisers]);

  const handleCostChange = (event, name) => {
    const newValue = Number(event.target.value);

    //region Update the store
    const update = {
      name,
      cost: newValue,
    };

    dispatch(updateFertiliser(update));
    //endregion

    const newFerts = fertilisers.map((fert) => {
      if (fert.name === name) {
        return {...fert, userCost: newValue};
      }
      return fert;
    });

    setFertilisers(newFerts);
    // props.UpdateFertilisers(newFerts);
  };

  const onRowClick = (rowkey) => {
    console.log('Row Clicked:', rowkey);
  };

  const renderRow = (fertiliserObj) => {
    // console.log(commodityObj);
    const {name} = fertiliserObj;
    const {cost, userCost} = fertiliserObj;

    // Only display user fields when they are different to the defaults.
    let renderUserCost = userCost.toString();
    if (userCost === cost) {
      renderUserCost = '';
    }

    const rowKey = `R_${name}`.replace(' ', '_');
    const idCost = rowKey + '_cost';

    return (
      <tr key={rowKey} onClick={() => onRowClick(rowKey)}>
        <td style={{height: 0, width: '50%'}}>
          {name}
        </td>
        <td align="right" style={{paddingRight: '10px'}}>
          {cost.toString()}
        </td>
        <td align="right" style={{paddingRight: '10px'}}>
          <TextField
            id={idCost}
            type="number"
            inputProps={{min: 0, style: { textAlign: 'center' }}}
            variant="standard"
            defaultValue={renderUserCost}
            onChange={event => handleCostChange(event, name)}
          />
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div>
          <table aria-label="fertiliser list">
            <thead>
            <tr key="header1" >
                <th align="center"> </th>
                <th colSpan="2" align="center">
                  <Typography component="div">
                    <Box sx={{ fontWeight: 'bold', m: 1 }}>Fertiliser Price</Box>
                  </Typography>
                </th>
              </tr>
              <tr key="header2" >
                <th align="center"></th>
                <th colSpan="2" align="center">
                  <Typography component="div">
                    <Tooltip title="Australian Dollars per tonne">
                      <Box sx={{ fontWeight: 'bold', m: 1 }}>(AUD/t)</Box>
                    </Tooltip>
                  </Typography>
                </th>
              </tr>
              <tr key="header3" >
                <th align="center">Name</th>
                <th align="center">Default</th>
                <th align="center">User Defined</th>
              </tr>
            </thead>
            <tbody>
              {fertilisers.map((row, rowIndex) => {
                {return renderRow(row)}
              })}
            </tbody>
          </table>
      </div>
    </div>
  );
}

FertiliserPrices.propTypes = {
  Fertilisers: PropTypes.arrayOf(PropTypes.object).isRequired,
  UpdateFertilisers: PropTypes.func,
}


FertiliserPrices.defaultProps = {
  UpdateFertilisers: () => {},
}

export default FertiliserPrices;

import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axiosRotation from '../ra_data/axiosRotation';

const initialState = {
  infoItems: [],
  status: 'idle',   // 'idle' | 'loading' | 'success' | 'failed'
  error: null,
};

export const fetchInfoItems = createAsyncThunk('infoItems/fetchItems', async (arg, {getState}) => {
  try {
    const url = `/infodata`;
    const response = await axiosRotation.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const infoDataSlice = createSlice({
  name: 'infoItems',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInfoItems.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchInfoItems.fulfilled, (state, action) => {
        state.status = 'success';

        const {infoData} = action.payload;
        //console.log(infoData);

        state.infoItems = [...infoData];
      })
      .addCase(fetchInfoItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export const selectInfoItems = (state) => state.infoItems.infoItems;
export const selectInfoItemByKey = (state, key) => {
  if (state.infoItems.infoItems) {
    return state.infoItems.infoItems.find(item => item.InfoKey === key);
  }

  return null;
};

export default infoDataSlice.reducer;

import axios from 'axios';

const axiosRotation = axios.create({

  // Production
  baseURL: 'https://armonline.net.au/rotation',

  // Development
  // baseURL: 'http://localhost:3004/',

  timeout: 30000,
  headers: {}
});

export default axiosRotation;

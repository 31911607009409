import React, {useState, useEffect, useRef, useMemo} from 'react';
import {Box, Grid, Typography} from "@mui/material";
import BWParallelChart from "../ra_components/BWParallelChart";
import BestWorstChart from "../ra_components/BestWorstChart";
import {useDispatch, useSelector} from "react-redux";
import {
  selectRotationLevelError,
  selectRotationLevelData,
  selectRotationLevelStatus,
  fetchRotationLevelResults} from "../features/resultsSlice";
import {
  selectBaseRotation,
  selectCrops, selectNitrogenLevel, selectSeasonalConditions,
  selectSectionCIndicatorObj,
  selectSectionCIndicators,
  updateSectionCIndicator} from "../features/rotationSlice";
import ResultIndicatorModal from "../ra_components/ResultIndicatorModal";
import {anycaseEquals, SummariseDataByIndicator} from "../functions";
import ScatterChart from "../ra_components/ScatterChart";
import SectionCRotationList from "../ra_components/SectionCRotationList";
import LoadingComponent from "../ra_components/LoadingComponent";
import {selectSowingRule} from "../features/sowingRulesSlice";
import {selectPAWC, selectSite} from "../features/sitesSlice";
import styles from '../ra_components/sharedPageStyles.module.scss';
import InfoButton from "../ra_components/InfoButton";
import PlantingsChart from "./PlantingsChart";
import miscHelper from '../ra_Helpers/miscHelper';

const ResultsRotationLevel = (props) => {
  const effectDone = useRef(false);
  const dispatch = useDispatch();
  const resultsData = useSelector(selectRotationLevelData);
  const resultsStatus = useSelector(selectRotationLevelStatus);
  const resultsError = useSelector(selectRotationLevelError);
  const crops = useSelector(selectCrops);

  const allIndicators = useSelector(selectSectionCIndicators);
  const indicatorObj = useSelector(selectSectionCIndicatorObj);
  const currentRotationId = useSelector(selectBaseRotation);
  const currentNLevel = useSelector(selectNitrogenLevel);
  const currentSowingRuleId = useSelector(selectSowingRule);
  const currentSiteId = useSelector(selectSite);
  const currentPAWC = useSelector(selectPAWC);

  const [bwRotations, setBWRotations] = useState([]);

  // Data required for ParallelChart Component.
  const [parallelSummaryData, setParallelSummaryData] = useState({});

  const [plantings, setPlantings] = useState([]);

  const selectableIndicators = useMemo(() => {
    return allIndicators.filter(r => r.selectable);
  }, [allIndicators]);

  const parallelIndicators = useMemo(() => {
    const filteredIndicators = allIndicators.filter(r => r.spider);
    filteredIndicators.sort((a, b) => a.spiderSort - b.spiderSort);

    return filteredIndicators;
  }, [allIndicators]);


  useEffect(() => {

    if (resultsStatus === 'success') {
      if (resultsData ) {
        const summaryData = SummariseDataByIndicator(resultsData, parallelIndicators, crops, ['fallow']);
        setParallelSummaryData(summaryData);

        getBestWorstRotations();

        const myPlantings = [
          {
            GaugeColour: miscHelper.getCompareColour(miscHelper.CompareColours.REFERENCE).colour,
            GaugeMax: resultsData.CropsPerYear.target,
            GaugeValue: resultsData.CropsPerYear.achieved,
            Title: '',
          },
        ];
        console.log('Plantings', myPlantings);
        setPlantings([...myPlantings]);
      }
    }


  }, [dispatch, resultsStatus, resultsData, crops, parallelIndicators]);

  const handleIndicatorChange = (index, indicatorValues) => {
    // Index is ignore in this instance.
    // console.log('SectionC indicator change', indicatorValues);
    if (indicatorValues) {
      if (indicatorValues.length > 0) {
        dispatch(updateSectionCIndicator(indicatorValues));
      }
    }

    getBestWorstRotations();
  };

  const getBestWorstRotations = () => {
    if (resultsData.TreatTimeAgg && resultsData.TreatTimeAgg.length > 0) {
      // Step 1:  Get the indicator details.
      const indicator = allIndicators.find(i => anycaseEquals(i.value, currentResultIndicator));

      // Step 2:  Sort the data in the required order for the current indicator.
      if (indicator) {
        const sortIndicator = (a, b) => {
          return a[indicator.value] - b[indicator.value];
        };

        const sortedByIndicator = resultsData.TreatTimeAgg.slice(0).sort(
          (a, b) => a[indicator.value] - b[indicator.value]
        );
        if (indicator.axisReverse) {
          sortedByIndicator.reverse();
          // Array should now be from worst to best.
        }


        // Step 3: Get the index of the reference rotation.
        const referenceIndex = sortedByIndicator.findIndex(i => i.BaseRotationId === currentRotationId);

        // Step 3: Update state with best, worst & reference rotations.
        const bwArray = [];
        bwArray.push(sortedByIndicator[sortedByIndicator.length - 1]);  // best
        bwArray.push(sortedByIndicator[referenceIndex]);                // reference
        bwArray.push(sortedByIndicator[0]);                             // worst

        setBWRotations([...bwArray]);
      }
    }
  };

  let currentUnits = '';
  if (indicatorObj) {
    currentUnits = indicatorObj.units;
  }

  let currentResultIndicator = '';
  if (indicatorObj) {
    currentResultIndicator = indicatorObj.value;
  }

  let currentResultIndicatorDesc = '';
  if (indicatorObj) {
    currentResultIndicatorDesc = indicatorObj.display;
    if (indicatorObj.details !== '') {
      currentResultIndicatorDesc += ` (${indicatorObj.details})`;
    }
  }

  let BestWorstRotations = [];
  if (resultsData) {
    if (resultsData.hasOwnProperty('BestWorstList')) {
      BestWorstRotations = [...resultsData.BestWorstList];
    }
  }

  if (resultsStatus === 'loading') {
    return <LoadingComponent State='loading' />;
  }

  if (resultsStatus === 'error') {
    return <LoadingComponent Error={resultsError} State='error' />;
  }

  return (
    <div>
      <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
          <div>
            <ResultIndicatorModal
              CurrentIndicator={currentResultIndicator}
              CurrentIndicatorDesc={currentResultIndicatorDesc}
              SelectableIndicators={selectableIndicators}
              OnResultIndicatorChange={handleIndicatorChange} />
          </div>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {resultsData && <div id="rl-paraxis" className={styles.graphPadded}>
              <InfoButton InfoKey="rl_par_axis" Position='top' AnchorTo="rl-paraxis" >
                {/*<BWParallelChart*/}
                {/*  SummaryData={resultsData}*/}
                {/*  Indicators={parallelIndicators}*/}
                {/*  CurrentIndicator={currentResultIndicator}*/}
                {/*  Explanation=''*/}
                {/*  InfoKey=''*/}
                {/*/>*/}
                <BestWorstChart IndicatorObj={indicatorObj} Rotations={bwRotations} />
              </InfoButton>
            </div> }
          </Grid>

          {/*<Grid item xs={3}>*/}
          {/*  {resultsData && <div id="rl-plantings" className={styles.graphPadded}>*/}
          {/*    <InfoButton InfoKey="" Position='auto' AnchorTo="rl-plantings">*/}
          {/*      <PlantingsChart Plantings={plantings} Title="Planting Frequency"  />*/}
          {/*    </InfoButton>*/}
          {/*  </div> }*/}
          {/*</Grid>*/}

          {/*<Grid item xs={12}>*/}
          {/*  {resultsData && <div id="rl-table" className={styles.graphPadded}>*/}
          {/*    <div className={styles.plantingTitle}>Best & worst performing crop rotations</div>*/}
          {/*    <InfoButton InfoKey="rl_bw_table" Position="auto" AnchorTo="rl-table">*/}
          {/*      <SectionCRotationList Rotations={BestWorstRotations} Crops={crops}/>*/}
          {/*    </InfoButton>*/}
          {/*  </div> }*/}

          {/*  <Typography fontStyle="italic" >*/}
          {/*    Note: At the selected location, there may be various, different rotations that provide the best (or worst)*/}
          {/*    performance for a certain indicator. In those cases, only one of these best (or worst) performing crop*/}
          {/*    rotations is exemplarily listed here.*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
        </Grid>
      </Box>
    </div>
  );
}

export default ResultsRotationLevel;

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import axiosClient from '../data/axiosClient';
import configHelper from '../data/configHelper';
import styles from './Training.module.scss';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TrainingItem from "./TrainingItem";
import sharedStyles from "../containers/sharedPageStyles.module.scss";
import {Container, Box, Typography } from '@mui/material';

const Training = (props) => {
  const effectDone = useRef(false);
  const [listData, setListData] = useState([]);

  useEffect(() => {

    const today = getDate();

    axiosClient.get(`/training?date=${today}`, {})
      .then(function(response) {
        // if we've called the api already, let's cache the results on this class
        setListData([...response.data.training]);
      })
      .catch(error => {
        console.log(error);
      });

  }, []);

  const getDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${year}-${month}-${date}`;
  };

  return (

    <Container maxWidth="lg">
      <Box sx={{ width: '100%' }}>
        <div className={sharedStyles.descriptionArea}>
          <div className={sharedStyles.headerSection}>
            <Typography variant="h4">Education/Training Available</Typography>
            <div className={styles.overallContainer}>
              <List >
                {listData.map(item => (
                  <ListItem key={item.id} >
                    <TrainingItem Item={item} />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </div>
      </Box>
    </Container>
  );
}

export default Training;

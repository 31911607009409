import React,  { Component } from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';

import componentStyles from './ProbabilityOfExceedanceGraph.module.scss';

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

class ProbabilityOfExceedanceGraph extends Component {

    getChartData() {
        return this.props.scenarios.map((scenario, index) => {
            return {
                name: "#" + scenario['Index'],
                data: scenario.data[this.props.dataField].probability_exceedance_data.distribution,
                color: this.getColorOptions(index),
                marker: {
                    enabled: false
                },
            }
        });
    }

    getOverallMinAndMax() {
        var min = 0;
        var max = 0;
        this.props.scenarios.forEach((scenario) => {
            var scenarioMin = scenario.data[this.props.dataField].probability_exceedance_data.min;
            var scenarioMax = scenario.data[this.props.dataField].probability_exceedance_data.max;
            if (scenarioMin < min) {
                min = scenarioMin;
            }
            if (max < scenarioMax) {
                max = scenarioMax;
            }
        });
        // Ideally these will be decimals, and by performing a floor/ceil, we can get
        // the x-axis to the closest round number and allow for the full line on the graph.
        return [
            Math.floor(min),
            Math.ceil(max)
        ];
    }

    getColorOptions(index) {
        var colors = Highcharts.getOptions().colors; // this only gives us 10 colors, boo!
        if (index > 9) {
            var randomColor = "#" + Math.floor(Math.random()*16777215).toString(16);
            colors.push(randomColor);
        }
        var perShapeGradient = [ 0, 0, 0, 350 ]; // needed instead of object to see the line colours in legend... for some reason?
        const myStopColour = new Highcharts.Color(colors[index]);
        return {
            linearGradient : perShapeGradient,
            stops : [[0, colors[index]], [1, myStopColour.brighten(0.3).get('rgb')]]
        };
    }

    getUnits() {
        return this.props.scenarios[0].data[this.props.dataField].units;
    }

    getTitle() {
        var fullDescription = this.props.scenarios[0].data[this.props.dataField].fullDescription || this.props.dataField;
        var units = this.getUnits();
        if (fullDescription && units) {
            return [fullDescription, units].join(' - ');
        }
        return fullDescription;
    }

    getChartOptions() {
        return {
            chart : {
                zoomType : 'x',
            },
            credits: {
                enabled: false
            },
            title : {
                text : this.getTitle()
            },
            legend : {
                enabled : true
            },
            xAxis : {
                maxZoom : 200,
                enabled: true,
                min: this.getOverallMinAndMax()[0],
                max: this.getOverallMinAndMax()[1],
            },
            yAxis : {
                floor: 0,
                ceiling: 1,
                title : {
                    text : 'Percentile',
                }
            },
            tooltip : {
                headerFormat : '<span style="font-size:10px;font-weight:bold">{point.y:.2f} %</span><table>',
                pointFormat : '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.x:.1f} ' + this.getUnits() + '</b></td></tr>',
                footerFormat : '</table>',
                useHTML : true,
                valueDecimals : 2
            },

            series : this.getChartData()
        }
    }

    render() {
        const chartOptions = this.getChartOptions();
        return (
            <div className={componentStyles.graphContainer}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    constructorType={ 'chart' }
                />
            </div>
        )
    }
}

ProbabilityOfExceedanceGraph.propTypes = {
    scenarios: PropTypes.arrayOf(PropTypes.object),
    dataField: PropTypes.string,
};

export default ProbabilityOfExceedanceGraph;

import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import styles from "./RotationStepper.module.scss";
import {Typography} from "@mui/material";
import CropList from "../ra_components/CropList";
import RotationList from "../ra_components/RotationList";
import {
  clearSelectedCrops,
  selectSelectedCrops,
  selectBaseRotation,
  selectRotations,
  updateSelectedCrops,
  updateBaseRotation,
  selectCrops,
} from "../features/rotationSlice";

const RefRotationStep = () => {
  const dispatch = useDispatch();
  const selectedCrops = useSelector(state => selectSelectedCrops(state));
  const baseRotationId = useSelector(state => selectBaseRotation(state));
  const [cropFilter, setCropFilter] = useState('');

  const rotations = useSelector(state => selectRotations(state));
  // console.log('RefRotationStep rotations', rotations);

  const crops = useSelector(state => selectCrops(state));
  // console.log('RefRotationStep crops', crops);

  useEffect(() => {
    if (selectedCrops.length > 0) {
      const userCrops = selectedCrops.join(',');
      setCropFilter(userCrops);
    }
  }, []);

  const handleRotationSelect = (selectedRotations) => {
    // Always passed an array of rotationIds.
    // console.log('stepper rotation select', selectedRotations);
    if (selectedRotations.length > 0) {
      const rotationId = parseInt(selectedRotations[0]);
      dispatch(updateBaseRotation(rotationId));
    }
  };

  const handleCropSelect = (selected) => {

    // Always passed an array of crop names
    if (selected.length > 0) {
      const userCrops = selected.join(',');
      setCropFilter(userCrops);
      dispatch(updateSelectedCrops(selected));
    } else {
      setCropFilter('');
      dispatch(clearSelectedCrops());
    }
  };

  let selectedRotations = []
  if (baseRotationId !== null) {
    selectedRotations.push(baseRotationId.toString());
  }

  return (
    <div>
      <div>
        <Typography variant="h6" fontWeight="bold">
          Please select a reference crop rotation that you want to analyse. This may
          include a typical crop rotation you cultivate on your farm, a crop rotation
          that you plan to cultivate in the near future, or any other relevant crop
          rotation of your interest.
        </Typography>
        <div className={styles.shadedTitle}>
          <Typography variant="h6" fontWeight="bold">
            A. Select the crops included in your reference crop rotation:
          </Typography>
        </div>
        <div className={styles.croplistContainer}>
          <CropList
            Crops={crops}
            Columns={4}
            Title=""
            CropSelect={handleCropSelect}
            SelectedCrops={selectedCrops}
          />
        </div>
      </div>
      <div className={styles.selectRotations}>
        <div className={styles.shadedTitle}>
          <Typography variant="h6" fontWeight="bold">
            B. Select one reference crop rotation:
          </Typography>
        </div>
        <div className={styles.rotationlistContainer}>
          <RotationList
            Rotations={rotations}
            Crops={crops}
            Title=""
            OnSelect={handleRotationSelect}
            MaxSelectCount={1}
            CropFilter={cropFilter}
            Selected={selectedRotations}
          />
        </div>
      </div>
    </div>
  );
}

export default RefRotationStep;

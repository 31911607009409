import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const TextList = (props) => {
  const liStyle = {
    color: props.AsErrors ? 'red' : 'black',
  };

  const listItems = props.Items.map(item => <li style={{...liStyle}}>{item}</li>);

  return (
    <>
      {props.Items.length > 0 && (
        <div>
          {props.ListTitle !== '' && (<>{props.ListTitle}:</>)}
          <ul style={{listStyleType: 'none', margin: '3px'}}>{listItems}</ul>
        </div>
      )}
    </>
  );
}

TextList.propTypes = {
  Items: PropTypes.array.isRequired,
  ListTitle: PropTypes.string,
  AsErrors: PropTypes.bool,
};

TextList.defaultProps = {
  ListTitle: '',
  AsErrors: false,
}

export default TextList;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftOutlined from '@mui/icons-material/ArrowCircleLeftOutlined';

import ResultTable from '../../components/ResultTable';
import BoxPlotGraph from '../../components/BoxPlotGraph';
import BarGraph from '../../components/BarGraph';
import CumulativeDistributionGraph from '../../components/CumulativeDistributionGraph';
import ProbabilityOfExceedanceGraph from '../../components/ProbabilityOfExceedanceGraph';
import analysisDataHelper from '../../data/analysisDataHelper';
import configHelper from '../../data/configHelper';
import sharedStyles from '../sharedPageStyles.module.scss';

import {
  Box,
  MenuItem,
  Typography,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  createFilterOptions
} from '@mui/material';

class FAScenarioResults extends Component {
  constructor(props) {
    super(props);

    this.RESULT_DISPLAY_TYPES = [
      { value: 'table', label: 'Table'},
      { value: 'box_plot', label: 'Box Plot'},
      { value: 'bar_chart', label: 'Bar Chart'},
      { value: 'cumulative_distribution', label: 'Cumulative Distribution'},
      { value: 'probability_of_exceedance', label: 'Probability Of Exceedance'},
    ];

    this.FIELD_SELECTION_OPTIONS = [
      "surfaceOM",
      "PAW",
      "nh4",
      "no3",
      "inorganicN",
      "drain",
      "runoff",
      "no3Leach",
      "rain",
      "effRain",
      "WeedWaterUptake",
    ];

    this.state = {
      resultDisplayType: 'box_plot',
      scenarioFieldDisplayed: this.FIELD_SELECTION_OPTIONS[0],
      fieldList: analysisDataHelper.getOptionsList(this.FIELD_SELECTION_OPTIONS, "fallow"),
    };
  }

  getResultDisplay(displayType) {
    switch(displayType) {
      case 'table':
        return (
          <div>
            <ResultTable
              scenarios={this.props.results}
              dataField={this.state.scenarioFieldDisplayed}
            />
          </div>
        )
      case 'box_plot':
        return (
          <div>
            <BoxPlotGraph
              scenarios={this.props.results}
              dataField={this.state.scenarioFieldDisplayed}
            />
            <ResultTable
              scenarios={this.props.results}
              dataField={this.state.scenarioFieldDisplayed}
            />
          </div>
        )
      case 'bar_chart':
        return (
          <div>
            <BarGraph
              scenarios={this.props.results}
              dataField={this.state.scenarioFieldDisplayed}
              droughts={this.state.droughts}
            />
            <ResultTable
              scenarios={this.props.results}
              dataField={this.state.scenarioFieldDisplayed}
            />
          </div>
        )
      case 'cumulative_distribution':
        return (
          <div>
            <CumulativeDistributionGraph
              scenarios={this.props.results}
              dataField={this.state.scenarioFieldDisplayed}
            />
            <ResultTable
              scenarios={this.props.results}
              dataField={this.state.scenarioFieldDisplayed}
            />
          </div>
        )
      case 'probability_of_exceedance':
        return (
          <div>
            <ProbabilityOfExceedanceGraph
              scenarios={this.props.results}
              dataField={this.state.scenarioFieldDisplayed}
            />
            <ResultTable
              scenarios={this.props.results}
              dataField={this.state.scenarioFieldDisplayed}
            />
          </div>
        )
    }
  }

  getScenarioFieldSelector() {
    // by default we want all available fields returned
    return (
      <Select
        value={this.state.scenarioFieldDisplayed}
        label="Scenario Data Field"
        onChange={this.onScenarioFieldSelection}
      >
        {
          this.FIELD_SELECTION_OPTIONS.map((fieldSelectionOption) => {
            return (
              <MenuItem
                key={fieldSelectionOption}
                value={fieldSelectionOption}
              >
                {fieldSelectionOption}
              </MenuItem>
            )
          })
        }
      </Select>
    )
  }

  isModifierApplied(type) {
    if (this.props.modifiers.length > 0) {
      let modifier = this.props.modifiers.find(element => element.type === type);
      if (modifier) {
        return true;
      }
    }
    return false;
  }

  onBackSelection = (event) => {
    event.preventDefault();
    this.setState({
      selectedMonth: "",
      selectedPhases: [],
      scenarioFieldDisplayed: this.FIELD_SELECTION_OPTIONS[0],
    })
    this.props.clearScenarioResults();
  }

  onScenarioFieldSelection = (event, value) => {
    this.setState({
      scenarioFieldDisplayed: event.target.value
    });
  }

  onViewTypeSelection = (event, value) => {
    this.setState({
      resultDisplayType: event.target.value
    });
  }

  render() {
    return (
      <div className={sharedStyles.resultsArea}>
        <Box sx={{ display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", alignItems: "center" }}>
          <Box sx={{ flexGrow: 0 }}>
            <Typography variant="h4">
              <Tooltip title={"Returning to Scenario Creation step will clear all Analysis currently applied."}>
                <IconButton onClick={this.onBackSelection} aria-label="back">
                  <ArrowCircleLeftOutlined />
                </IconButton>
              </Tooltip>
              Scenario Results
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormGroup row={true} sx={{flexDirection: "row-reverse"}}>
              {/* Scenario Data Field */}
              <FormControl sx={{ margin: "0 1em", width: "40%" }}>
                <InputLabel>Scenario Field</InputLabel>
                {this.getScenarioFieldSelector()}
              </FormControl>

              {/* View Type */}
              <FormControl sx={{ margin: "0 1em", width: "40%" }}>
                <InputLabel>View Type</InputLabel>
                <Select
                  value={this.state.resultDisplayType || null}
                  label="View Type"
                  onChange={this.onViewTypeSelection}
                >
                  {
                    this.RESULT_DISPLAY_TYPES.map((resultDisplayType) => {
                      return (
                        <MenuItem
                          key={resultDisplayType.value}
                          value={resultDisplayType.value}
                        >
                          {resultDisplayType.label}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </FormGroup>
          </Box>
        </Box>
        {(this.props.showResults && this.props.results.length > 0) &&
          this.getResultDisplay(this.state.resultDisplayType)}
      </div>
    )
  }
}

FAScenarioResults.propTypes = {
  originalScenarios: PropTypes.arrayOf(PropTypes.object),
  results: PropTypes.arrayOf(PropTypes.object),
  modifiers: PropTypes.arrayOf(PropTypes.object),
  clearScenarioResults: PropTypes.func,
  showResults: PropTypes.bool,
};

export default FAScenarioResults;

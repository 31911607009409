import axiosRotation from "../ra_data/axiosRotation";

class dataHelper {
  constructor() {
    this.data = {};
  }

  cropsFromAPI() {
    // we don't want to call the API every time this function is called
    // if we have cached data, let's return that in a promise
    // and pretend it's an API call that's been successful.
    if (this.data.hasOwnProperty('data')) {
      return new Promise((resolve, reject) => {
        resolve(this.data);
      });
    };
    var self = this;
    return axiosRotation.get('/crops', {})
      .then(function(response) {
        // if we've called the api already, let's cache the results on this class
        self.data = response;
        return response;
      });
  }

  // It is not ideal to have this information hardcoded and this information is contained in
  // the Crops table in the RotationArm database table. This is provided here as a quick
  // reference that doesn't require any additional API calls.
  cropsList() {
    const list = [
      {id: 0, name: 'fallow',   bg_colour: '#D4D4D4', fg_colour: '#000000', fallow: true,  season: '',       abbrev: 'FA', class: 'none'},
      {id: 1,	name: 'sorghum',	bg_colour: '#AF8708', fg_colour: '#FFFFFF', fallow: false, season: 'summer', abbrev: 'SG', class: 'cereal'},
      {id: 2,	name: 'maize',	  bg_colour: '#F8D65C', fg_colour: '#000000', fallow: false, season: 'summer', abbrev: 'MZ', class:	'cereal'},
      {id: 3,	name: 'wheat',	  bg_colour: '#FCF1C5', fg_colour: '#000000', fallow: false, season: 'winter', abbrev: 'WH', class:	'cereal'},
      {id: 4,	name: 'mungbean',	bg_colour: '#B25618', fg_colour: '#FFFFFF', fallow: false, season: 'summer', abbrev: 'MB', class:	'pulse'},
      {id: 5,	name: 'chickpea',	bg_colour: '#98B8E2', fg_colour: '#000000', fallow: false, season: 'winter', abbrev: 'CP', class:	'pulse'},
      {id: 6,	name: 'canola',	  bg_colour: '#F0C6A4', fg_colour: '#000000', fallow: false, season: 'winter', abbrev: 'CN', class:	'oilseed'},
    ];

    return list;
  }

  getCrop(cropName) {
    const crops = this.cropsList();
    return crops.find(c => c.name === cropName.toLowerCase().trim());
  }
}

const cropHelper = new dataHelper();

export default cropHelper;

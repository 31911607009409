import axios from 'axios';

const axiosClient = axios.create({
    // Looking to replace with an environment variable: REACT_APP_ARMAPIURL

    // Production
    baseURL: 'https://armonline.net.au/croparm',

    // Development
    // baseURL: 'http://localhost:3001/',

    timeout: 30000,
    headers: {}
});

export default axiosClient;

import React, { Component } from 'react';

import styles from './NameHeader.module.scss';

import appIcon from '../images/FieldIcon.png';
import Container from '@mui/material/Container';
import { Typography, Link, Box } from '@mui/material';

import LogoSection from './LogoSection';

class NameHeader extends Component {
    render() {
        return (
            <Container maxWidth="lg">
                <div className={styles.overallContainer}>
                    <div className={styles.appLogo}>
                        <img className={styles.appLogo}
                             src={appIcon}
                             alt='ARMOnline'
                        />
                    </div>
                    <div className={styles.nameSection}>
                        <Typography align='left' variant="h2" className={styles.appTitle}>
                            ARM Online
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom className={styles.appSubtitle}>
                            Agricultural Risk Management Tools
                        </Typography>
                    </div>
                    <div className={styles.nameSpacer}></div>
                    <div className={styles.logoSection}>
                        <LogoSection/>
                    </div>
                </div>
            </Container>
        )
    }
}

export default NameHeader;

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

import {selectCropCommodity} from "../features/croparmSlice";
import { Button, Modal, Typography, Box, Tabs, Tab, InputLabel, FormControl, Divider, FormGroup, Input, InputAdornment, Tooltip } from '@mui/material';

import configHelper from '../data/configHelper';
import componentStyle from './GrossMarginContainer.module.scss';

const UreaConversionRate = 2.1739;

const GROSS_MARGIN_FIELDS = configHelper.GROSS_MARGIN_FIELDS;
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const GrossMarginContainer2 = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState(0);
  const [scenarioData, setScenarioData] = useState({});

  const commodity = useSelector(selectCropCommodity);

  useEffect(() => {
    initialiseScenarioData();
  }, [props.Crop, props.scenarios]);

  const initialiseScenarioData = (forceReset = false) => {
    if (props.scenarios) {

      const otherCosts = Number(commodity.other_costs) + Number(commodity.fallow_costs);
      const defaultData = {
        SeedCosts: commodity.seed_costs,
        NFertiliserCost: commodity.n_costs,
        IrrigationCosts: commodity.irrigation_costs,
        OtherVariableCosts: otherCosts.toFixed(2),
        ExpectedPriceReceived: commodity.price,
      };

      const newScenarioData = {};
      props.scenarios.forEach((scenario, i) => {
        const scenarioData = {};
        GROSS_MARGIN_FIELDS.forEach(field => {
          if (scenario.hasOwnProperty(field) && !forceReset) {
            let multiplier = 1;
            if (field === 'NFertiliserCost') {
              multiplier = UreaConversionRate;
            }

            const originalValue = Math.round((scenario[field] / multiplier) * 100) / 100;
            scenarioData[field] = originalValue;
          } else {
            scenarioData[field] = defaultData[field];
          }
        })

        newScenarioData[i] = {...scenarioData};
      });

      setScenarioData(newScenarioData);
    }
  };

  const onTabChange = (event, value) => {
    setValue(value);
  };

  const getAnalysisStatus= () => {
    return props.modifiers.some((modifier) => modifier.type === 'grossMargin');
  };

  const getButtonVariant = () => {
    return getAnalysisStatus() ? "contained" : "outlined";
  };

  const getTooltipContent = () => {
    if (props.disabled) {
      return props.disabledMessage;
    }
    return '';
  };

  const buttonIsDisabled = () => {
    let disabled = true;

    if (getScenarioValidCount() === props.scenarios.length) {
      disabled = false;
    }

    return disabled;
  };

  const getScenarioDescription = () => {
    if (props.scenarios.length > 0) {
      let scenario = props.scenarios[value];
      let description = "";

      Object.keys(scenario).forEach((field) => {
        let value = scenario[field];
        if (typeof(value) !== 'object') {
          description += field + ": " + value + "; ";
        }
      });

      return description;
    }
    return "";
  };

  const getFormField = (scenarioIndex, field) => {
    if (scenarioData.hasOwnProperty(scenarioIndex)) {
      if (scenarioData[scenarioIndex].hasOwnProperty(field)) {
        return scenarioData[scenarioIndex][field];
      }
    }
    if (props.scenarios[scenarioIndex].hasOwnProperty(field)) {
      return props.scenarios[scenarioIndex][field];
    }
    return null;
  };

  const isValidScenarioData = (obj) => {
    // for each gross margin field
    let validScenario = GROSS_MARGIN_FIELDS.every((field) => {
      // does our state have this field set for the scenario?
      if (obj.hasOwnProperty(field)) {
        // access the value for this field on this scenario
        let value = obj[field];
        // if it's not null and empty, we'll accept the value as valid
        return (value !== null && value !== '');
      }
      // the field isn't set, we should return false
      return false;
    });

    return validScenario;
  };

  const getScenarioValidCount = () => {
    let validCount = 0;

    // for each scenario that was provided to this component
    props.scenarios.forEach((scenario, index) => {
      // if we don't have any data for it, we don't need to check if it's valid - we know it's not
      if (scenarioData.hasOwnProperty(index)) {

        // access the data in state for this scenario
        const scenarioDataObj = {...scenarioData[index]};
        if (isValidScenarioData(scenarioDataObj)) {
          validCount += 1;
        }
      }
    });

    return validCount;
  };

  const handleGrossMarginAnalysisApply = (event) => {
    event.preventDefault();
    let scenarios = JSON.parse(JSON.stringify(props.scenarios));

    scenarios.forEach((scenario, index) => {
      let configuredData = scenarioData[index];
      GROSS_MARGIN_FIELDS.forEach((field) => {
        let multiplier = 1;
        if (field === 'NFertiliserCost') {
          multiplier = UreaConversionRate;
        }

        const fValue = parseFloat(configuredData[field]);
        const value = Math.round((multiplier * fValue) * 100) / 100;

        //scenario[field] = parseFloat(configuredData[field]);
        scenario[field] = value;
      });
    });

    setShowModal(false);

    props.applyGrossMarginAnalysis(scenarios);
  };

  const handleGrossMarginAnalysisClear = (event) => {
    event.preventDefault();
    // let scenarioData = JSON.parse(JSON.stringify(scenarioData));
    // // we essentially want to clear all the properties within all the scenarios
    // for (var index in scenarioData) {
    //   let scenario = scenarioData[index];
    //   for (var key in scenario) {
    //     // can't set this to null (like elsewhere in this file)
    //     // because the form input won't clear if we set to null
    //     scenario[key] = '';
    //   }
    // }
    // setScenarioData(scenarioData);
    initialiseScenarioData(true);
  };

  const handleGrossMarginAnalysisRemove = (event) => {
    event.preventDefault();
    setShowModal(false);

    props.removeGrossMarginAnalysis();
  }

  const updateFormField = (scenarioIndex, field, value) => {
    let xScenario = {};
    if (scenarioData.hasOwnProperty(scenarioIndex)) {
      xScenario = {...scenarioData[scenarioIndex]};
    }
    xScenario[field] = value;

    const newScenarioData = {}
    props.scenarios.forEach((s, index) => {
      if (index === scenarioIndex) {
        newScenarioData[index] = {...xScenario};
      } else {
        newScenarioData[index] = {...scenarioData[index]};
      }
    });

    setScenarioData(newScenarioData);
  };

  const handleCopyToAllScenarios = (masterScenarioIndex) => {
    const currentScenarioData = {...scenarioData[masterScenarioIndex]};

    let newScenarioData = {};
    props.scenarios.forEach((scenario, index) => {
      newScenarioData[index] = {...currentScenarioData};
    });

    setScenarioData(newScenarioData);
  };

  return (
    <div className={componentStyle.container}>
      <Tooltip title={getTooltipContent()} arrow>
        {/* <span> is required in the case that the button is disabled. */}
        <span>
          <Button
            sx={{m: 1, p: .5,  width: 180}}
            variant={getButtonVariant()}
            disabled={props.disabled}
            onClick={() => {setShowModal(true)}}
          >
              Gross Margin
          </Button>
        </span>
      </Tooltip>

      <Modal
        open={showModal}
        onClose={() => {setShowModal(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Gross Margin Analysis
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Enter a value for each option across a scenario. Selecting "Copy values to all scenarios"
            will apply currently entered values across all scenarios.
          </Typography>
          <Divider sx={{margin: "1em 0"}} />
          <Box sx={{ marginTop: '1em', display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", alignItems: "stretch" }}>
            <Box sx={{ flexGrow: 0 }}>
              <Tabs
                orientation="vertical"
                value={value}
                variant="scrollable"
                onChange={onTabChange}
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                {props.scenarios.map((scenario, index) => {
                  let indexString = (index + 1).toString();
                  let label = "Scenario " + indexString;
                  return (
                    <Tab
                      label={label}
                      id={`vertical-tab-${index}`}
                      key={`vtab_key_${index}`}
                      aria-controls={`vertical-tabpanel-${index}`} />
                  )
                })}
              </Tabs>
            </Box>
            <Box sx={{ flexGrow: 0, flex: 1 }}>
              {props.scenarios.map((scenario, index) => {
                return (
                  <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`vertical-tabpanel-${index}`}
                    aria-labelledby={`vertical-tab-${index}`}
                    key={`tab_scenario_${index}`}
                  >
                    {value === index && (
                      <Box sx={{ flexGrow: 0, flex: 1 }}>
                        <Typography sx={{ m: 2, textAlign: "center" }}>
                          {getScenarioDescription()}
                        </Typography>
                        <FormGroup>
                          <FormControl sx={{ m: 2 }} variant="standard">
                            <InputLabel id={`i${index}_L_Seed`}>Seed costs</InputLabel>
                            <Input
                              id={`i${index}_I_Seed`}
                              type="number"
                              value={getFormField(index, 'SeedCosts')}
                              onChange={(event) => {updateFormField(index, 'SeedCosts', event.target.value);}}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              endAdornment={<InputAdornment position="end">/kg seed</InputAdornment>}
                            ></Input>
                          </FormControl>
                          <FormControl sx={{ m: 2 }} variant="standard">
                            <InputLabel id={`i${index}_L_Fert`}>Urea fertiliser cost</InputLabel>
                            <Input
                              id={`i${index}_I_Fert`}
                              type="number"
                              value={getFormField(index, 'NFertiliserCost')}
                              onChange={(event) => {updateFormField(index, 'NFertiliserCost', event.target.value);}}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              endAdornment={<InputAdornment position="end">/t Urea applied</InputAdornment>}
                            ></Input>
                          </FormControl>
                          <FormControl sx={{ m: 2 }} variant="standard">
                            <InputLabel id={`i${index}_L_Irr`}>Irrigation costs</InputLabel>
                            <Input
                              id={`i${index}_I_Irr`}
                              type="number"
                              value={getFormField(index, 'IrrigationCosts')}
                              onChange={(event) => {updateFormField(index, 'IrrigationCosts', event.target.value);}}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              endAdornment={<InputAdornment position="end">/ML water</InputAdornment>}
                            ></Input>
                          </FormControl>
                          <FormControl sx={{ m: 2 }} variant="standard">
                            <InputLabel id={`i${index}_L_Other`}>Other variable costs</InputLabel>
                            <Input
                              id={`i${index}_I_Other`}
                              type="number"
                              value={getFormField(index, 'OtherVariableCosts')}
                              onChange={(event) => {updateFormField(index, 'OtherVariableCosts', event.target.value);}}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              endAdornment={<InputAdornment position="end">/ha</InputAdornment>}
                            />
                          </FormControl>
                          <FormControl sx={{ m: 2 }} variant="standard">
                            <InputLabel id={`i${index}_L_Price`}>Expected price received</InputLabel>
                            <Input
                              id={`i${index}_I_Price`}
                              type="number"
                              value={getFormField(index, 'ExpectedPriceReceived')}
                              onChange={(event) => {updateFormField(index, 'ExpectedPriceReceived', event.target.value);}}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              endAdornment={<InputAdornment position="end">/t</InputAdornment>}
                            />
                          </FormControl>
                          <Box sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Button
                              variant={"outlined"}
                              onClick={(event) => {handleCopyToAllScenarios(index);}}
                            >
                              Copy values to all scenarios
                            </Button>
                          </Box>
                        </FormGroup>
                      </Box>
                    )}
                  </div>
                )
              })}
            </Box>
          </Box>
          <Divider sx={{margin: "1em 0"}} />
          <Box sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <Button
              variant={"contained"}
              sx={{marginRight: "1em"}}
              onClick={handleGrossMarginAnalysisRemove}
              disabled={!getAnalysisStatus()}
              color="error"
            >
              Disable Analysis
            </Button>
            <Typography sx={{flex: 2}} variant="subtitle1">
              {getScenarioValidCount()} scenarios with valid information.
            </Typography>
            <Button
              variant={"outlined"}
              sx={{marginRight: "1em"}}
              onClick={handleGrossMarginAnalysisClear}
            >
              Reset to default
            </Button>
            <Button
              variant={"contained"}
              disabled={buttonIsDisabled()}
              onClick={handleGrossMarginAnalysisApply}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )

}

GrossMarginContainer2.propTypes = {
  Crop: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  scenarios: PropTypes.arrayOf(PropTypes.object),
  modifiers: PropTypes.arrayOf(PropTypes.object),
  applyGrossMarginAnalysis: PropTypes.func,
  removeGrossMarginAnalysis: PropTypes.func,
}

export default GrossMarginContainer2;

import React, { Component } from 'react';

import sharedStyles from './sharedPageStyles.module.scss';
import pageStyles from './DisclaimerPage.module.scss';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

class DisclaimerPage extends Component {
    render() {
        return (
            <Container maxWidth="lg">
                <Box sx={{ width: '100%' }}>
                    <div className={sharedStyles.descriptionArea}>
                        <div className={sharedStyles.headerSection}>
                            <Typography variant="h4">
                                Disclaimer
                            </Typography>
                        </div>
                        <Typography variant="body1" paragraph>
                            The State of Queensland, acting through its Department of Agriculture and Fisheries (DAF), on behalf of itself and owners of intellectual property of these materials presented online, makes no representations about the content and suitability of such materials for any purpose. Specifically, DAF and all Intellectual property owners of such materials, jointly and severally do not warrant, guarantee or make any representations regarding the correctness, accuracy, reliability, currency, or any other aspect regarding characteristics or use of the information presented in such materials.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            The information contained herein is subject to change without notice. DAF, on behalf of itself and owners of intellectual property of these materials presented online, and the University of Southern Queensland, shall not be liable for technical or other errors or omissions contained herein. The reader/user accepts sole responsibility and all risks and responsibility for losses, damages, costs and other consequences resulting directly or indirectly from using this information, irrespective of the purpose to which such use or results are applied.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Users should confirm information from an appropriate site or other appropriate source if it is of sufficient importance for them to do so. In no event shall DAF nor any owner of intellectual property in such materials be liable for any special, indirect or consequential damages or any damages whatsoever resulting from loss of use, data or profits, whether in an action of contract, negligence or tort, rising out of or in connection with the use or performance of such materials.
                        </Typography>
                        <Typography variant="h5" paragraph>
                            Third Party Sites
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You should be aware that third party sites are not under the control of DAF. Therefore DAF can make no representation concerning the content of these sites to you, nor can the fact that DAF has provided a link to these sites serve as an endorsement by DAF of the materials contained on any of them. DAF provides these references only as a convenience to you. DAF does not warrant, guarantee or make any representations regarding the correctness, accuracy, reliability, currency, or any other aspect regarding characteristics or use of the information presented on these sites.
                        </Typography>
                        <Typography variant="body1" paragraph>
                        Where software is offered by a third party site, you are advised that DAF may not have tested this software and therefore cannot make any representations regarding the quality, safety or suitability of it. There are inherent dangers in the use of any software found on the Internet, and DAF cautions you to make sure that you completely understand the risk before retrieving any software on the Internet.
                        </Typography>
                    </div>
                </Box>
            </Container>
        )
    }
}

export default DisclaimerPage;
import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Grid, Tooltip} from "@mui/material";
import {List, ListItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import {anycaseEquals, capitalise} from "../functions";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {selectBaseRotation, selectSectionCIndicators} from "../features/rotationSlice";
import ResultIndicatorModal from "../ra_components/ResultIndicatorModal";
import LoadingComponent from "../ra_components/LoadingComponent";
import {
  selectPerformanceIndicators,
  selectExploreData,
  selectExploreStatus,
  selectExploreError,
  updateExploreRotations,
  selectExploreRotations, fetchExploreData
} from "../features/resultsSlice";
import pageStyles from './ExploreStep.module.scss';
import ExploreRotationList from "../ra_components/ExploreRotationList";

//region Defined styles Accordion components
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={4} square={false} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  borderRadius: '10px',
  marginTop: '2px',
  backgroundColor: 'lightgray',
  color: 'black',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.4rem', color: '#fefefe'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',   Commenting this out puts icons on the right
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  fontWeight: 'bold',
  color: '#fefefe',
  marginLeft: '10px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, .7)',
  padding: theme.spacing(0),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
//endregion


const ExploreStep = (props) => {
  const effectDone = useRef(false);
  const dispatch = useDispatch();
  const [expandedAry, setExpandedAry] = useState(['explore1']);
  const [referenceRotation, setReferenceRotation] = useState(null);
  const [otherRotations, setOtherRotations] = useState([]);
  const [exploreListKey, setExploreListKey] = useState('');
  const [userIndicators, setUserIndicators] = useState([]);
  const allIndicators = useSelector(selectSectionCIndicators);
  const exploreData = useSelector(selectExploreData);
  const exploreStatus = useSelector(selectExploreStatus);
  const exploreError = useSelector(selectExploreError);
  const exploreRotations = useSelector(selectExploreRotations);
  const baseRotationId = useSelector(selectBaseRotation);

  useEffect(() => {
    if (exploreStatus === 'idle') {
      dispatch(fetchExploreData());
    }
    if (exploreStatus === 'success') {
      console.log('ExploreStep - Explore Data', exploreData);
      // if (exploreData) {
      //   const refRotation = exploreData.rotationAverages.find(r => r.BaseRotationId === baseRotationId);
      //   if (refRotation) {
      //     setReferenceRotation({...refRotation, XSort: 0});
      //   }
      //   const others = exploreData.rotationAverages.filter(r => r.BaseRotationId !== baseRotationId);
      //   const otherRotations = others.map(o => {return {...o, XSort: 1}});
      //   setOtherRotations([...otherRotations]);
      // }
    }
  }, [exploreStatus, exploreData]);

  const selectableIndicators = useMemo(() => {
    return allIndicators.filter(r => r.selectable);
  }, [allIndicators]);

  if (exploreStatus === 'loading') {
    return <LoadingComponent State="loading" />
  }

  if (exploreStatus === 'error') {
    return <LoadingComponent State="error" Error={exploreError} />
  }

  const handleRotationSelect = (selectedRotations) => {
    dispatch(updateExploreRotations(selectedRotations));
  };

  const handleIndicatorSelect = (index, newIndicator) => {
    const tempIndicators = [...userIndicators];
    const existingIndex = userIndicators.indexOf(newIndicator);
    if (existingIndex !== -1) {
      tempIndicators[index] = newIndicator;
    } else {
      tempIndicators[existingIndex] = '';
      tempIndicators[index] = newIndicator;
    }

    // Remove gaps.
    const final = tempIndicators.filter(i => i !== '');
    while (final.length < 3) {
      final.push('');
    }

    setUserIndicators([...final]);

    // Generate new id to force a rerender of the ExploreRotationList
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const dummyId = 'ts' + timestamp.toString();
    setExploreListKey(dummyId);
  };

  const inExpanded = (panelName) => {
    if (expandedAry.indexOf(panelName) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const toggleExpanded = (panelName) => {
    let newExpanded;
    if (expandedAry.indexOf(panelName) !== -1) {
      // In the array so remove it.
      newExpanded = expandedAry.filter(x => x !== panelName);
    } else {
      newExpanded = [...expandedAry, panelName];
    }

    setExpandedAry(newExpanded);
  };

  return (
    <div style={{padding: 2}}>
      <Accordion
        expanded={inExpanded('explore1')}
        onChange={() => toggleExpanded('explore1')}>
        <AccordionSummary aria-controls="explore1-content" id="explore1-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20}
          >
            Explore alternative crop rotation options
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <div className={pageStyles.exploreContainer}>
            <Typography>
              In this section, you can explore which alternative crop rotations may potentially be of interest
              to your farm. To decide which alternative crop rotations are most relevant to you own
              circumstances, you can ask yourself the following guidance questions:
            </Typography>
            <div style={{paddingLeft: '30px'}}>
              <List>
                <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
                  <Typography fontStyle="italic">
                    Would it be of value to increase my cropping intensity? Does my current rotation contain
                    fallow periods with sufficient rainfall to accommodate another crop?
                  </Typography>
                </ListItem>
                <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
                  <Typography fontStyle="italic">
                    Would it be of value to decrease my cropping intensity? Does my current rotation contain
                    too many crops that prevent the sufficient accumulation of water in the soil profile
                    during fallow periods and cause subsequent crops to fail?
                  </Typography>
                </ListItem>
                <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
                  <Typography fontStyle="italic">
                    Would it be of value to shift to other crop types? Does my current rotation contain
                    too many crops that provide low gross margins? Does my current rotation too exclusively
                    rely on cereals and would benefit from the (further) inclusion of legumes?
                  </Typography>
                </ListItem>
                <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
                  <Typography fontStyle="italic">
                    Would it be of value to reduce my downside risk? Does it cause problems for my farm enterprise
                    that my current rotation leads to extreme reductions of my gross margin in bad years?
                  </Typography>
                </ListItem>
                <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
                  <Typography fontStyle="italic">
                    Would it be of value to cultivate a rotation that provides a higher average gross margin
                    across multiple years - while I am willing to accept high fluctuation between years
                    (i.e. extreme reductions to my gross margin in bad years)?
                  </Typography>
                </ListItem>
                <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
                  <Typography fontStyle="italic">
                    Would it be of value to shift to a crop rotation with a higher water and/or nitrogen-use efficiency?
                  </Typography>
                </ListItem>
                <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
                  <Typography fontStyle="italic">
                    Would it be of value to shift to a crop rotation that uses more of the available rainfall in the
                    form of crop water uptake and/or more of the available nitrogen in terms of crop nitrogen uptake?
                  </Typography>
                </ListItem>
                <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
                  <Typography fontStyle="italic">
                    Would it be of value to shift to a crop rotation that provides better performance regarding
                    one or several environmental indicators (e.g. surface organic matter, soil carbon,
                    soil nitrogen)?
                  </Typography>
                </ListItem>
              </List>
            </div>
            <Typography sx={{marginTop: "10px"}} >
              Based on these and further own considerations, you can explore below which crop rotations
              perform best on your prioritised performance indicators.
            </Typography>

            <Typography className={pageStyles.stepHeading} sx={{marginTop: "10px"}} >
              Step 1: Display crop rotations that outperform the reference crop rotation on one or
              several indicators.
            </Typography>

            <div className={pageStyles.columns5050}>
              <div className={pageStyles.columnHalf1}>
                <div className={pageStyles.columnHalfFlex}>
                  <ResultIndicatorModal
                    CurrentIndicator={props.PerformanceIndicators[0]}
                    SelectableIndicators={selectableIndicators}
                    Index={0}
                    LabelBefore={true}
                    Label="Performance Indicator 1"
                    WithBorder={false}
                    CanClear={true}
                    OnResultIndicatorChange={handleIndicatorSelect}
                  />
                  <ResultIndicatorModal
                    CurrentIndicator={props.PerformanceIndicators[1]}
                    SelectableIndicators={selectableIndicators}
                    Index={1}
                    LabelBefore={true}
                    Label="Performance Indicator 2"
                    WithBorder={false}
                    CanClear={true}
                    OnResultIndicatorChange={handleIndicatorSelect}
                  />
                  <ResultIndicatorModal
                    CurrentIndicator={props.PerformanceIndicators[2]}
                    SelectableIndicators={selectableIndicators}
                    Index={2}
                    LabelBefore={true}
                    Label="Performance Indicator 3"
                    WithBorder={false}
                    CanClear={true}
                    OnResultIndicatorChange={handleIndicatorSelect}
                  />
                </div>
              </div>
            </div>
            <div style={{marginTop: '10px'}}>
              <Typography sx={{textDecoration: 'underline'}} display="inline" >
                Step 2: Inspect alternative crop rotations
              </Typography>
            </div>

            <Typography sx={{marginTop: "10px"}} >
              The below list identifies all crop rotations that outperform the reference
              crop rotation on the selected performance indicators. (Note: For each rotation, the
              displayed performance indicators were averaged across all simulated sowing rules and
              nitrogen application rates)
            </Typography>

            <Typography className={pageStyles.stepHeading} sx={{marginTop: "10px"}} >
              Step 3: Select alternative crop rotations.
            </Typography>
            <Typography sx={{marginTop: "10px"}} >
              Please select one or two alternative crop rotations that will subsequently be
              compared in detail to the reference crop rotation.
            </Typography>
            {referenceRotation && <ExploreRotationList
              ReferenceRotation={referenceRotation}
              Rotations={otherRotations}
              SelectedIndicators={userIndicators}
              SelectedRotations={exploreRotations}
              MaxSelectCount={2}
              Selected={exploreRotations}
              OnSelect={handleRotationSelect}
              key={exploreListKey}
            />}
          </div>
        </AccordionDetails>
      </Accordion>

    </div>
  );
}

ExploreStep.propTypes = {
  PerformanceIndicators: PropTypes.array.isRequired,
}

export default ExploreStep;

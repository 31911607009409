import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, FormGroup} from '@mui/material';

import CheckboxGroup2 from '../../components/CheckboxGroup2';
import ScenarioList2 from "../../components/SectionList2";

import FASiteOptionsDataHelper, {
  allNumeric,
  allDates,
  sortedLikeDates
} from '../../data/FallowARM/FASiteOptionsDataHelper';

import configHelper from '../../data/configHelper';
import FAScenarioOptionsDataHelper from "../../data/FallowARM/FAScenarioOptionsDataHelper";

import pageStyles from "../ScenarioSelectionStep.module.scss";
import sharedStyles from "../sharedPageStyles.module.scss";
import LoadingComponent from '../../components/LoadingComponent';

class FAScenarioSelectionStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scenarioOptions: {},
      siteScenarioSelections: {},
      loadingState: 'loading',
      error: {},
    };

    this.props.sites.forEach((site) => {
      this.state.siteScenarioSelections[site] = {};
    });
  }

  onScenarioOptionSelection = (event, value) => {
    // process update event
    let updateIndex = JSON.parse(event.target.parentElement.dataset.updateIndex);
    let site = updateIndex.site;
    let simField = updateIndex.simField;
    let updateValue = isNaN(event.target.value) ? event.target.value : Number(event.target.value);
    let update = value; // has this been checked or not?

    this.updateSelection(site, simField, updateValue, update);
  }

  loadScenarioOptions = (site) => {

    // Need these before we drop into the FAScenarioOptionsDataHelper.scenarioOptionsFromAPI(site) promise.
    const selectUpdateFunc = this.updateSelection;
    const autoSelect = this.props.autoSelectSingleEntry;

    // this is where we would load scenario options in for a site provided.
    // we'd also want to make sure we set a "loading" flag in state here in case we wanted to show a loading element.
    let self = this;
    FAScenarioOptionsDataHelper.scenarioOptionsFromAPI(site)
      .then(function(response) {
        self.state.scenarioOptions[site] = response.data.options;

        if (self.props.soilSettings[site].pawc) {
          // we don't want PAWC as an available factor if we already have it specified for a site
          const excludeField = (fieldKey) => {
            const excludeFields = ['pawc', 'soil', 'soilname', 'soil_type']
            const excludeIndex = excludeFields.indexOf(fieldKey.toLowerCase());
            return excludeIndex === -1 ? false : true;
          };

          self.state.scenarioOptions[site] = self.state.scenarioOptions[site].filter(factor => !excludeField(factor.key))
        }
        let changedScenarioOptions = self.state.scenarioOptions;
        self.setState({
          scenarioOptions: changedScenarioOptions,
          loadingState: 'success',
        });

        // Pre-select options with only one value.
        if (autoSelect) {
          for (const key of Object.keys(changedScenarioOptions)) {

            changedScenarioOptions[key].forEach((option) => {
              if (option.values.length === 1) {
                let updateValue = isNaN(option.values[0]) ? option.values[0] : Number(option.values[0]);

                selectUpdateFunc(key, option.key, option.values[0], true);
              }
            });
          }
        }
      })
      .catch(function(error) {
        self.setState({
          loadingState: 'error',
          error: error,
        });
      })
  };

  updateSelection = (site, simField, updateValue, update) => {
    // if this option has not been checked before, we need to ensure that an array is available
    // to insert the relevant value into.
    let siteScenarioSelections = this.state.siteScenarioSelections;
    if (!siteScenarioSelections[site][simField]) {
      siteScenarioSelections[site][simField] = [];
    }

    if (update) {
      siteScenarioSelections[site][simField].push(updateValue);
    } else {
      // we need to remove from the array
      // we can't clear the array, as there may be multiple options selected for this simField
      let index = siteScenarioSelections[site][simField].indexOf(updateValue);
      siteScenarioSelections[site][simField].splice(index, 1);
    }

    this.setState({siteScenarioSelections});
  };

  isScenarioOptionSelected = (site, simField, option) => {
    let selectedOptions = this.state.siteScenarioSelections[site][simField];
    if (selectedOptions === undefined) {
      return false;
    } else if (selectedOptions.indexOf(option) !== -1) {
      return true;
    }
    return false;
  }

  isValidScenarioPresent = (site) => {
    let availableSimFactors = this.state.scenarioOptions[site];
    let selectedSimFactors = this.state.siteScenarioSelections[site];

    // has the user at least selected the name number of scenario options that are available?
    if (availableSimFactors.length === Object.keys(selectedSimFactors).length) {
      // if so, do all of them have a value selected?
      // this can happen if they select and then de-select a value
      let valueCheck = (simFactor) => selectedSimFactors[simFactor].length === 0;
      return !Object.keys(selectedSimFactors).some(valueCheck);
    }
    return false;
  }

  isScenarioLimitReached = (site) => {
    return this.calculateNumberOfScenariosToGenerate(site) + this.props.scenarios.length > configHelper.MAX_SCENARIOS;
  }

  calculateNumberOfScenariosToGenerate = (site) => {
    let selectedSimFactors = this.state.siteScenarioSelections[site];
    let numberOfScenarios = 0;
    Object.keys(selectedSimFactors).forEach((factor) => {
      let scenarioModifier = selectedSimFactors[factor].length;
      if (numberOfScenarios === 0) {
        numberOfScenarios += scenarioModifier;
      } else {
        // the number of scenarios grows exponentially
        // based on the number of scenario options chosen
        numberOfScenarios = numberOfScenarios * scenarioModifier;
      }
    });
    return numberOfScenarios;
  }

  onScenarioAdd = (site) => {
    // bubble up this event to ScenarioBuilder
    // all scenarios will be calculated and added at that level
    let selectedSimFactors = this.state.siteScenarioSelections[site];
    this.props.handleScenarioAddition(site, selectedSimFactors);
  }

  parseSoilTypeLabel = (soil, site) => {
    // This is also defined within SoilTypeSelectionStep.js
    // This could be split out into it's own unique component.
    let soilNameSplit = soil.split('_PAWC')[0].split('_');
    let soilName = soilNameSplit.map( val => val.charAt(0).toUpperCase() + val.substr(1)).join(" ");
    let pawc_value;
    if (soil.split('_PAWC').length > 1) {
      pawc_value = soil.split('_PAWC')[1].trim();
      soilName = soilName + " (PAWC: " + pawc_value + ")";
    } else if (this.props.soilSettings[site].pawc) {
      pawc_value = this.props.soilSettings[site].pawc;
      soilName = soilName + " (PAWC: " + pawc_value + ")";
    }
    return soilName;
  }

  // Previously was componentWillMount()
  componentDidMount() {
    var self = this;
    var apiCalls = [];
    // The API endpoint for siteOptions has been designed to take in information
    // for a specific site. However, even though we don't support it at the moment, this step
    // could realistically have multiple sites present.
    this.props.sites.forEach(function(site) {
      // I want to be prepared for this situation, so what we are doing is
      // proactively making the API call for each site that exists in the props
      // passed to this component.
      apiCalls.push(
        FASiteOptionsDataHelper.siteOptionsFromAPI(site)
      );
    });

    // We are chaining all of these API calls together in the one Promise.all()
    // So once they are all complete, we can take the data from each API call
    // and add to a state property which can then be accessed when performing the render.
    Promise.all(apiCalls).then((values) => {
      for (var i = 0; i < values.length; i++) {
        var site = this.props.sites[i];
      }
    }).catch((error) => {
      // I am not setting loadingState to 'error' here as this call has likely
      // already successfully completed in the step before (in SiteSelectionStep)
      // For the API calls above, we would be pulling from cache.
      // If something really does go wrong, we should see it in console, but it
      // should be unlikely for this to happen.
      // Please get mad at Rhys if it does.
      console.error(error);
    })

    this.loadScenarioOptions(this.props.sites[0]);
  }

  render() {
    return (
      <>
        <Box className={sharedStyles.scenarioStepSection}>
          <div>
            <div className={pageStyles.creationContainer}>
              <div className={pageStyles.creationLeft}>
                <Typography variant="h6">
                  Scenario Creation
                </Typography>
                <Typography variant="body1" gutterBottom>
                  After adding at least one scenario, a list of scenarios will display
                  at the bottom of the page. You can review this list before submitting
                  the list of scenarios.
                </Typography>
              </div>
            </div>
            {this.props.sites.map((site) => {
              let showScenarioOptions = this.state.scenarioOptions[site] !== undefined;
              let scenarioOptionsArea;

              // first and formost, we want an error to show if one exists
              if (this.state.loadingState === 'error') {
                scenarioOptionsArea = <LoadingComponent fullwidth={true} state='error' error={this.state.error} />;
                // otherwise if nothing has been selected, don't show anything
              } else if (!showScenarioOptions) {
                scenarioOptionsArea = <div/>;
                // otherwise if something has been selected and we're loading, let's show the loading bar
              } else if (this.state.loadingState === 'loading') {
                scenarioOptionsArea = <LoadingComponent fullwidth={true} state='loading' />;
                // otherwise let's show the results!
              } else {
                scenarioOptionsArea = this.state.scenarioOptions[site].flatMap((factor) => {
                  const currentValues = factor.values;
                  let sortedValues;
                  if (allDates(currentValues)) {
                    sortedValues = sortedLikeDates(currentValues);
                  } else if (allNumeric(currentValues)) {
                    currentValues.sort( function( a , b){
                      if(a > b) return 1;
                      if(a < b) return -1;
                      return 0;
                    });

                    sortedValues = [...currentValues];
                  } else {
                    sortedValues = [...currentValues];
                  }
                  return (
                    <CheckboxGroup2
                      key={factor.label}
                      label={factor.label}
                      options={sortedValues}
                      horizontal={true}
                      labelStyling={{ margin: "1em", marginLeft: "0em" }}
                      isChecked={(option) => {return this.isScenarioOptionSelected(site, factor.key, option)}}
                      onChange={this.onScenarioOptionSelection}
                      updateIndex={JSON.stringify({
                        "site": site,
                        "simField": factor.key
                      })}
                    />
                  );
                });
              }

              return (
                <div key={site}>
                  <Box
                    overflow="auto"
                    sx={{ border: "1px solid", borderRadius: "15px", flexGrow: 1, marginTop: "0.5em", padding: "1em" }}
                  >
                    <Typography variant="h5">
                      {site}
                    </Typography>
                    {
                      this.props.soilSettings[site].soil_type &&
                      <Typography variant="caption">Soil Type: {this.parseSoilTypeLabel(this.props.soilSettings[site].soil_type, site)}</Typography>
                    }
                    <FormGroup row={true} sx={{ marginTop: "0.5em" }}>
                      {/* Scenario Options - based on site and crop combo */}
                      {scenarioOptionsArea}

                      {showScenarioOptions && <div className={pageStyles.formBottomRow}>
                        {/* Scenario Subtitles - how many scenarios would this create? */}
                        <Typography>This configuration will add {this.calculateNumberOfScenariosToGenerate(site)} scenarios.</Typography>

                        {this.isScenarioLimitReached(site) &&
                          <Typography variant="caption">Adding this many scenarios would exceed the scenario limit.</Typography>}

                        {/* Scenario Options - create scenario button */}
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!this.isValidScenarioPresent(site) || this.isScenarioLimitReached(site)}
                          onClick={() => this.onScenarioAdd(site)}
                        >
                          Add Scenario/s
                        </Button>
                      </div>}

                    </FormGroup>

                  </Box>
                </div>
              )
            })}
            {this.props.scenarios.length > 0 && <ScenarioList2
              scenarios={this.props.scenarios}
              handleScenarioDeletion={this.props.handleScenarioDeletion}
            />}
          </div>
          <LoadingComponent/>
        </Box>
      </>
    )
  }
}

FAScenarioSelectionStep.propTypes = {
  sites: PropTypes.array,
  soilSettings: PropTypes.object,
  handleScenarioAddition: PropTypes.func,
  handleScenarioDeletion: PropTypes.func,
  scenarios: PropTypes.array,
  autoSelectSingleEntry: PropTypes.bool,
};

FAScenarioSelectionStep.defaultProps = {
  autoSelectSingleEntry: true,
};

export default FAScenarioSelectionStep;

import React, { Component } from 'react';

import sharedStyles from './sharedPageStyles.module.scss';
import pageStyles from './DisclaimerPage.module.scss';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


class CopyrightPage extends Component {
    render() {
        return (
            <Container maxWidth="lg">
                <Box sx={{ width: '100%' }}>
                    <div className={sharedStyles.descriptionArea}>
                        <div className={sharedStyles.headerSection}>
                            <Typography variant="h4">
                                Copyright
                            </Typography>
                        </div>
                        <Typography variant="body1" paragraph>
                            © State of Queensland, Department of Agriculture and Fisheries, 2015.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            © University of Southern Queensland, 2016.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Except as permitted by the <i>Copyright Act 1968</i>, and as provided for by interactive use of the material on the web page, no part of this work may in any form or by any electronic, mechanical, photocopying, recording, or any other means be reproduced, stored in a retrieval system or be broadcast or transmitted without the prior written permission of the copyright owners.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            The information contained herein is subject to change without notice. The copyright owners, jointly and severally, shall not be liable for technical or other errors or omissions contained herein. The reader/user accepts all risks and responsibility for losses, damages, costs and other consequences resulting directly or indirectly from using this information.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Enquiries about reproduction of DAF owned materials, including a web page or web version of a document, should be directed to <a href="mailto:DAFcopyright@daf.qld.gov.au">DAFcopyright@daf.qld.gov.au</a> or telephone +61 7 3239 3501.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            When you access the Department of Agriculture and Fisheries webpages, you agree to:
                        </Typography>
                        <ul className={sharedStyles.itemList}>
                            <li>retrieve DAF materials for information only</li>
                            <li>download a copy or printout only for your personal use or to inform authorised and potential users about DAF</li>
                            <li>include the copyright notice in any copy you make</li>
                            <li>not modify information found in DAF materials without prior written permission of DAF</li>
                        </ul>
                        <Typography variant="h5" paragraph>
                            Further Information
                        </Typography>
                        <ul className={sharedStyles.itemList}>
                            <li><a href="https://www.daf.qld.gov.au/website-information/copyright-application">Application to use copyright works form</a></li>
                            <li>Contact the <a href="https://www.daf.qld.gov.au/about-us/contact-us">Customer Service Centre</a></li>
                        </ul>
                    </div>
                </Box>
            </Container>
        )
    }
}

export default CopyrightPage;
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

const CompareSectionA = (props) => {
  const effectDone = useRef(false);

  useEffect(() => {
    if (effectDone.current === true) {
    }

    return () => {
      effectDone.current = true;
    }
  }, []);

  return (
    <div>

    </div>
  );
}

CompareSectionA.propTypes = {
  props1: PropTypes.arrayOf(PropTypes.object),
  props2: PropTypes.func,
}


CompareSectionA.defaultProps = {
  props2: () => {
  },
}

export default CompareSectionA;

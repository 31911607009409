import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import parallelCoordinates from 'highcharts/modules/parallel-coordinates';
import {MakeIndicatorCategories, MakeParallelChartSeries} from "../functions";

import InfoButton from "./InfoButton";

import styles from './sharedPageStyles.module.scss';

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);
parallelCoordinates(Highcharts);

const ParallelChart = (props) => {

  const getTitle = () => {
    if (props.Title === '') {
      return 'Multi-criteria performance';
    } else {
      return props.Title;
    }
  };

  const configureYAxis = () => {
    let yaxis = [];

    if (props.SummaryData) {
      // console.log(props.SummaryData);
      if (props.SummaryData.hasOwnProperty("indicators")) {
        props.SummaryData.indicators.forEach(ind => {
          const indicator = ind.value;
          const maxminObj = props.SummaryData.maxMins[indicator];
          let max = Math.ceil(maxminObj.max);
          let min = Math.floor(maxminObj.min);

          if (ind.hasOwnProperty('minAxisValue')) {
            min = ind.minAxisValue;
          }

          //region Adjust max/min for this axis.
          if (max !== 0) {
            if (ind.axisStep !== null) {
              const steps = max / ind.axisStep;
              const nextSteps = Math.floor(steps + 1);
              max = nextSteps * ind.axisStep;
            }
          }

          if (min !== 0) {
            if (ind.axisStep !== null) {
              if (ind.axisStep !== 0) {
                const steps = max / ind.axisStep;
                if (steps !== Math.floor(steps)) {
                  const nextSteps = Math.floor(steps);
                  max = nextSteps * ind.axisStep;
                }
              }
            }
          }
          //endregion

          let tooltipFormat = '{value}';
          if (ind.units !== '') {
            tooltipFormat = `{value} (${ind.units})`;
          }

          const axisConfig = {
            name: indicator,
            min: min,
            max: max,
            labels: {
              format: '{value}',
              align: 'right',
              x: -5,
            },
            ticks: 4,
            tickWidth: 1,
            tickLength: 3,
            tooltipValueFormat: tooltipFormat,
            reversed: ind.axisReverse,
          }

          yaxis.push(axisConfig);
        });
      }
    }

    // console.log('Y Axis Config', yaxis);
    return yaxis;
  };

  const getSubTitle = () => {
    let subtitle = '(average per year';
    if (props.TopScale) {
      subtitle += '; top of scale is the better result';
    }
    subtitle += ')';

    return subtitle;
  };

  const getChartOptions = () => {
    return {
      chart: {
        type: 'spline',
        parallelCoordinates: true,
        parallelAxes: {
          labels: {
            style: {
              color: '#999999'
            }
          },
          gridLineWidth: 0,
          lineWidth: 2,
          showFirstLabel: true,
          showLastLabel: true
        },
      },
      subtitle: {
        text: getSubTitle(),
        style: {
          color: '#808080',
          fontSize: '0.7em',
        }
      },
      title: {
        text: getTitle(),
      },
      legend : {
        enabled : true,
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span>' +
          '{series.name}: <b>{point.formattedValue}</b><br/>'
      },
      plotOptions: {
        series: {
          accessibility: {
            enabled: false
          },
          animation: false,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false
              }
            }
          },
          states: {
            hover: {
              halo: {
                size: 0
              }
            }
          },
          events: {
            mouseOver: function () {
              this.group.toFront();
            }
          }
        }
      },
      xAxis: {
        categories: MakeIndicatorCategories(props.SummaryData.indicators),
        offset: 10,
      },
      yAxis: configureYAxis(),
      colors: ['rgba(11, 200, 200, 0.1)'],
      series: MakeParallelChartSeries(props.SummaryData),
    };
  };

  return (
    <div className={styles.graphContainer}>
      <div>
        {props.SummaryData && <HighchartsReact
          highcharts={Highcharts}
          options={getChartOptions()}
          constructorType={ 'chart' }
        />}
      </div>
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
}

ParallelChart.propTypes = {
  SummaryData: PropTypes.object.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
  InfoKey: PropTypes.string,
  TopScale: PropTypes.bool,
};

ParallelChart.defaultProps = {
  Title: '',
  Explanation: '',
  InfoKey: '',
  TopScale: false,
};

export default ParallelChart;

import React, { useState } from 'react';
import { Typography, LinearProgress, Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import pageStyles from "./LoadingComponent.module.scss";

const LoadingComponent = (props) => {
  const [open, setOpen] = useState(true);

  const getErrorString = (error) => {
    if (error !== null) {
      if (typeof error === 'object' ) {
        // we are likely dealing with a scenario submit error here...
        let reason = error['reason'] || 'UNKNOWN_REASON';
        let scenario = error['scenario'] || {};
        delete scenario['data'];
        return JSON.stringify(scenario) + " - " + reason;
      }

      // we are assuming by default this will be a string
      return error;
    }
  };

  const getErrorDetails = () => {
    let errors = [];

    if (props.Error) {
      let initialError = props.Error;


      if (initialError.hasOwnProperty('code') && initialError.hasOwnProperty('message')) {
        // this may be an AxiosError (e.g. network problem) rather than an error
        // returned by the API
        let code = initialError.code;
        let message = initialError.message;
        errors.push(code + ': ' + message);
      }

      if (initialError.hasOwnProperty('response')) {
        // we have been sent back something from the API

        if (initialError.response.hasOwnProperty('data')) {
          let errorData = initialError.response.data;
          // we sometimes send multiple errors or a single error
          // and that will be defined in 'errors' or 'error' respectively
          if (errorData.hasOwnProperty('errors')) {
            errorData.errors.forEach(error => {
              let errorString = getErrorString(error);
              errors.push(errorString);
            })
          } else if (errorData.hasOwnProperty('error')) {
            errors.push(errorData.error);
          } else {
            errors.push("An unknown error has occurred.");
          }
        } else {
          errors.push("An unknown error has occurred.");
        }
      }
    }

    return errors;
  };

  let styles = [pageStyles.spaced];
  if (props.FullWidth) {
    styles.push(pageStyles.fullwidth);
  }

  return (
    <div className={styles.join(' ')}>
      {props.State === 'loading' &&
        <div>
          <Typography variant="body1" align="center">
            Loading, please wait...
          </Typography>
          <LinearProgress />
        </div>
      }
      {props.State === 'error' &&
        <div>
          {props.Dismissable ?
            <Collapse in={open}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setOpen(false)}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <AlertTitle>Error</AlertTitle>
                {getErrorDetails().map((error, idx) => {
                  return (
                    <li key={`alertert${idx}`}>{error}</li>
                  )
                })}
              </Alert>
            </Collapse>
            :
            <Alert
              severity="error"
            >
              <AlertTitle>Error</AlertTitle>
              {getErrorDetails().map((error, index) => {
                return (
                  <li key={`alerterr${index}`}>{error}</li>
                )
              })}
            </Alert>
          }
        </div>
      }
    </div>
  );
};

LoadingComponent.propTypes = {
  FullWidth: PropTypes.bool,
  Dismissable: PropTypes.bool,
  State: PropTypes.string,
  Error: PropTypes.object,
};

export default LoadingComponent;

import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {makeAColour, capitalise} from "../functions";
import styles from './RefRotation.module.scss';
import {Tooltip} from "@mui/material";

//region Setup different table styles

const sxCommon = {
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxContainer = {
  // width: "max-content",
  width: 'auto',
  height: '110px',
  marginLeft: "auto",
  marginRight: "auto",
  padding: '2px',
};
//endregion

const RefRotation = (props) => {

  const renderCrop = (cropName, rowIndex, colIndex, withBorder) => {
    const cellKey = `row${rowIndex}c${cropName}_${colIndex}`;
    const lcCrop = cropName.toLowerCase().trim();

    let cropObj = null;
    if (props.Crops) {
      cropObj = props.Crops.find(c => c.Name.toLowerCase().trim() === lcCrop);
      // console.log(cropObj);
    }

    const cellStyle = {};
    if (cropObj) {
      cellStyle.backgroundColor = makeAColour(cropObj.bg_colour);
      cellStyle.color = makeAColour(cropObj.fg_colour);
      cellStyle.padding = "0px 3px";
      cellStyle.margin = 0;

      if (withBorder) {
        cellStyle.borderRightWidth = 1;
        cellStyle.borderRightStyle = "solid";
        cellStyle.borderRightColor = "#000000";
      }

      return (
        <TableCell key = {cellKey} align="center" style={cellStyle}>
          {lcCrop}
        </TableCell>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.container}>
      {props.Rotation &&
      <TableContainer component={Paper} sx={{...sxContainer}}>
        <fieldset style={{border: 'none'}}>
          <Table style={{ border: "1px solid grey" }} size="small">
            <TableHead>
              <TableRow key="header1" >
                <TableCell colSpan="2" align="center">Year 1</TableCell>
                <TableCell colSpan="2" align="center">Year 2</TableCell>
                <TableCell colSpan="2" align="center">Year 3</TableCell>
                <TableCell colSpan="2" align="center">Year 4</TableCell>
                <TableCell align="center" sx={{...sxCommon}}>Sowing Rule</TableCell>
                <TableCell align="center" sx={{...sxCommon}}>Nitrogen Fertiliser Rate</TableCell>
                <TableCell align="center" sx={{...sxCommon}}>Targeted</TableCell>
                <TableCell align="center" sx={{...sxCommon}}>Summer/winter</TableCell>
                <TableCell align="center" sx={{...sxCommon}}>Planned time</TableCell>
              </TableRow>
              <TableRow sx={{maxHeight: 15}} key="header2" >
                <TableCell align="center">Summer</TableCell>
                <TableCell align="center">Winter</TableCell>
                <TableCell align="center">Summer</TableCell>
                <TableCell align="center">Winter</TableCell>
                <TableCell align="center">Summer</TableCell>
                <TableCell align="center">Winter</TableCell>
                <TableCell align="center">Summer</TableCell>
                <TableCell align="center">Winter</TableCell>
                <TableCell align="center" sx={{...sxCommon}}></TableCell>
                <TableCell align="center" sx={{...sxCommon}}></TableCell>
                <TableCell align="center" sx={{...sxCommon}}>crops/year</TableCell>
                <TableCell align="center" sx={{...sxCommon}}>crop ratio</TableCell>
                <TableCell align="center" sx={{...sxCommon}}>in fallow</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {renderCrop(props.Rotation.Yr1_Summer, 1, 1, 0)}
                {renderCrop(props.Rotation.Yr1_Winter, 1, 2, 1)}
                {renderCrop(props.Rotation.Yr2_Summer, 1, 3, 0)}
                {renderCrop(props.Rotation.Yr2_Winter, 1, 4, 1)}
                {renderCrop(props.Rotation.Yr3_Summer, 1, 5, 0)}
                {renderCrop(props.Rotation.Yr3_Winter, 1, 6, 1)}
                {renderCrop(props.Rotation.Yr4_Summer, 1, 7, 0)}
                {renderCrop(props.Rotation.Yr4_Winter, 1, 8, 1)}
                <TableCell key="row1k9" align="left" sx={{...sxCommon}} className={styles.cellEllipsis}>
                  <Tooltip title={props.SowingRule}>
                    <div className={styles.trimText}>{props.SowingRule}</div>
                  </Tooltip>
                </TableCell>
                <TableCell key="row1k10" align="center" sx={{...sxCommon}}>{capitalise(props.NFertLevel)}</TableCell>
                <TableCell key="row1k11" align="center" sx={{...sxCommon}}>{props.Rotation.TargetedCrops}</TableCell>
                <TableCell key="row1k12" align="center" sx={{...sxCommon}}>{props.Rotation.CropRatio}</TableCell>
                <TableCell key="row1k13" align="center" sx={{...sxCommon}}>{props.Rotation.PlannedTimeFallow}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </fieldset>
      </TableContainer>}
    </div>
  );
}

RefRotation.propTypes = {
  Rotation: PropTypes.object,
  Crops: PropTypes.arrayOf(PropTypes.object).isRequired,
  SowingRule: PropTypes.string.isRequired,
  NFertLevel: PropTypes.string.isRequired,
};

RefRotation.defaultProps = {
  Rotation: null,
};

export default RefRotation;

import React, {useState, useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {Typography, Box, Checkbox, FormControlLabel, Collapse} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CropTableCell from "./CropTableCell";
import { visuallyHidden } from '@mui/utils';
import LoadingComponent from "./LoadingComponent";
import miscHelper from '../ra_Helpers/miscHelper';
import {selectCrops} from "../features/rotationSlice";
import {useDispatch, useSelector} from "react-redux";
import {FormatExploreTableData, anycaseEquals} from "../functions";

//region Setup different table styles
const sx2ColBorder = {
  borderRightWidth: "1px",
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  borderBottomWidth: 0,
  padding: 0,

};

const sxNoBottom = {
  borderBottomWidth: 0,
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxNoBottom4 = {
  borderBottomWidth: 0,
  paddingRight: 4,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
};

const sxBottom = {
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: '#000000',
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxBottomRight = {
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: '#000000',
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxBottomRight1 = {
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: '#000000',
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  paddingRight: 1,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 1,
};

const sxRight = {
  borderBottomWidth: 0,
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxRight1 = {
  borderBottomWidth: 0,
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  paddingRight: 1,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 1,
};

const sxId = {
  maxWidth: '20px',
  borderBottomWidth: 0,
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: '#000000',
  paddingRight: 1,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 1,
};

const sxContainer = {
  // width: "max-content",
  width: 'auto',
  height: 500,
  marginLeft: "auto",
  marginRight: "auto",
  padding: '20px',
};
//endregion

const PerformanceIndicators = miscHelper.sectionCIndicators();

const headCells1 = [
  {
    id: 'year1',
    numeric: false,
    align: 'center',
    colspan: 2,
    disablePadding: true,
    label: 'Year 1',
    canSort: false,
  },
  {
    id: 'year2',
    numeric: false,
    align: 'center',
    colspan: 2,
    disablePadding: true,
    label: 'Year 2',
    canSort: false,
  },
  {
    id: 'year3',
    numeric: false,
    align: 'center',
    colspan: 2,
    disablePadding: true,
    label: 'Year 3',
    canSort: false,
  },
  {
    id: 'year4',
    numeric: false,
    align: 'center',
    colspan: 2,
    disablePadding: true,
    label: 'Year 4',
    canSort: false,
  },
  // performance indicator column added by code (see updateHeaderCells() )
];

const headCells2 = [
  {id: 'summer1', numeric: false, align: 'center', disablePadding: true, label: 'Summer', canSort: false},
  {id: 'winter1', numeric: false, align: 'center', disablePadding: true, label: 'Winter', canSort: false},
  {id: 'summer2', numeric: false, align: 'center', disablePadding: true, label: 'Summer', canSort: false},
  {id: 'winter2', numeric: false, align: 'center', disablePadding: true, label: 'Winter', canSort: false},
  {id: 'summer3', numeric: false, align: 'center', disablePadding: true, label: 'Summer', canSort: false},
  {id: 'winter3', numeric: false, align: 'center', disablePadding: true, label: 'Winter', canSort: false},
  {id: 'summer4', numeric: false, align: 'center', disablePadding: true, label: 'Summer', canSort: false},
  {id: 'winter4', numeric: false, align: 'center', disablePadding: true, label: 'Winter', canSort: false},
  // performance indicator columns added by code (see updateHeaderCells() )
];

//region Subclassed Table Headings
const EnhancedTableHead = (props) => {
  const {order, orderBy, onRequestSort} = props;
  const {HeadingRow1, HeadingRow2} = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const renderSortedCell = headingCell => {
    return (
      <TableCell
        key={headingCell.id}
        align={headingCell.align}
        padding={headingCell.disablePadding ? 'none' : 'normal'}
        colSpan={headingCell.colspan}
        sortDirection={orderBy === headingCell.id ? order : false}>
          <TableSortLabel
            active={orderBy === headingCell.id}
            direction={orderBy === headingCell.id ? order : 'asc'}
            onClick={createSortHandler(headingCell.id)}
          >
            {headingCell.label}
            {orderBy === headingCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
      </TableCell>
    );
  }

  const renderCell = (headingCell) => {
    return (

      <TableCell
        key={headingCell.id}
        align={headingCell.align}
        padding={headingCell.disablePadding ? 'none' : 'normal'}
        colSpan={headingCell.colspan}
        sortDirection={orderBy === headingCell.id ? order : false}>
          {headingCell.canSort ? renderSortedCell(headingCell) : headingCell.label}
      </TableCell>
    );
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell key="head0_cb" padding="checkbox"></TableCell>
        {HeadingRow1.map((headCell) => (renderCell(headCell)) )}
      </TableRow>

      <TableRow>
        <TableCell key="head1_cb" padding="checkbox"></TableCell>
        {HeadingRow2.map((headCell) => (renderCell(headCell)) )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  HeadingRow1: PropTypes.array.isRequired,
  HeadingRow2: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
//endregion


const ExploreRotationList = (props) => {
  const effectDone = useRef(false);
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [headings1, setHeadings1] = useState(headCells1);
  const [headings2, setHeadings2] = useState(headCells2);

  const [rotations, setRotations] = useState([]);
  const [filteredListCount, setFilteredListCount] = useState(0);
  const [sortIndicator, setSortIndicator] = useState('');
  const [currentSortOrder, setCurrentSortOrder] = useState('asc');
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');

  const selectedIndicatorCount = useMemo(() => {
    let result = 0;
    if (Array.isArray(props.SelectedIndicators)) {
      props.SelectedIndicators.forEach(pi => {
        if (pi !== '') {
          result += 1;
        }
      });
    }

    return result;
  }, [props.SelectedIndicators]);

  useEffect(() => {
    if (Array.isArray(props.SelectedIndicators)) {
      if (props.SelectedIndicators.length > 0) {
        if (props.SelectedIndicators[0] !== '') {
          setSortIndicator(props.SelectedIndicators[0]);
        }

        updateHeaderCells(props.SelectedIndicators);
      }
    }

    setCurrentSortOrder('asc');
  }, [props.SelectedIndicators]);

  useEffect(() => {

    if (Array.isArray(props.Rotations)) {
      const rots = FormatExploreTableData(
        props.Rotations,
        props.ReferenceRotation,
        props.SelectedIndicators,
        sortIndicator,
        currentSortOrder
      );
      setRotations([...rots]);
    }

    // Setup initial sorting.
    let currentOrderBy = '';
    if (Array.isArray(props.SelectedIndicators)) {
      if (props.SelectedIndicators.length > 0) {
        currentOrderBy = props.SelectedIndicators[0];
        setOrderBy(currentOrderBy);
      }
    }

    if (props.ReferenceRotation && props.OtherRotations) {
      reBuildRowData(props.ReferenceRotation, props.OtherRotations, currentOrderBy, 'asc');
    }

  }, [props.Rotations, props.ReferenceRotation]);

  const reBuildRowData = (referenceRotation, otherRotations, orderBy, order) => {
    const rowData = [];
    rowData.push(referenceRotation);

    //region Create a blank row in the data.
    const blankRow =  Object.assign({}, referenceRotation);
    blankRow.id = null;
    blankRow.BaseRotationId = null;
    blankRow.RotationName = null;
    blankRow.profileDepth_mm = null;
    blankRow.percFull = null;
    blankRow.gross_margin = null;
    blankRow.downside_risk = null;
    blankRow.revenue = null;
    blankRow.variable_costs = null;
    blankRow.revcost_ratio = null;
    blankRow.crop_intensity = null;
    blankRow.crop_failure = null;
    blankRow.financial_wue = null;
    blankRow.financial_fue = null;
    blankRow.water_uptake = null;
    blankRow.n_uptake = null;
    blankRow.water_loss = null;
    blankRow.organic_c = null;
    blankRow.organic_n = null;
    blankRow.organic_matter = null;
    blankRow.incrop_rain = null;

    rowData.push(blankRow);
    //endregion

    //region Sort the other rotations.
    if (orderBy !== '') {
      const sortedRotations = props.OtherRotations.toSorted((a, b) => {
        return (order === 'asc') ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
      });

      sortedRotations.forEach(r => {
        rowData.push(r);
      });

      setRows([...rowData]);
    }
    //endregion
  };

  //region Handle user interactions
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    reBuildRowData(props.ReferenceRotation, props.OtherRotations, property, newOrder);
  };

  const handleToggle = () => {

  };

  const handleClick = (event, id) => {
    const selectedIndex = props.SelectedRotations.indexOf(id);
    const selectedCount = props.SelectedRotations.length;

    console.log('handleClick', id, selectedIndex);

    let newSelected = [...props.SelectedRotations];
    if (selectedIndex === -1) {
      // Not already in the list.
      if (selectedCount < props.MaxSelectCount) {
        newSelected.push(id);
      }
    } else {
      // Already in the list. Remove it.
      newSelected = selected.filter(s => s !== id);
    }

    setSelected(newSelected);

    props.OnSelect(newSelected);
  };
  //endregion

  const updateHeaderCells = (performanceIndicators) => {

    // Update first heading row.
    const perfIndCol = {
      id: 'perfinds',
      numeric: false,
      align: 'center',
      colspan: selectedIndicatorCount,
      disablePadding: true,
      label: 'Performance Indicators',
    };

    const tempArray = [...headCells1];
    tempArray.push(perfIndCol);
    setHeadings1([...tempArray]);

    // Update second heading row.
    const tempHeadings2 = [...headCells2];

    performanceIndicators.forEach(p => {
      if (p !== '') {
        const indicator = PerformanceIndicators.find(perf => anycaseEquals(perf.value, p));
        if (indicator) {
          let labelAndUnits = indicator.label;
          if (indicator.units !== '') {
            labelAndUnits += ` (${indicator.units})`;
          }
          const newColumn = {
            id: p,
            numeric: true,
            align: 'center',
            disablePadding: false,
            label: labelAndUnits,
            canSort: true,
          };
          tempHeadings2.push(newColumn);
        }
      }
    });

    setHeadings2([...tempHeadings2]);
  };

  const isSelected = (optionValue) => {
    let result = false;

    if (optionValue) {
      if (props.Selected) {
        if (props.Selected.indexOf(optionValue) !== -1) {
          result = true;
        }
      }
    }

    return result;
  };

  if (loading) {
    return <LoadingComponent State="loading" />
  }

  const renderCrops = (rotationName, rowIndex) => {
    // Expecting a delimited string of crops or crop abbreviations.
    if (rotationName) {
      const cropParts = rotationName.replace('-', '_').split('_');
      // console.log(cropParts);
      return cropParts.map((cp, i) => {
        const lBorder = (i === 0);
        const rBorder = (i % 2 === 1);

        return <CropTableCell
                  TableRowIndex={rowIndex}
                  CropName={cp}
                  TableColumnIndex={i}
                  LeftBorder={lBorder}
                  RightBorder={rBorder}
               />
      });
    } else {
      // Need to return empty table cells.
      const cropParts = ['','','','','','','',''];
      return cropParts.map((cp, i) => {
        const lBorder = (i === 0);
        const rBorder = (i % 2 === 1);

        return <CropTableCell
          TableRowIndex={rowIndex}
          CropName={cp}
          TableColumnIndex={i}
          LeftBorder={lBorder}
          RightBorder={rBorder}
        />
      });
    }
  };

  const renderValues = (row, perfIndicators, rowIndex) => {
    if (props.PerformanceIndicators) {
      const indicators = props.PerformanceIndicators.filter(p => p !== '');

      return indicators.map((indicator, index) => {
        const val = (row[indicator] === null) ? '' : row[indicator].toFixed(3);
        return (<TableCell align="right" style={{width: '120px'}}>{val}</TableCell>);
      });
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              HeadingRow1={headings1}
              HeadingRow2={headings2}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.BaseRotationId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.BaseRotationId)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`r_${index}`}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      {index === 0 && <div>Ref</div>}
                      {(index > 0 && row.RotationName !== null) && <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />}
                    </TableCell>
                    {renderCrops(row.RotationName, index)}
                    {renderValues(row, props.PerformanceIndicators, index)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

ExploreRotationList.propTypes = {
  ReferenceRotation: PropTypes.number.isRequired,
  Rotations: PropTypes.array.isRequired,
  SelectedIndicators: PropTypes.array.isRequired,
  SelectedRotations: PropTypes.array,
  MaxSelectCount: PropTypes.number,
  OnSelect: PropTypes.func,
  ShowResults: PropTypes.bool,
  Selected: PropTypes.array,
}


ExploreRotationList.defaultProps = {
  Title: 'Rotation List',
  SelectedRotations: [],
  OnSelect:  () => {},
  ShowResults: false,
  MaxSelectCount: 2,
  Selected: [],
}

export default ExploreRotationList;

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import {capitalise, ColourDarken, ColourLuminance, getOverallMinMax, makeAColour} from "../functions";

import styles from './sharedPageStyles.module.scss';
import InfoIcon from "../ra_images/infoIcon.png";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);

const bandColour = 'rgba(255,20,20,0.1)';

const chartedIndicators = ['gross_margin', 'downside_risk', 'cropping_intensity'];

const series = [
  {
    name: 'Reference rotation',
    id: 'reference',
    color: 'blue',
    marker: {
      symbol: 'circle'
    },
    data: [],
  },
  {
    name: 'Highest gross margin',
    id: 'gross_margin',
    color: 'red',
    marker: {
      symbol: 'triangle'
    },
    data: [],
  },
  {
    name: 'Lowest gross margin',
    id: 'gross_margin',
    color: 'red',
    marker: {
      symbol: 'triangle-down'
    },
    data: [],
  },
  {
    name: 'Highest risk',
    id: 'downside_risk',
    color: 'green',
    marker: {
      symbol: 'square'
    },
    data: [],
  },
  {
    name: 'Lowest risk',
    id: 'downside_risk',
    color: 'green',
    marker: {
      symbol: 'diamond'
    },
    data: [],

  },
  {
    name: 'Highest cropping intensity',
    id: 'hicropint',
    color: 'black',
    marker: {
      symbol: 'square'
    },
    data: [],
  },
  {
    name: 'Lowest cropping intensity',
    id: 'locropint',
    color: 'black',
    marker: {
      symbol: 'diamond'
    },
    data: [],
  },
];


const ScatterChart = (props) => {
  const effectDone = useRef(false);
  const [chartData, setChartData] = useState([]);
  const [matchedSeasons, setMatchedSeasons] = useState([]);
  const [plotBands, setPlotBands] = useState(null);
  const [minY, setMinY] = useState(null);
  const [maxY, setMaxY] = useState(null);


  const getCropColour = (cropname, withGradient = true, makeDarker = false) => {
    let result = null;

    const lcCropName = cropname.toString().trim().toLowerCase();
    const aCrop = props.Crops.find(c => c.Name.toLowerCase() === lcCropName);

    if (aCrop) {
      const startColour = makeAColour(aCrop.bg_colour);

      if (withGradient) {
        const stopColour = ColourLuminance(startColour, 0.2);
        const perShapeGradient = {x1: 0, y1: 0, x2: 1, y2: 1};
        const gradient = {
          linearGradient: perShapeGradient,
          stops: [[0, startColour], [1, stopColour]],
        };

        result = gradient;
      } else {
        result = makeAColour(startColour);

        if (aCrop.neverDarken === 0) {
          if (makeDarker) {
            result = ColourDarken(startColour, .1);
          }
        }
      }
    }

    return result;
  };

  const getChartData = (resultData) => {
    const xSeries = [];

    if (resultData) {
      // console.log(resultData);
      if (resultData.hasOwnProperty("ReferenceRotation")) {
        const referenceRotation = resultData.ReferenceRotation;

        const seriesObj = {
          name: 'Reference rotation',
          color: 'blue',
          marker: {
            symbol: 'circle'
          },
          zIndex: 1,
          data: [[referenceRotation.downside_risk, referenceRotation.gross_margin]],
        };
        xSeries.push(seriesObj);
      }

      if (resultData.hasOwnProperty("BestWorstList")) {
        const {BestWorstList} = resultData;

        const bestCropIntensity = BestWorstList.find((b) => b.Indicator === 'crop_intensity' && b.BestWorst === 'BEST');
        const worstCropIntensity = BestWorstList.find((b) => b.Indicator === 'crop_intensity' && b.BestWorst === 'WORST');
        const bestGrossMargin = BestWorstList.find((b) => b.Indicator === 'gross_margin' && b.BestWorst === 'BEST');
        const worstGrossMargin = BestWorstList.find((b) => b.Indicator === 'gross_margin' && b.BestWorst === 'WORST');
        // Seems I have these about face in the database queries. Easier to fix here,
        const bestDownsideRisk = BestWorstList.find((b) => b.Indicator === 'downside_risk' && b.BestWorst === 'WORST');
        const worstDownsideRisk = BestWorstList.find((b) => b.Indicator === 'downside_risk' && b.BestWorst === 'BEST');
        // const bestDownsideRisk = BestWorstList.find((b) => b.Indicator === 'downside_risk' && b.BestWorst === 'BEST');
        // const worstDownsideRisk = BestWorstList.find((b) => b.Indicator === 'downside_risk' && b.BestWorst === 'WORST');

        if (bestGrossMargin) {
          const seriesObj = {
            name: 'Highest gross margin',
            color: 'green',
            marker: {symbol: 'triangle'},
            zIndex: 1,
            data: [[bestGrossMargin.downside_risk, bestGrossMargin.gross_margin]],
          };
          xSeries.push(seriesObj);
        }
        if (worstGrossMargin) {
          const seriesObj = {
            name: 'Lowest gross margin',
            color: 'red',
            marker: {symbol: 'triangle'},
            zIndex: 1,
            data: [[worstGrossMargin.downside_risk, worstGrossMargin.gross_margin]],
          };
          xSeries.push(seriesObj);
        }

        if (bestDownsideRisk) {
          const seriesObj = {
            name: 'Highest downside risk',
            color: 'green',
            marker: {symbol: 'diamond'},
            zIndex: 1,
            data: [[bestDownsideRisk.downside_risk, bestDownsideRisk.gross_margin]],
          };
          xSeries.push(seriesObj);
        }
        if (worstDownsideRisk) {
          const seriesObj = {
            name: 'Lowest downside risk',
            color: 'red',
            marker: {symbol: 'diamond'},
            zIndex: 1,
            data: [[worstDownsideRisk.downside_risk, worstDownsideRisk.gross_margin]],
          };
          xSeries.push(seriesObj);
        }

        if (bestCropIntensity) {
          const seriesObj = {
            name: 'Highest cropping intensity',
            color: 'green',
            marker: {symbol: 'square'},
            zIndex: 1,
            data: [[bestCropIntensity.downside_risk, bestCropIntensity.gross_margin]],
          };
          xSeries.push(seriesObj);
        }
        if (worstCropIntensity) {
          const seriesObj = {
            name: 'Lowest cropping intensity',
            color: 'red',
            marker: {symbol: 'square'},
            zIndex: 1,
            data: [[worstCropIntensity.downside_risk, worstCropIntensity.gross_margin]],
          };
          xSeries.push(seriesObj);
        }
      }

      if (resultData.hasOwnProperty('ScatterList')) {
        const seriesObj = {
          name: 'All other rotations',
          color: '#e8e8e8',
          marker: {
            symbol: 'circle',
            radius: 2.5,
          },
          zIndex: 0,
          data: [],
        };

        resultData.ScatterList.forEach(s => {
          seriesObj.data.push([s.downside_risk, s.gross_margin]);
        });
        xSeries.push(seriesObj);
      }
    }

    // console.log(xSeries);
    return xSeries;
  };


  const getYAxisTitle = () => {
    let result = '';
    if (props.YAxisTitle === '') {
      result = 'Long-term gross margin (AUD/ha)';
    } else {
      result = props.YAxisTitle;
    }

    return result;
  };

  const getTitle = () => {
    if (props.Title === '') {
      return 'Risk-return trade-off';
    } else {
      return props.Title;
    }
  };

  const getChartOptions = () => {
    return {
      chart : {
        type: 'scatter',
        zoomType: 'xy',
      },
      title : {
        text : getTitle(),
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: 'Long-term downside risk (AUD/ha)'
        },
        labels: {
          format: '{value} $/ha'
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true
      },
      yAxis: {
        title: {
          text: getYAxisTitle(),
        },
      },
      legend: {
        enabled: true
      },
      series: getChartData(props.ResultsData),
      tooltip : {
        headerFormat : '<span style="font-size:10px;font-weight:bold">{point.key}</span><table>',
        pointFormat : '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} ' + props.Units + '</b></td></tr>',
        footerFormat : '</table>',
        shared : true,
        useHTML : true,
        valueDecimals : 2
      },
    }
  };

  return (
    <div className={styles.graphContainer}>
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={getChartOptions()}
          constructorType={ 'chart' }
        />
      </div>
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
};

ScatterChart.propTypes = {
  ResultsData: PropTypes.array.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
  YAxisTitle: PropTypes.string,
  InfoKey: PropTypes.string,
};

ScatterChart.defaultProps = {
  Title: '',
  Explanation: '',
  YAxisTitle: '',
  InfoKey: '',
};

export default ScatterChart;

import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './IFrameWrapper.module.scss';

const IFrameWrapper = (props) => {
  const ref = React.useRef();
  const [height, setHeight] = React.useState("100%");
  const [width, setWidth] = React.useState("100%");
  const onLoad = () => {
    // setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
    // console.log('IFrame loaded');
  };

  return (
    <div className={styles.container}>
        <iframe
          sandbox
          allowFullScreen
          ref={ref}
          onLoad={onLoad}
          id="iframe"
          src={props.Source}
          width={width}
          height={height}
        ></iframe>
    </div>
  );
}

IFrameWrapper.propTypes = {
  Source: PropTypes.string.isRequired,
}

export default IFrameWrapper;

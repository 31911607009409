import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Typography} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import usqLogo from '../images/usq-logo-black.png';
import qldGovLogo from '../images/qg_logo_mar.png';
import utasLogo from '../images/UniTas_logo.svg';
import austGovLogo from '../images/aust_gov_stacked.png';
import CRCNALogo from '../images/CRC_NA_Logo.jpeg';
import FDFLogo from '../images/Logo_FDF.png';
import styles from "./LogoSection.module.scss";

const logoDetails = [
  {name: 'UTas', image: utasLogo, URL: "https://www.utas.edu.au/", alt: "University of Tasmania" },
  {name: 'CRCNA', image: CRCNALogo, URL: "http://www.crcna.com.au//", alt: "Cooperative Research Centre for Developing Northern Australia" },
  {name: 'AusGov', image: austGovLogo, URL: "https://www.directory.gov.au/", alt: "Australian Government" },
  {name: 'FDF', image: FDFLogo, URL: "http://www.daff.qld.gov.au/", alt: "Future Drought Fund" },
];

const Acknowledgements = (props) => {

  return (
    <div style={{
      width: '40%',
      height: '60%',
      margin: 'auto',
      outline: 'solid 1px black',
      borderRadius: 10,
      padding: '20px'}}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography >
            WhopperCropper, the forerunner to CropARM, was originally developed by the Queensland
            Department of Primary Industries and the Agricultural Production Systems Research Unit.
            It was a machine based decision support tool to aid the cropping industry in improving
            agronomic tactical decisions.
          </Typography>
          <Typography marginTop='10px' >
            The Queensland Department of Agriculture and Fisheries partnered with the University of
            Southern Queensland, as part of the Broad Acre Cropping Initiative, to develop an Agricultural
            Risk Management decision support tool platform ARMonline.net.au. The online decision support
            tools include ClimateARM, FallowARM and the redeveloped WhopperCropper to what is now called
            CropARM.
          </Typography>
          <Typography  marginTop='10px'  >
            Since the ARMonline launch in 2016, additional locations have been added to the tools with
            the support of the University of Tasmania and the Cooperative Research Centre for Developing
            Northern Australia.  In 2022 the Australian Government’s Future Drought Fund through an
            Innovation Grant supporting the addition of new functions and analytics to CropARM and
            the development of the RotationARM tool.
          </Typography>
        </Grid>
        {logoDetails.map(logo => (
          <Grid xs={6}>
            <a href={logo.URL} key={logo.name} target="_blank" rel="noreferrer">
              <img
                src={logo.image}
                className={styles.logo}
                alt={logo.alt}
              />
            </a>
          </Grid>
        ) )}
      </Grid>
    </div>
  );
};


export default Acknowledgements;

import React, {useEffect, useState, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import { Box, Typography, Tabs, Tab } from '@mui/material';
import siteHelper from '../ra_Helpers/siteHelper';
import MapWrapper from '../components/MapWrapper';
import pageStyles from './SitePicker.module.scss';
import sharedStyles from "./sharedPageStyles.module.scss";
import LoadingComponent from '../components/LoadingComponent';
import SiteList from "./SiteList";
import {sitesByStateObj, siteLatLongInfoObj, getStatesList} from '../functions';

const SitePicker2 = (props) => {
  const dispatch = useDispatch();
  const mapChild = useRef();
  const initialLoadDone = useRef(false);
  const [statesList, setStatesList] = useState([]);
  const [sitesByState, setSitesByState] = useState(null);
  const [siteLatLongInfo, setSiteLatLongInfo] = useState(null);
  const [mapCenter, setMapCenter] = useState({});
  const [currentState, setCurrentState] = useState('QLD');

  useEffect(() => {
    if (props.Sites) {
      if (props.Sites.length > 0) {
        setStatesList(getStatesList(props.Sites));
        setSitesByState(sitesByStateObj(props.Sites));
        setSiteLatLongInfo(siteLatLongInfoObj(props.Sites));
        initialLoadDone.current = true;
      }
    }
  }, [props.Sites]);

  const onTabChange = (event, value) => {
    if (value) {
      console.log('SitePicker2 onTabChange ', value);
      setCurrentState(value);
      props.OnStateChange(value);
    }
  };

  const onSiteSelection = (selectedArray) => {
    console.log('OnSiteSelection', selectedArray);
    let currentSite = null;

    if (selectedArray) {
      if (selectedArray.length > 0) {
        const firstSite = selectedArray[0];

        let locationName = firstSite.toString();
        if (locationName !== '') {
          let locationCoordinates = siteLatLongInfo.find(element => element.site === locationName);
          let siteCoordinates = null;
          if (locationCoordinates) {
            siteCoordinates = {
              lat: locationCoordinates.lat,
              lng: locationCoordinates.lng,
            }

            currentSite = props.Sites.find(s => s.Name ===  locationName && s.State === locationCoordinates.state);

            mapChild.current.setMapCenter(locationCoordinates.lat, locationCoordinates.lng);
          }

          props.HandleSiteSelection(currentSite, true);
        }
      }
    }
  };

  const isChecked = (site) => {
    return this.props.SelectedSite === site;
  };

  const isDisabled = (site) => {
    // For the moment no sites will be disabled. Selecting a site
    // will now automatically deselect all others.
    return false;
  };

  const handleMapIconClick = (event, value) => {
    // console.log(value);
    const {site, state, selected} = value;

    // If this site is already selected do nothing.
    if (selected) {
      return;
    } else {
      props.HandleSiteSelection(site, true);
    }

    // let us also change the active tab group, so the proper state is highlighted
    // when the map icon has been clicked
    onTabChange(null, state);
  };

  if (!initialLoadDone.current) {
    return (
      <div>
        <LoadingComponent
          FullWidth={true}
          State='loading'
        />
      </div>
    );
  }

  return (
    <Box className={sharedStyles.scenarioStepSection}>
      <div className={pageStyles.pickerContainer}>
        {props.Title !== '' && (
          <Typography variant="h6">
            {props.Title}
          </Typography>
        )}
        {props.Title !== '' && (
          <Typography variant="body1" gutterBottom>
            Select site to analyse.
          </Typography>
        )}
        <Box sx={{ display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", alignItems: "stretch" }}>
          <Box sx={{ flexGrow: 0 }}>
            {props.Sites.length > 0 &&
              <Tabs
                orientation="vertical"
                value={currentState}
                variant="scrollable"
                onChange={onTabChange}
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                {statesList.map((state) => {
                  return (
                    <Tab value={state} key={state} label={state} />
                  )
                })}
              </Tabs>
            }
          </Box>
          {initialLoadDone.current && <Box sx={{ flexGrow: 0, flex: 1 }}>
            {Object.keys(sitesByState).map((state) => {
              return (
                <div key={state} hidden={currentState !== state} id={`vertical-tabpanel-${state}`} className={pageStyles.siteSelectionSection}>
                  <Box overflow="auto" sx={{ flexGrow: 1, padding: "0.5em"}}>
                    <SiteList
                      CurrentSiteName={props.CurrentSiteName}
                      Sites={sitesByState[state]}
                      Columns={2}
                      HideTitle={true}
                      HideBorder={true}
                      SiteSelect={onSiteSelection}
                      MultiSelect={false}/>
                  </Box>
                </div>
              )
            })}
          </Box>}
          <Box overflow="auto" sx={{ flexGrow: 2, padding: "0.5em"}} className={pageStyles.hiddenMobile}>
            {/*<MapWrapper*/}
            {/*  ref={mapChild}*/}
            {/*  MarkerPoints={siteLatLongInfo}*/}
            {/*  HandleIconClick={handleMapIconClick}*/}
            {/*  SelectedSite={props.CurrentSiteName}*/}
            {/*  Center={mapCenter}*/}
            {/*/>*/}
            <MapWrapper
              ref={mapChild}
              markerPoints={siteLatLongInfo}
              handleIconClick={handleMapIconClick}
              selectedSites={[props.CurrentSiteName]}
              // center={mapCenter}
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%", marginTop: "1em", marginBottom: "2px"}}>
          <Typography variant="body1">
            Site Selected: {props.CurrentSiteName || 'None'}
          </Typography>
        </Box>
      </div>
    </Box>
  )
}

SitePicker2.propTypes = {
  Title: PropTypes.string,
  HandleSiteSelection: PropTypes.func,
  CurrentSite: PropTypes.number,
  CurrentSiteName: PropTypes.string,
  CurrentState: PropTypes.string,
  Sites: PropTypes.array,
};

SitePicker2.defaultProps = {
  Title: 'Site Selection',
  HandleSiteSelection: () => {},
  CurrentSite: 0,
  CurrentSiteName: '',
  CurrentState: '',
  Sites: [],
}

export default SitePicker2;

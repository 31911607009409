import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {anycaseEquals, makeAColour} from "../functions";
import TableCell from "@mui/material/TableCell";
import {selectCrops} from "../features/rotationSlice";

const CropTableCell = (props) => {
  const crops = useSelector(selectCrops);

  const cellKey = (`rw${props.TableRowIndex}_` +
                  `c${props.CropName}_cl${props.TableColumnIndex}`);

  let cropName = null;

  const cellStyle = {
    padding: "0px 3px",
    margin: 0,
    width: props.Width,
  };

  if (crops) {
    const cropObj = crops.find(c => anycaseEquals(c.Name, props.CropName) || anycaseEquals(c.Abbreviation, props.CropName));

    if (cropObj) {
      cellStyle.backgroundColor = makeAColour(cropObj.bg_colour);
      cellStyle.color = makeAColour(cropObj.fg_colour);

      if (props.RightBorder) {
        cellStyle.borderRightWidth = 1;
        cellStyle.borderRightStyle = "solid";
        cellStyle.borderRightColor = "#000000";
      }

      if (props.LeftBorder) {
        cellStyle.borderLeftWidth = 1;
        cellStyle.borderLeftStyle = "solid";
        cellStyle.borderLeftColor = "#000000";
      }

      cropName = cropObj.Name;
    }
  }

  if (cropName) {
    return (
      <TableCell key={cellKey} align="center" style={cellStyle}>
        {cropName}
      </TableCell>
    );
  } else {
    return (<TableCell key={cellKey} align="center" style={cellStyle}></TableCell>)
  }

};

CropTableCell.propTypes = {
  CropName: PropTypes.string.isRequired,
  TableRowIndex: PropTypes.number.isRequired,
  TableColumnIndex: PropTypes.number.isRequired,
  RightBorder: PropTypes.bool,
  LeftBorder: PropTypes.bool,
  Width: PropTypes.number,
}

CropTableCell.defaultProps = {
  RightBorder: false,
  LeftBorder: false,
  Width: 100,
}

export default CropTableCell;

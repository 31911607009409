import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, Typography} from "@mui/material";
import ResultIndicatorModal from "../ra_components/ResultIndicatorModal";
import styles from "../ra_components/sharedPageStyles.module.scss";
import InfoButton from "../ra_components/InfoButton";
import BWParallelChart from "../ra_components/BWParallelChart";
import ScatterChart from "../ra_components/ScatterChart";
import CDFChart from "../ra_components/CDFChart";
import LineChart from "../ra_components/LineChart";
import {useDispatch, useSelector} from "react-redux";
import {
  selectCrops,
  selectResultIndicators,
  updateSectionCIndicator} from "../features/rotationSlice";
import {
  selectRotationLevelError,
  selectRotationLevelStatus,
  selectRotationLevelData} from "../features/resultsSlice";

const CompareSectionC = (props) => {
  const effectDone = useRef(false);
  const dispatch = useDispatch();


  const yearlyResults = [];

  const seasonalConditions = [];

  const resultsData = useSelector(selectRotationLevelData);
  const resultsStatus = useSelector(selectRotationLevelStatus);
  const resultsError = useSelector(selectRotationLevelError);

  const crops = useSelector(selectCrops);
  const allIndicators = useSelector(selectResultIndicators);
  const indicatorObj = {};

  // Data required for ParallelChart Component.
  const [parallelSummaryData, setParallelSummaryData] = useState({});

  const selectableIndicators = useMemo(() => {
    return allIndicators.filter(r => r.selectable);
  }, [allIndicators]);

  const parallelIndicators = useMemo(() => {
    const filteredIndicators = allIndicators.filter(r => r.spider);
    filteredIndicators.sort((a, b) => a.spiderSort - b.spiderSort);

    return filteredIndicators;
  }, [allIndicators]);


  useEffect(() => {
    if (effectDone.current === true) {
    }

    return () => {
      effectDone.current = true;
    }
  }, []);

  const handleIndicatorChange = (index, indicatorValues) => {
    // Index is ignore in this instance.
    // console.log('SectionC indicator change', indicatorValues);
    if (indicatorValues) {
      if (indicatorValues.length > 0) {
        dispatch(updateSectionCIndicator(indicatorValues));
      }
    }
  };

  let currentUnits = '';
  if (indicatorObj) {
    currentUnits = indicatorObj.units;
  }

  let currentResultIndicator = '';
  if (indicatorObj) {
    currentResultIndicator = indicatorObj.value;
  }

  let currentResultIndicatorDesc = '';
  if (indicatorObj) {
    currentResultIndicatorDesc = indicatorObj.display;
    if (indicatorObj.details !== '') {
      currentResultIndicatorDesc += ` (${indicatorObj.details})`;
    }
  }

  return (
    <div>
      <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
          <div>
            <ResultIndicatorModal
              CurrentIndicator={currentResultIndicator}
              CurrentIndicatorDesc={currentResultIndicatorDesc}
              SelectableIndicators={selectableIndicators}
              OnResultIndicatorChange={handleIndicatorChange} />
          </div>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div id="linechartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer">
                <LineChart
                  ResultsData={yearlyResults}
                  ResultIndicator={currentResultIndicator}
                  ResultIndicatorDesc={currentResultIndicatorDesc}
                  SeasonalConditions={seasonalConditions}
                  Crops={crops}
                  Units={currentUnits}
                />
              </InfoButton>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div id="cdfchartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_cdf_chart" Position='auto' AnchorTo="cdfchartcontainer">
                <CDFChart
                  ResultsData={yearlyResults}
                  ResultIndicator={currentResultIndicator}
                  ResultIndicatorDesc={currentResultIndicatorDesc}
                  SeasonalConditions={seasonalConditions}
                  Crops={crops}
                  Units={currentUnits}
                />
              </InfoButton>
            </div>
          </Grid>
          <Grid item xs={8}>
            {resultsData && <div id="rl-paraxis" className={styles.graphPadded}>
              <InfoButton InfoKey="rl_par_axis" Position='auto' AnchorTo="rl-paraxis">
                <BWParallelChart
                  SummaryData={resultsData}
                  Indicators={parallelIndicators}
                  CurrentIndicator={currentResultIndicator}
                  Explanation=''
                  InfoKey=''
                />
              </InfoButton>
            </div> }
          </Grid>
          <Grid item xs={4}>
            {resultsData && <div id="rl-scatter" className={styles.graphPadded}>
              <InfoButton InfoKey="rl_risk_return" Position='auto' AnchorTo="rl-scatter">
                <ScatterChart ResultsData={resultsData} />
              </InfoButton>
            </div> }
          </Grid>
          <Grid item xs={12}>
            {resultsData && <div id="rl-table" className={styles.graphPadded}>
              <InfoButton InfoKey="rl_bw_table" Position="auto" AnchorTo="rl-table">
                <div>Table Goes Here</div>
              </InfoButton>
            </div> }

            <Typography fontStyle="italic" >
              Note: At the selected location, there may be various, different rotations that provide the best (or worst)
              performance for a certain indicator. In those cases, only one of these best (or worst) performing crop
              rotations is exemplarily listed here.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default CompareSectionC;

import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

import whiteMarkerIcon from '../images/plant_wh.png'
import greenMarkerIcon from '../images/plant_gr.png'
import styles from "./MapWrapper.module.scss";

const siteMarkerStyle = {
  position: 'absolute',
  width: 20,
  height: 20,
  left: -20 / 2,
  top: -20 / 2,
  zIndex: 0,
};

const SiteMarker = (props) => {
  if (props.selected) {
    siteMarkerStyle.zIndex = 1;
  }

  return (
    <div className={siteMarkerStyle}>
      <span className={styles.circleText} title={props.site}>
        <img src={props.selected ? greenMarkerIcon : whiteMarkerIcon}/>
      </span>
    </div>
  );
};

SiteMarker.propTypes = {
  site: PropTypes.string,
  selected: PropTypes.bool,
}

export default SiteMarker;

import {configureStore} from "@reduxjs/toolkit";
import sitesReducer from '../features/sitesSlice';
import sowingRulesReducer from '../features/sowingRulesSlice';
import pricesReducer from '../features/pricesSlice';
import rotationReducer from '../features/rotationSlice';
import infoItemsReducer from "../features/infoDataSlice";
import resultsReducer from "../features/resultsSlice";
import croparmReducer from "../features/croparmSlice";

export const store = configureStore({
  reducer: {
    prices: pricesReducer,
    rotation: rotationReducer,
    infoItems: infoItemsReducer,
    sites: sitesReducer,
    sowingRules: sowingRulesReducer,
    results: resultsReducer,
    croparm: croparmReducer,
  },
});

import { useEffect, useState } from "react";

const windowObj = (width, height) => {
  const obj = {
    width: width,
    height: height,
  };

  return obj;
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(windowObj(window.innerHeight, window.innerWidth));

  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize(windowObj(window.innerWidth, window.innerHeight));
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;

import React, { Component } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

class CopyrightFooter extends Component {
    render() {
        const currentYear = new Date().getFullYear();

        return (
            <Container maxWidth="lg">
                <Box sx={{
                    bgcolor: '#333333',
                    color: 'white',
                    padding: '0.5em',
                    borderRadius: "10px",
                    lineHeight: "2em"}}>
                    <Typography align="center" variant="body2">
                        © The State of Queensland (Department of Agriculture and Fisheries) 2010–{currentYear}.
                        <br/>
                        Queensland Government
                    </Typography>
                </Box>
            </Container>
        )
    }
}

export default CopyrightFooter;

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axiosClient from "../data/axiosClient";

const initialState = {
  sites: [],
  siteId: null,
  name: null,
  soil_type: null,
  pawc: null,
  status: 'idle',
  error: null,
  commodities: [],
  crop: null,
  season: null,
}

export const fetchCropARMSites = createAsyncThunk('croparm/fetchCropARMSites', async (arg, {getState}) => {
  try {
    const url = `/sites`;
    const response = await axiosClient.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchAllCommodities = createAsyncThunk('croparm/fetchCommodities', async (arg, {getState}) => {
  try {
    const url = `/commodity?crop=*`;
    const response = await axiosClient.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const croparmSlice = createSlice({
  name: 'croparm',
  initialState,
  reducers: {
    updateSite: (state, action) => {
      // Payload is expected to be a site id.
      const {siteId, name} = action.payload;

      const selSite = state.sites.find(s => (s.id === siteId || s.Name === name));
      if (selSite) {
        state.siteId = selSite.id;
        state.name = selSite.Name;
      }
    },
    updateSoilType: (state, action) => {
      // Payload is expected to be a soil type (string).
      const soiltype = action.payload;
      state.soil_type = soiltype;
    },
    updatePAWC: (state, action) => {
      // Payload is expected to be a PAWC value (number).
      const pawcValue = action.payload;
      state.pawc = pawcValue;
    },
    updateCrop: (state, action) => {
      // Payload is expected to be a PAWC value (number).
      const cropValue = action.payload;
      state.crop = cropValue;
    },
    updateSeason: (state, action) => {
      // Payload is expected to be a PAWC value (number).
      const cropSeason = action.payload;
      state.season = cropSeason;
    },
    clearCurrentSite: (state, action) => {
      state.name = null;
      state.siteId = null;
      state.soil_type = null;
      state.pawc = null;
      state.crop = null;
    },
    clearSoilType: (state, action) => {
      state.soil_type = null;
    },
    clearPAWC: (state, action) => {
      state.pawc = null;
    },
    clearCrop: (state, action) => {
      state.crop = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCropARMSites.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchCropARMSites.fulfilled, (state, action) => {
        state.status = 'success';
        state.sites = action.payload.sites;
      })
      .addCase(fetchCropARMSites.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchAllCommodities.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchAllCommodities.fulfilled, (state, action) => {
        state.status = 'success';
        state.commodities = action.payload.commodityData;
      })
      .addCase(fetchAllCommodities.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export const {updateSite, updateSoilType, updatePAWC, clearPAWC, clearSoilType, clearCurrentSite} = croparmSlice.actions;
export const {updateCrop, clearCrop} = croparmSlice.actions;

export const selectSites = (state) => state.croparm.sites;
export const selectSite = (state) => state.croparm.siteId;
export const selectCrop = (state) => state.croparm.crop;
export const selectSeason = (state) => state.croparm.season;
export const selectSiteName = (state) => state.croparm.name;
export const selectPAWC = (state) => state.croparm.pawc;
export const selectSoilType = (state) => state.croparm.soil_type;
export const selectSiteById = (state, siteId) => {
  return state.croparm.sites.find(s => s.id === siteId);
};
export const selectCommodities = (state) => state.croparm.commodities;
export const selectCropCommodity = (state) => {
  let thisCommodity = state.croparm.commodities.find(c => c.crop === state.croparm.crop);
  if (!thisCommodity) {
    thisCommodity = {
      crop: state.croparm.crop,
      fallow_costs: 0,
      seed_costs: 0,
      n_costs: 0 ,
      irrigation_costs: 0,
      other_costs: 0,
      price: 0,
    };
    return thisCommodity;
  } else {
    return thisCommodity;
  }
};
export const getSitesStatus = (state) => state.croparm.status;
export const getSitesError = (state) => state.croparm.error;

export default croparmSlice.reducer;

import axiosFallow from "../axiosFallow";

class dataHelper {
  scenarioOptionsFromAPI(site) {
    return axiosFallow.get('/scenarioOptions', {
      params: {
        site: site,
      }
    });
  }

}

const FAScenarioOptionsDataHelper = new dataHelper();

export default FAScenarioOptionsDataHelper;


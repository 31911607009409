import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axiosRotation from "../ra_data/axiosRotation";

const initialState = {
  prices: [],
  fertilisers: [],
  agMarginRegion: '',
  status: 'idle',   // 'idle' | 'loading' | 'success' | 'failed'
  error: null,
};

export const fetchPrices = createAsyncThunk('prices/fetchPrices', async (arg, {getState}) => {
  try {
    const reduxState = getState();
    const sitesState = reduxState.sites;
    const siteName = sitesState.name;
    const url = `/commodity?site=${siteName.toLowerCase()}`;
    const response = await axiosRotation.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    updateCommodity: (state, action) => {
      const {crop, price, otherCosts} = action.payload;

      const newArray = state.prices.map((p) => {
        const px = {...p};
        if (p.crop === crop) {
          if (price) {
            px.userPrice = price;
          }
          if (otherCosts) {
            px.userOtherCosts = otherCosts;
          }
        }
        return px;
      });

      // Modify the item with the new information.
      state.prices = [...newArray];
    },
    updateFertiliser: (state, action) => {
      const {name, cost} = action.payload;

      const newArray = state.fertilisers.map((f) => {
        const fx = {...f};
        if (f.name === name) {
          fx.userCost = cost;
        }
        return fx;
      });

      // Modify the item with the new information.
      state.fertilisers = [...newArray];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPrices.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchPrices.fulfilled, (state, action) => {
        state.status = 'success';

        const {siteData} = action.payload;
        state.agMarginRegion = siteData.agMarginRegion;

        const loadedCommodities = action.payload.commodityData.map(commodity => {
          const commodityx = {...commodity}
          // Add the 2 user defined fields and drop the 2 fertiliser fields
          delete commodityx.urea;
          delete commodityx.granulock;
          commodityx.userPrice = commodity.price;
          commodityx.userOtherCosts = commodity.otherCosts;

          return commodityx;
        });

        state.prices = [...loadedCommodities];

        // Update the fertiliser values from the first commodity array item
        const newFerts = [];
        const firstRecord = action.payload.commodityData[0];
        newFerts.push({name: 'urea', cost: firstRecord.urea, userCost: firstRecord.urea});
        newFerts.push({name: 'granulock', cost: firstRecord.granulock, userCost: firstRecord.granulock});

        state.fertilisers = [...newFerts];
      })
      .addCase(fetchPrices.rejected, (state, action) => {
        console.log('Prices error');
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export const {updateCommodity, updateFertiliser} = pricesSlice.actions;

export const selectPrices = (state) => state.prices.prices;
export const selectFertilisers = (state) => state.prices.fertilisers;
export const getPricesStatus = (state) => state.prices.status;
export const getPricesError = (state) => state.prices.error;

export default pricesSlice.reducer;

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {updateCommodity} from "../features/pricesSlice";
import {Typography, Box, Tooltip, TextField} from "@mui/material";
import ColouredLabel from "./ColouredLabel";
import styles from '../ra_containers/PriceStep.module.scss';

const CommodityPrices = (props) => {
  const dispatch = useDispatch();
  const effectDone = useRef(false);
  const [commodities, setCommodities] = useState([]);

  useEffect(() => {
    if (effectDone.current === false) {
      if (props.Commodities) {
        setCommodities([...props.Commodities]);
      }
    }

    return () => {
      effectDone.current = true;
    }

  }, [props.Commodities]);

  const handlePriceChange = (event, crop) => {
    const newValue = Number(event.target.value);

    //region Update the store
    const update = {
      crop,
      otherCosts: null,
      price: newValue,
    };

    dispatch(updateCommodity(update));
    //endregion

    const newCommods = commodities.map((comm) => {
      if (comm.crop === crop) {
        return {...comm, userPrice: newValue};
      }
      return comm;
    });

    setCommodities(newCommods);

    // props.UpdateCommodities(newCommods);
  };

  const handleOtherCostsChange = (event, crop) => {
    const newValue = Number(event.target.value);

    //region Update the store
    const update = {
      crop,
      otherCosts: newValue,
      price: null,
    };

    dispatch(updateCommodity(update));
    //endregion

    const newCommods = commodities.map((comm) => {
      if (comm.crop === crop) {
        return {...comm, userPrice: newValue};
      }
      return comm;
    });

    setCommodities(newCommods);

    // props.UpdateCommodities(newCommods);
  };

  const onRowClick = (rowkey) => {
    // console.log('Row Clicked:', rowkey);
  };

  const renderRow = (commodityObj) => {
    const {crop} = commodityObj;
    const {price, userPrice} = commodityObj;
    const {otherCosts, userOtherCosts} = commodityObj;

    // Only display user fields when they are different to the defaults.
    let renderUserPrice = userPrice.toString();
    if (userPrice === price) {
      renderUserPrice = '';
    }

    let renderUserCosts = userOtherCosts.toString();
    if (userOtherCosts === otherCosts) {
      renderUserCosts = '';
    }

    const rowKey = `R_${crop}`.replace(' ', '_');
    const idPrice = rowKey + '_price';
    const idCost = rowKey + '_cost';

    return (
      <tr key={rowKey} onClick={() => onRowClick(rowKey)}>
        <td style={{height: 0, width: '45%'}}>
          <div style={{height: '100%'}}>
            <ColouredLabel Name={crop} />
          </div>
        </td>
        <td align="right" style={{paddingRight: '10px'}}>
          {price.toString()}
        </td>
        <td align="right" style={{paddingRight: '10px'}}>
          <TextField
            id={idPrice}
            type="number"
            inputProps={{min: 0, style: { textAlign: 'center' }}}
            variant="standard"
            defaultValue={renderUserPrice}
            onChange={event => handlePriceChange(event, crop)}
          />
        </td>
        <td align="right" style={{paddingRight: '10px'}}>
          {otherCosts.toString()}
        </td>
        <td align="right" style={{paddingRight: '10px'}}>
          <TextField
            id={idCost}
            type="number"
            inputProps={{min: 0, style: { textAlign: 'center' }}}
            variant="standard"
            defaultValue={renderUserCosts}
            onChange={event => handleOtherCostsChange(event, crop)}
          />
        </td>
      </tr>
    );
  };

  return (
    <div>
          <table aria-label="commodity list">
            <thead>
              <tr key="header1" >
                <th align="center"> </th>
                <th colSpan="2" align="center">
                  <Typography component="div">
                    <Box sx={{ fontWeight: 'bold', m: 1 }}>Commodity Price</Box>
                  </Typography>
                </th>
                <th colSpan="2" align="center">
                  <Typography component="div">
                    <Box sx={{ fontWeight: 'bold', m: 1 }}>Other Variable Costs</Box>
                  </Typography>
               </th>
              </tr>
              <tr sx={{maxHeight: 15}} key="header2" >
                <th> </th>
                <th colSpan="2" align="center"> </th>
                <th colSpan="2" align="center">(excluding Nitrogen fertiliser)</th>
              </tr>
              <tr key="header3" >
                <th align="center"></th>
                <th colSpan="2" align="center">
                  <Typography component="div">
                    <Tooltip title="Australian Dollars per tonne">
                      <Box sx={{ fontWeight: 'bold', m: 1 }}>(AUD/t)</Box>
                    </Tooltip>
                  </Typography>
                </th>
                <th colSpan="2" align="center">
                  <Typography component="div">
                    <Tooltip title="Australian Dollars per hectare">
                      <Box sx={{ fontWeight: 'bold', m: 1 }}>(AUD/ha)</Box>
                    </Tooltip>
                  </Typography>
                </th>
              </tr>
              <tr key="header4" >
                <th align="center">Crop</th>
                <th align="center">Default</th>
                <th align="center">User Defined</th>
                <th align="center">Default</th>
                <th align="center">User Defined</th>
              </tr>
            </thead>
            <tbody>
              {commodities.map((row, rowIndex) => {
                {return renderRow(row)}
              })}
            </tbody>
          </table>
    </div>
  );
}

CommodityPrices.propTypes = {
  Commodities: PropTypes.arrayOf(PropTypes.object).isRequired,
  UpdateCommodities: PropTypes.func,
}


CommodityPrices.defaultProps = {
  UpdateCommodities: () => {},
}

export default CommodityPrices;

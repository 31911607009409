import React, {useEffect, useRef, useState} from 'react';
import pageStyles from './RAMainPage.module.scss';
import RotationStepper from "./RotationStepper";
import ToolDescription from "../ra_components/ToolDescription";
import {Button, Typography} from '@mui/material';
import Analysis from "./Analysis";

const RAMainPage = (props) => {
  const effectDone = useRef(false);
  const [hideDescription, setHideDescription] = useState(false);
  const [hideStepper, setHideStepper] = useState(true);
  const [haveSubmitted, setHaveSubmitted] = useState(false);
  const [mainTitle, setMainTitle] = useState('RotationARM - Agricultural Risk Management of Crop Rotations');

  useEffect(() => {
    if (effectDone.current === true) {

    }

    return () => {
      effectDone.current = true;
    }
  }, []);

  const handleGetStarted = (event) => {
    setHideDescription(true);
    setHideStepper(false);
  };

  const handleSubmit = () => {
    // We will leave the stepper and start showing results.
    setMainTitle('RotationARM - Agricultural Risk Management of Crop Rotations - Analysis');
    setHaveSubmitted(true);
    setHideStepper(true);
  };

  const handleAnalysisStepChange = (newStepTitle) => {
    const newTitle = `RotationARM - ${newStepTitle}`;
    setMainTitle(newTitle);
  };

  return (
    // <Container maxWidth="lg">
    <div className={pageStyles.container}>
      <div style={{ width: '100%' }}>
        <div className={pageStyles.descriptionArea}>
          <div className={pageStyles.headerSection}>
            <Typography variant="h4">
              {mainTitle}
            </Typography>
          </div>
          <div hidden={hideDescription}>
            <ToolDescription />
          </div>
          <div hidden={hideStepper}>
            <RotationStepper OnSubmit={handleSubmit} />
          </div>
          {haveSubmitted && <Analysis OnStepTitleChange={handleAnalysisStepChange} />}
          <div className={pageStyles.buttonContainer} hidden={hideDescription}>
            <Button variant="contained" size="large" onClick={() => handleGetStarted()}>Get Started</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RAMainPage;

import React from 'react';
import PropTypes from 'prop-types';
import HomeAppRow from "../components/HomeAppRow";
import styles from "./ARMHome.module.scss";

const ARMHome = (props) => {
  return (
    <div className={styles.container}>
      {props.Apps.map(app => {
        if (app.image) {
          return <HomeAppRow ARMApp={app} />
        }
      })}
    </div>
  );
};

ARMHome.propTypes = {
  Apps: PropTypes.array.isRequired,
}

export default ARMHome;

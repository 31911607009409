import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axiosRotation from "../ra_data/axiosRotation";

const initialState = {
  sowingRules: [],
  sowingRule: 0,   // currently selected sowing rule
  status: 'idle',
  error: null,
}

export const fetchSowingRules = createAsyncThunk('sowingRules/fetchSowingRules', async (arg, {getState}) => {
  try {
    const url = `/sowingrules`;
    const response = await axiosRotation.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const sowingRulesSlice = createSlice({
  name: 'sowingRules',
  initialState,
  reducers: {
    updateSowingRule: (state, action) => {
      // Payload is expected to be a sowing rule string.
      state.sowingRule = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSowingRules.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchSowingRules.fulfilled, (state, action) => {
        state.status = 'success';

        const sowingRules = action.payload.sowingRules;
        state.sowingRules = sowingRules;
      })
      .addCase(fetchSowingRules.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export const {updateSowingRule} = sowingRulesSlice.actions;

export const selectSowingRules = (state) => state.sowingRules.sowingRules;
export const selectSowingRule = (state) => state.sowingRules.sowingRule;
export const getSowingRulesStatus = (state) => state.sowingRules.status;
export const getSowingRulesError = (state) => state.sowingRules.error;

export const selectSowingRuleObj = (state) => {
  if (state.sowingRules.sowingRules) {
    if (state.sowingRules.sowingRule !== null) {
      const sowingRuleObj = state.sowingRules.sowingRules.find(
        sr => sr.id === state.sowingRules.sowingRule
      );
      if (sowingRuleObj) {
        return sowingRuleObj;
      }
    }
  }

  return null;
};

export default sowingRulesSlice.reducer;

import React from 'react';
import PropTypes from 'prop-types';
import cropHelper from "../ra_Helpers/cropHelper";

const ColouredLabel = (props) => {
  const lcName = props.Name.toLowerCase().trim();

  const crop = cropHelper.getCrop(props.Name);
  if (!crop) {
    return null;
  }

  const cropStyle = {
    display: 'block',
    height: "100%",
    lineHeight: "100%",
    backgroundColor: crop.bg_colour,
    color: crop.fg_colour,
    textAlign: 'center',
    margin: 'auto',
    paddingTop: '5px',
  };

  const textStyle = {
    height: "100%",
    lineHeight: "100%",
    paddingTop: '5px',
  };

  return (
    <div style={{...cropStyle}}>
      <div style={{...textStyle}}>
        {lcName}
      </div>
    </div>
  );
}

ColouredLabel.propTypes = {
  Name: PropTypes.string.isRequired,
}

export default ColouredLabel;

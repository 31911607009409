import axiosFallow from "../axiosFallow";

class dataHelper {
  constructor() {
    this.data = {};
  }

  siteOptionsFromAPI(site) {
    // Given this provides data to different components, we don't want
    // to call the API every time this function is called.
    // If we have cached data for a site, let's return that in a promise
    // and pretend it's an API call that's been successful.
    if (this.data.hasOwnProperty(site)) {
      return new Promise((resolve, reject) => {
        resolve(this.data[site]);
      });
    }
    var self = this;
    return axiosFallow.get('/siteOptions', {
      params: {
        site: site,
      }
    }).then(function(response) {
      // once we've called the API for this site, let's cache the results on this class
      self.data[site] = response;
      return response;
    });
  }
}

export function looksLikeADate(possibleDate) {
  // Check if string is like 15-dec.
  // Note: does not check of invalid dates like 30-feb
  let result = false;

  const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

  if (possibleDate.toString().includes('-')) {
    const parts = possibleDate.toLowerCase().split('-');
    if (months.indexOf(parts[1]) !== -1) {
      if (isValidNumber(parts[0], 1, 31)) {
        result = true;
      }
    }
  }

  return result;
}

export function sortedLikeDates(arrayOfLikeDates) {
  const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

  const monthPart = (month) => {
    const parts = month.toLowerCase().split('-');
    return {
      name: parts[1],
      index: months.indexOf(parts[1]) + 1,
    };
  };

  const wrapGap = (arrayOfDates) => {
    let firstGap = 0;
    //region check for year wrap.
    // also find first month NOT in the array.
    const joinedDates = arrayOfDates.join('').toLowerCase();
    if (joinedDates.includes('jan') && joinedDates.includes('dec')) {
      // Dates wrap around end of year. So months Jan onwards will use year + 1

      // Find the first month not appearing in the array.
      for(let i = 0; i < months.length; i++) {
        if (!joinedDates.includes(months[i])) {
          firstGap = i + 1;
          break;
        }
      }
    }

    return firstGap;
  };

  if (Array.isArray(arrayOfLikeDates)) {
    if (allDates(arrayOfLikeDates)) {
      const d = new Date();
      const year = d.getFullYear();
      const work = [];

      let firstGap = wrapGap(arrayOfLikeDates);

      //region Build the working array of dates.
      arrayOfLikeDates.forEach( d => {
        const {name, index} = monthPart(d);

        let xDate = d + "-" + year.toString(10);
        if (firstGap > 0) {
          // Wrapping dates
          if (index >= 1 && index < firstGap) {
            xDate = d + "-" + (year + 1).toString(10);
          } else {
            xDate = d + "-" + year.toString(10);
          }
        }

        const dateObj = new Date(xDate);
        let ye = dateObj.getFullYear().toString();
        let mo = (dateObj.getMonth() + 1).toString().padStart(2, '0')
        let da = dateObj.getDate().toString().padStart(2, '0')
        const sortDate = `${ye}-${mo}-${da}`;

        const srtDate = {
          original: d,
          sortDate,
        }

        work.push(srtDate);
      });
      //endregion

      //region  Now sort work array
      const dateCompare = ( a, b ) => {
        if ( a.sortDate < b.sortDate ){
          return -1;
        }
        if ( a.sortDate > b.sortDate ){
          return 1;
        }
        return 0;
      };

      work.sort( dateCompare );
      //endregion

      return work.map(x => x.original);
    }
  }
}

export function allDates(arrayOfPossibleDates) {
  let result = false;

  if (Array.isArray(arrayOfPossibleDates)) {
    let foundDates = 0;
    arrayOfPossibleDates.forEach(pd => {
      if (looksLikeADate(pd)) {
        foundDates += 1;
      }
    });

    if (foundDates === arrayOfPossibleDates.length) {
      result = true;
    }
  }

  return result;
}

export function allNumeric(arrayOfPossibleNumbers) {
  let result = false;

  if (Array.isArray(arrayOfPossibleNumbers)) {
    let foundNum = 0;
    arrayOfPossibleNumbers.forEach(pn => {
      if (isNumeric(pn)) {
        foundNum += 1;
      }
    });

    if (foundNum === arrayOfPossibleNumbers.length) {
      result = true;
    }
  }

  return result;
}

export function isNumeric(num) {
  return (typeof(num) === 'number' || (typeof(num) === "string" && num.trim() !== '')) && !isNaN(num);
}

export function isValidNumber(possibleNum, lowValue = null, highValue = null) {
  let result = false;

  if (isNumeric(possibleNum)) {
    result = true;
    const nowValue = Number(possibleNum);
    if (lowValue && isNumeric(lowValue) && nowValue < Number(lowValue)) {
      result = false;
    }
    if (highValue && isNumeric(highValue) && nowValue > Number(highValue)) {
      result = false;
    }
  }

  return result;
}

const FASiteOptionsDataHelper = new dataHelper();

export default FASiteOptionsDataHelper;

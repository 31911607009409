import React from 'react';
import pageStyles from "../ra_containers/RAMainPage.module.scss";
import {Typography} from "@mui/material";

const ToolDescription = (props) => {
  return (
    <div>
      <div className={pageStyles.descriptionArea}>
        <Typography variant="h5" color="common.error" fontWeight="bold" color="red" >
          This tool is in a development/experimental stage. <br/>
          You may encounter incorrect results, bugs or application crashes.<br/>
          The data behind this tool is likely to change significantly during its development.
        </Typography>
      </div>
      <Typography variant="body1" fontWeight="bold" sx={{textDecoration: 'underline'}}  gutterBottom >
        Purpose
      </Typography>
      <Typography variant="body1" gutterBottom>
        The RotationARM tool assists growers in their decision about which crop rotation
        is most suitable to their circumstances. It guides farmers to compare the
        performance of their current crop rotation to potential alternatives. The tool
        simulates crop rotations across 60 years of climate records. It allows the comparison
        of crop rotation options regarding various performance indicators, such as crop yield
        and gross margins as well as further multi-dimensional criteria.
      </Typography>
      <Typography variant="body1" gutterBottom>
        RotationARM does not answer which crop rotation is best for your given
        circumstance. Instead, it can provide you with suggestions about different
        options for improving your rotations, while highlighting missed opportunities,
        but also potential risks. RotationARM can best be used pre-season to assist
        your own reflection about which in-season crop mix and multi-season crop rotation
        you want to cultivate.
      </Typography>
      <Typography variant="body1" gutterBottom>
        RotationARM uses high-quality meteorological and soil data and the cropping systems
        model Agricultural Production Systems sIMulator (APSIM). However, the specific
        agro-ecological and socio-economic circumstances of your farm may differ from the
        data used in the tool. For this reason, the results from RotationARM should only be
        used as one useful piece of information. Major on-farm decisions should be based on
        your own long-term expert knowledge of your farm enterprise in consultation with
        your agronomist.
      </Typography>
      {/* Material UI does have a <List> element, but that's a bit overkill for the below. */}
      <div>
        For further information:
        <ul className={pageStyles.itemList} >
          <li>RotationARM tutorial</li>
          <li>RotationARM methodology</li>
          <li>RotationARM project information</li>
        </ul>
      </div>
    </div>
  );
}

export default ToolDescription;

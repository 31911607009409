import axiosClient from "./axiosClient";

class dataHelper {
    constructor() {
        this.data = {
            'soiOptions': {},
            'droughtOptions': {},
            'droughtData': {},
            'crops': {},
            // Local Drought data cache
            'localDroughtData': {},
            'ldSiteId': 0,
            'ldSeason': '',
        };
        this.monthLabelMapping = {
            "jan-feb": "January-Febuary",
            "feb-mar": "Febuary-March",
            "mar-apr": "March-April",
            "apr-may": "April-May",
            "may-jun": "May-June",
            "jun-jul": "June-July",
            "jul-aug": "July-August",
            "aug-sep": "August-September",
            "sep-oct": "September-October",
            "oct-nov": "October-November",
            "nov-dec": "November-December",
            "dec-jan": "December-January",
        };
        this.phaseLabelMapping = {
            1: "Consistently Negative (Phase 1)",
            2: "Consistently Positive (Phase 2)",
            3: "Rapidly Falling (Phase 3)",
            4: "Rapidly Rising (Phase 4)",
            5: "Consistently Near Zero (Phase 5)",
        };
        // This array is used to configure the dropdown for the user to
        // select which data they wish to view.
        this.dbMapping = [
            // FallowARM fields.
            {field: "OC", description: "Organic carbon", units: "(kg/ha)"},
            {field: "surfaceOM", description: "Organic carbon", units: "(kg/ha)"},
            {field: "monthAfterStart", description: "Month after start", units: ""},
            {field: "PAW", description: "Plant available water", units: "(mm)"},
            {field: "nh4", description: "NH4", units: "(kg/ha)"},
            {field: "no3", description: "NO3", units: "(kg/ha)"},
            {field: "inorganicN", description: "Total mineral nitrogen", units: "(kg/ha)"},
            {field: "drain", description: "Drainage", units: "(mm)"},
            {field: "runoff", description: "Runoff", units: "(mm)"},
            {field: "no3Leach", description: "Leached NO3", units: "(kg/ha)"},
            {field: "effRain", description: "Effective rainfall", units:"(mm)"},
            {field: "WeedWaterUptake", description: "Weed water uptake", units: "(mm)"},
            // CropARM fields.
            {field: "dryYield", description: "Dry grain  yield", units: "(t/ha)"},
            {field: "wetYield", description: "Wet grain yield", units: "(t/ha)"},
            {field: "protein", description: "Grain protein", units: "(%)"},
            {field: "biomass", description: "Biomass at harvest", units: "(t/ha)"},
            {field: "daysHarvest", description: "Days to harvest", units: ""},
            {field: "mineralN", description: "Mineral N ad harvest", units: "(kg/ha)"},
            {field: "maxT4Sow", description: "Av MAX temp 4 weeks post sowing", units: "(ºC)"},
            {field: "minT4Sow", description: "Av MIN temp 4 weeks post sowing", units: "(ºC)"},
            {field: "maxT2Flower", description: "Av MAX temp 2 weeks pre and post flowering", units: "(ºC)"},
            {field: "minT2Flower", description: "Av MIN temp 2 weeks pre and post flowering", units: "(ºC)"},
            {field: "maxT4Harvest", description: "Av MAX temp 4 weeks pre harvest", units: "(ºC)"},
            {field: "minT4Harvest", description: "Av MIN temp 4 weeks pre harvest", units: "(ºC)"},
            {field: "minT", description: "In-crop MIN temp", units: "(ºC)"},
            {field: "daysFrost", description: "Days of frost stress 2 weeks pre and post flowering", units: ""},
            {field: "daysHeat", description: "Days of heat stress 2 weeks pre and post flowering", units: ""},
            {field: "waterUsed", description: "Water used", units: "(mm)"},
            {field: "irrigationWater", description: "Irrigation water applied", units: "(mm)"},
            {field: "WUEYield", description: "WUE yield", units: "(kg/mm)"},
            {field: "WUEBiomass", description: "WUE biomass", units: "(kg/mm)"},
            // Common to multiple tools or require a placeholder filled. See getOptionsList below.
            {field: "rain", description: "In-%t% rainfall", units: "(mm)"},
        ];
    }

    soiAnalysisOptions() {
        // we don't want to call the API every time this function is called
        // if we have cached data, let's return that in a promise
        // and pretend it's an API call that's been successful.
        if (this.data.soiOptions.hasOwnProperty('data')) {
            return new Promise((resolve, reject) => {
                resolve(this.data.soiOptions);
            });
        };
        var self = this;
        return axiosClient.get('/soiOptions', {})
            .then(function(response) {
                // if we've called the api already, let's cache the results on this class
                self.data.soiOptions = response;
                return response;
            });
    }

    getSOIMonthLabel(monthKey) {
        if (this.monthLabelMapping.hasOwnProperty(monthKey)) {
            return this.monthLabelMapping[monthKey];
        } else {
            console.info("Could not find a label mapping for " + monthKey.toString());
            return monthKey;
        }
    }

    getSOIPhaseLabel(phaseKey) {
        if (this.phaseLabelMapping.hasOwnProperty(phaseKey)) {
            return this.phaseLabelMapping[phaseKey];
        } else {
            console.info("Could not find a label mapping for " + phaseKey.toString());
            return phaseKey;
        }
    }

    droughtAnalysisOptions() {
        // we don't want to call the API every time this function is called
        // if we have cached data, let's return that in a promise
        // and pretend it's an API call that's been successful.
        if (this.data.droughtOptions.hasOwnProperty('data')) {
            return new Promise((resolve, reject) => {
                resolve(this.data.droughtOptions);
            });
        };
        var self = this;
        return axiosClient.get('/droughts', {})
            .then(function(response) {
                // if we've called the api already, let's cache the results on this class
                self.data.droughtOptions = response;
                return response;
            });
    }

    getCropSeasonList() {
        // we don't want to call the API every time this function is called
        // if we have cached data, let's return that in a promise
        // and pretend it's an API call that's been successful.
        if (this.data.crops.hasOwnProperty('data')) {
            return new Promise((resolve, reject) => {
                resolve(this.data.crops);
            });
        };
        var self = this;
        return axiosClient.get('/cropSeasons', {})
          .then(function(response) {
              // if we've called the api already, let's cache the results on this class
              self.data.crops = response;
              return response;
          });
    }

    getDroughtsList() {
        // we don't want to call the API every time this function is called
        // if we have cached data, let's return that in a promise
        // and pretend it's an API call that's been successful.
        if (this.data.droughtData.hasOwnProperty('data')) {
            return new Promise((resolve, reject) => {
                resolve(this.data.droughtData);
            });
        };
        var self = this;
        return axiosClient.get('/droughts', {})
            .then(function(response) {
                // if we've called the api already, let's cache the results on this class
                self.data.droughtData = response;
                return response;
            });
    }

    // THIS IS NO LONGER USED. LocalDroughts has been replaced by the Seasonal Conditions feature.
    getLocalDroughtsList(siteId, season) {
        // we don't want to call the API every time this function is called
        // if we have cached data, let's return that in a promise
        // and pretend it's an API call that's been successful.
        if (this.data.localDroughtData.hasOwnProperty('data')) {
            const {ldSiteId, ldSeason} = this.data;
            if (ldSiteId === siteId && ldSeason === season) {
                // OK to use cached data.
                return new Promise((resolve, reject) => {
                    resolve(this.data.localDroughtData);
                });
            }
        };
        var self = this;
        if (!siteId || !season) {
            // Error both are needed
        }
        const URL = `/localDroughts?siteId=${siteId}&season=${season}`;
        return axiosClient.get(URL, {})
          .then(function(response) {
              // if we've called the api already, let's cache the results on this class
              self.data.localDroughtData = response;
              self.data.ldSiteId = siteId;
              self.data.ldSeason = season
              return response;
          });
    }

    getCommodityValues(crop) {
        return axiosClient.get(`/commodity?crop=${crop}`, {})
          .then(function(response) {
              return response;
          });
    }

    getOptionsList(fieldArray, cropType = "") {
        let results = [];

        // Determine the value for %t% placeholder.
        let subsCrop = cropType.toLowerCase();
        if (cropType !== "") {
            subsCrop = "crop";
            if (cropType.toLowerCase() === "fallow") {
                subsCrop = "fallow";
            }
        }

        fieldArray.forEach(fa => {
            const lu = this.dbMapping.find(dbm => dbm.field.toLowerCase() === fa.toLowerCase());
            if (lu) {
                const desc = `${lu.description} ${lu.units}`.replace("%t%", subsCrop);
                results.push({field: fa, description: desc});
            }
        });

        return results;
    }

    getSeasonalConditionsYearsAsDroughts(yearData, selectedConditions) {
        // Receives:
        //  yearData = Water Balance data (Year, WBRatio) typically from the database and pre-sorted.
        //  selectedConditions = array of condition display values (see getSeasonalConditionsList() )
        // Returns:
        //  An array of years (formatted as a drought object) where seasonal conditions are met.

        const conditions = this.getSelectedSeasonalConditions(selectedConditions)
        // console.log('getSeasonalConditionsYearsAsDroughts', yearData);

        //region Which years have a WBRatio inside the limits array.
        const years = new Set();
        yearData.forEach(yd => {
            conditions.forEach(c => {
                if (yd.WBRatio > c.lower && yd.WBRatio <= c.upper) {
                    years.add(yd.Year);
                }
            });
        });
        const results = Array.from(years);
        results.sort();
        //endregion

        return this.convertYearsToDroughts(results);
    }

    convertYearsToDroughts(droughtData) {
        // Expecting droughtData to be an array of years.
        let droughts = [];
        let droughtStart = null;
        let droughtEnd = null;
        let lastYear = null;

        const formatDroughtName = (droughtStart, droughtEnd) => {
            if (droughtStart === droughtEnd) {
                return droughtStart.toString();
            }

            const endYear = droughtEnd.toString();
            return droughtStart.toString() + '-' + endYear.slice(-2);
        };

        if (droughtData.length > 0) {
            droughtData.forEach(dr => {
                if (droughtStart === null) {
                    // This is the first time through.
                    droughtStart = dr;
                    droughtEnd = dr;
                } else {
                    // At least 2nd iteration
                    if (lastYear + 1 !== dr) {
                        // More than 1 year from previous start year, so,
                        // the pervious drought had already ended.
                        const dName = formatDroughtName(droughtStart, droughtEnd);
                        droughts.push({
                            DroughtName: dName,
                            FriendlyName: dName,
                            StartYear: droughtStart,
                            EndYear: droughtEnd,
                        });

                        // Restart the drought.
                        droughtStart = dr;
                        droughtEnd = dr;
                    } else {
                        // The drought is continuing from last year.
                        droughtEnd = dr;
                    }
                }

                lastYear = dr;
            });

            // Post loop cleanup.
            const dName = formatDroughtName(droughtStart, droughtEnd);
            droughts.push({
                DroughtName: dName,
                FriendlyName: dName,
                StartYear: droughtStart,
                EndYear: droughtEnd,
            });
        }

        return droughts;
    }

    getSeasonalConditionsList() {
        // Returns an array that is compatible with the CheckboxList Component.
        // Also the API MUST be kept in line with this.
        // Because the testing for lower and upper limits, the values are set
        // beyond actual values retrieved.
        let results = [];

        results.push({display: 'Extremely Dry', detail: 'water balance ratio <= 0.15', lower: 0, upper: 0.15});
        results.push({display: 'Moderately Dry', detail: '0.15 < water balance ratio <= 0.3', lower: 0.15, upper: 0.3});
        results.push({display: 'Mildly Dry', detail: '0.3 < water balance ratio <= 0.45', lower: 0.3, upper: 0.45});
        results.push({display: 'Mildy Wet', detail: '0.45 < water balance ratio <= 0.6', lower: 0.45, upper: 0.6});
        results.push({display: 'Moderately Wet', detail: '0.6 < water balance ratio <= 0.75', lower: 0.6, upper: 0.75});
        results.push({display: 'Extremely Wet', detail: '0.75 < water balance ratio', lower: 0.75, upper: 5});

        return results;
    }

    getDefaultSeasonalConditions() {
        return ['Extremely Dry', 'Moderately Dry'];
    }

    getSelectedSeasonalConditions(selectedConditions) {
        const conditions = [];
        if (selectedConditions) {
            if (Array.isArray(selectedConditions)) {

                const fullList = this.getSeasonalConditionsList();
                selectedConditions.forEach(sc => {
                    const condition = fullList.find(c => c.display === sc);
                    if (condition) {
                        conditions.push(condition);
                    }
                });
            }
        }

        return conditions;
    }

    convertToFieldName(fieldDesc='') {
        let result = "";

        // Trim away any units.
        let trimmedDesc = fieldDesc.toLowerCase();
        const lastPos = fieldDesc.lastIndexOf("(");
        if (lastPos !== -1 ) {
            trimmedDesc = fieldDesc.toLowerCase().substring(0, lastPos);
        }

        const lu = this.dbMapping.find(dbm => dbm.description.toLowerCase() === trimmedDesc.trim());
        if (lu) {
            result = lu.field;
        }

        return result;
    }

}

const siteDataHelper = new dataHelper();

export default siteDataHelper;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, Button, FormGroup, MenuItem, FormControl, InputLabel, Select} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {Diversity2} from "@mui/icons-material";
import TriangularDialog from "./TriangularDistDialog";

import CheckboxGroup from '../components/CheckboxGroup';
// import ScenarioList from '../components/ScenarioList';
import ScenarioList2 from "../components/SectionList2";

import siteOptionsDataHelper, {
    allNumeric,
    allDates,
    sortedLikeDates,
    sortedNumerics
} from '../data/siteOptionsDataHelper';

import scenarioOptionsDataHelper from '../data/scenarioOptionsDataHelper';
import configHelper from '../data/configHelper';

import pageStyles from "./ScenarioSelectionStep.module.scss";
import sharedStyles from "./sharedPageStyles.module.scss";
import LoadingComponent from '../components/LoadingComponent';
import {anycaseEquals} from "../functions";

const implementTriangularDist = true;

class ScenarioSelectionStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            siteCropSelection: {},
            scenarioOptions: {},
            siteScenarioSelections: {},
            siteAvailableCrops: {}, // Crops before any filtering
            loadingState: 'loading',
            error: {},
            triDialogOpen: false,
            selectedSeason: '',
            crops: [],
        };

        this.props.sites.forEach((site) => {
            this.state.siteScenarioSelections[site] = {};
        });

    }

    onScenarioOptionSelection = (event, value) => {
        // process update event
        let updateIndex = JSON.parse(event.target.parentElement.dataset.updateIndex);
        let site = updateIndex.site;
        let simField = updateIndex.simField;
        let updateValue = isNaN(event.target.value) ? event.target.value : parseInt(event.target.value);
        let update = value; // has this been checked or not?

        // if this option has not been checked before, we need to ensure that an array is available
        // to insert the relevant value into.
        if (this.state.siteScenarioSelections[site][simField] == null) {
            this.state.siteScenarioSelections[site][simField] = [];
        }

        if (update) {
            this.state.siteScenarioSelections[site][simField].push(updateValue);
        } else {
            // we need to remove from the array
            // we can't clear the array, as there may be multiple options selected for this simField
            let index = this.state.siteScenarioSelections[site][simField].indexOf(updateValue);
            this.state.siteScenarioSelections[site][simField].splice(index, 1);
        }

        let siteScenarioSelections = this.state.siteScenarioSelections;

        this.setState({
            siteScenarioSelections: siteScenarioSelections
        });
    }

    updateSelection = (site, field, value) => {
        let siteScenarioSelections = this.state.siteScenarioSelections;

        // if this option has not been checked before, we need to ensure that an array is available
        // to insert the relevant value into.
        if (siteScenarioSelections[site][field] == null) {
            siteScenarioSelections[site][field] = [];
        }

        if (value === null) {
            // Drop the selection.
            // we need to remove from the array
            // we can't clear the array, as there may be multiple options selected for this simField
            let index = siteScenarioSelections[site][field].indexOf(value);
            siteScenarioSelections[site][field].splice(index, 1);
        } else {
            siteScenarioSelections[site][field].push(value);
        }

        this.setState({
            siteScenarioSelections: siteScenarioSelections
        });
    }

    updateMultipleSelections = (updateArray) => {
        let siteScenarioSelections = this.state.siteScenarioSelections;

        updateArray.forEach(upd => {
            const {site, field, value} = upd;

            // if this option has not been checked before, we need to ensure that an array is available
            // to insert the relevant value into.
            if (siteScenarioSelections[site][field] == null) {
                siteScenarioSelections[site][field] = [];
            }

            if (value === null) {
                // Drop the selection.
                // we need to remove from the array
                // we can't clear the array, as there may be multiple options selected for this simField
                let index = siteScenarioSelections[site][field].indexOf(value);
                siteScenarioSelections[site][field].splice(index, 1);
            } else {
                siteScenarioSelections[site][field].push(value);
            }
        });

        this.setState({
            siteScenarioSelections: siteScenarioSelections
        });
    }

    loadScenarioOptions = (site, crop) => {
        // this is where we would load scenario options in for a site and crop provided.
        // we'd also want to make sure we set a "loading" flag in state here in case we wanted to show a loading element.
        let self = this;
        scenarioOptionsDataHelper.scenarioOptionsFromAPI(site, crop)
            .then(function(response) {
                self.state.scenarioOptions[site] = response.data.options;
                // console.log('ScenarioSelectionStep options', response.data.options );
                if (self.props.soilSettings[site].pawc) {
                    // we don't want PAWC as an available factor if we already have it specified for a site
                    self.state.scenarioOptions[site] = self.state.scenarioOptions[site].filter(factor => factor.key !== "PAWC")
                }
                let changedScenarioOptions = self.state.scenarioOptions;
                self.setState({
                    scenarioOptions: changedScenarioOptions,
                    loadingState: 'success',
                });

                const factors = changedScenarioOptions[site];
                let updates = [];
                factors.forEach(factor => {
                    if (factor.values.length === 1) {
                        // Single value for this one - autoselect it.
                        const myUpdate = {site, field: factor.key, value: factor.values[0]};
                        updates.push(myUpdate);
                    }
                });
                if (updates.length > 0) {
                    self.updateMultipleSelections(updates);
                }
            })
            .catch(function(error) {
                self.setState({
                    loadingState: 'error',
                    error: error,
                });
            })
    };

    isScenarioOptionSelected = (site, simField, option) => {
        let selectedOptions = this.state.siteScenarioSelections[site][simField];
        if (selectedOptions == undefined) {
            return false;
        } else if (selectedOptions.indexOf(option) !== -1) {
            return true;
        }
        return false;
    }

    onCropSelection = (event, value) => {
        // process update event
        // set the state so know what crop has been set for what site
        // trigger the load of scenario options for relevant site + crop combo
        let eventContext = JSON.parse(event.target.value);
        let site = eventContext.site;
        let crop = eventContext.value;

        // we need to do three things when a crop is selected:
        // 1 - ensure we store the selection in state
        // 2 - clear any options that might have been chosen for this site, but with a different crop
        // 3 - load the scenario options for this site + crop combo and store into state
        this.state.siteCropSelection[site] = crop;
        this.state.siteScenarioSelections[site] = {}; // we are proactively clearing the previous options set
        if (crop !== "") {
            // we don't want to trigger an API call if crop is empty!
            this.loadScenarioOptions(site, crop);
        }

        let changedSiteCropSelection = this.state.siteCropSelection;
        let changedSiteScenarioSelection = this.state.siteScenarioSelections;

        this.props.handleCropSelect(crop);

        this.setState({
            siteCropSelection: changedSiteCropSelection,
            siteScenarioSelections: changedSiteScenarioSelection,
        });
    }

    getCropSelectionValue = (site, element) => {
        return JSON.stringify({
            site: site,
            key: "crop",
            action: "update",
            value: element
        });
    }

    isValidScenarioPresent = (site) => {
        let availableSimFactors = this.state.scenarioOptions[site];
        let selectedSimFactors = this.state.siteScenarioSelections[site];

        // has the user at least selected the name number of scenario options that are available?
        if (availableSimFactors.length == Object.keys(selectedSimFactors).length) {
            // if so, do all of them have a value selected?
            // this can happen if they select and then de-select a value
            let valueCheck = (simFactor) => selectedSimFactors[simFactor].length == 0;
            return !Object.keys(selectedSimFactors).some(valueCheck);
        }
        return false;
    }

    isScenarioLimitReached = (site) => {
        return this.calculateNumberOfScenariosToGenerate(site) + this.props.scenarios.length > configHelper.MAX_SCENARIOS;
    }

    calculateNumberOfScenariosToGenerate = (site) => {
        let selectedSimFactors = this.state.siteScenarioSelections[site];
        let numberOfScenarios = 0;
        Object.keys(selectedSimFactors).forEach((factor) => {
            let scenarioModifier = selectedSimFactors[factor].length;
            if (numberOfScenarios == 0) {
                numberOfScenarios += scenarioModifier;
            } else {
                // the number of scenarios grows exponentially
                // based on the number of scenario options chosen
                numberOfScenarios = numberOfScenarios * scenarioModifier;
            }
        });
        return numberOfScenarios;
    }

    getCropSeason = (site, crop) => {
        let result = null;

        if (this.state.siteAvailableCrops.hasOwnProperty(site)) {
            const availCrops = [...this.state.siteAvailableCrops[site]];
             const myCrop = availCrops.find(ac => ac.crop === crop);
            if (myCrop) {
                result = myCrop.season;
            }
        }
        return result;
    }

    onScenarioAdd = (site, crop) => {
        // bubble up this event to ScenarioBuilder
        // all scenarios will be calculated and added at that level
        let selectedCrop = JSON.parse(crop).value;
        let cropSeason = this.getCropSeason(site, selectedCrop);
        let selectedSimFactors = this.state.siteScenarioSelections[site];
        this.props.handleScenarioAddition(site, selectedCrop, selectedSimFactors, cropSeason);

        // also ensuring we are clearing the current crop selection
        this.state.siteCropSelection[site] = null;
        let changedSiteCropSelection = this.state.siteCropSelection;
        this.setState({
            siteCropSelection: changedSiteCropSelection,
            selectedSeason: cropSeason,
        });


    }

    parseSoilTypeLabel = (soil, site) => {
        // This is also defined within SoilTypeSelectionStep.js
        // This could be split out into it's own unique component.
        let soilNameSplit = soil.split('_PAWC')[0].split('_');
        let soilName = soilNameSplit.map( val => val.charAt(0).toUpperCase() + val.substr(1)).join(" ");
        let pawc_value;
        if (soil.split('_PAWC').length > 1) {
            pawc_value = soil.split('_PAWC')[1].trim();
            soilName = soilName + " (PAWC: " + pawc_value + ")";
        } else if (this.props.soilSettings[site].pawc) {
            pawc_value = this.props.soilSettings[site].pawc;
            soilName = soilName + " (PAWC: " + pawc_value + ")";
        }
        return soilName;
    }

    componentDidMount() {
        var self = this;
        var apiCalls = [];
        // The API endpoint for siteOptions has been designed to take in information
        // for a specific site.
        // However, even though we don't support it at the moment, this step
        // could realistically have multiple sites present.
        this.props.sites.forEach(function(site) {
            // I want to be prepared for this situation, so what we are doing is
            // proactively making the API call for each site that exists in the props
            // passed to this component.
            apiCalls.push(
                siteOptionsDataHelper.siteOptionsFromAPI(site)
            );
        });

        // We are chaining all of these API calls together in the one Promise.all()
        // So once they are all complete, we can take the data from each API call
        // and add to a state property which can then be accessed when performing the render.
        Promise.all(apiCalls).then((values) => {
            var availableCrops = {};
            for (var i=0; i < values.length; i++) {
                var site = this.props.sites[i];
                availableCrops[site] = values[i].data.crops;
                // TODO: What do we do in the case of an error occurring here instead of
                // data being available? We don't expect an error, but it could happen!
            }
            self.setState({
                siteAvailableCrops: availableCrops,
                // crops: availableCrops[site].map(m => m.crop),
            });
        }).catch((error) => {
            // I am not setting loadingState to 'error' here as this call has likely
            // already successfully completed in the step before (in SiteSelectionStep)
            // For the API calls above, we would be pulling from cache.
            // If something really does go wrong, we should see it in console, but it
            // should be unlikely for this to happen.
            // Please get mad at Rhys if it does.
            console.error(error);
        })
    }


    cropsForSeason = (site) => {
        let mySeason = this.state.selectedSeason;
        let myCrops = [];

        if (this.props.scenarios.length === 0) {
            mySeason = null;
        }

        if (this.state.siteAvailableCrops.hasOwnProperty(site)) {
            const availCrops = [...this.state.siteAvailableCrops[site]];
            // console.log(mySeason, availCrops);
            if (mySeason === null || mySeason === '') {
                myCrops = availCrops.map(c => c.crop);
            } else {
                // const filtered = availCrops.filter(c => c.season.toLowerCase() === mySeason.toLowerCase());
                const filtered = availCrops.filter(c => anycaseEquals(c.season, mySeason) || anycaseEquals(c.season, 'both') );
                myCrops = filtered.map(c => c.crop);
            }
        }

        return myCrops;
    };

    handleTriangularClick = () => {
        this.setState({triDialogOpen: true});
    };

    handleTriangularClose = () => {
        this.setState({triDialogOpen: false});
    };

    handleTriangularSubmit = (triScenario) => {
        // Receives a
        //region  Triangular Distribution Scenarion Object
        //           const triScenario = {
        //             scenarioType: 'td',
        //             minYield,
        //             maxYield,
        //             expYield,
        //             season: season.toLowerCase(),
        //             description,
        //           };
        //endregion

        // Hide the dialog
        this.setState({triDialogOpen: false});

        // Extend to include site and soil selected.
        const mySite = this.props.sites[0];
        const mySoil = this.props.soilSettings[mySite].soil_type;
        const extScenario = {
            ...triScenario,
            site: mySite,
            SoilType: mySoil,
        }

        // Include into the scenarios array.
        this.props.handleTriangularScenario(extScenario);
    };

    triangularButton = () => {
        if (implementTriangularDist) {
            const mySite = this.props.sites[0];
            let tooltip = "Using seasonal data with Adjusted Triangular Probability Distribution for crops not modelled with APSIM";
            return (
              <div>
                  <Tooltip title={tooltip}>
                      <div className={pageStyles.tdContainer}>
                          <IconButton
                            variant="outline" onClick={this.handleTriangularClick} disabled={this.isScenarioLimitReached(mySite)}>
                              <Diversity2/>
                          </IconButton>
                          <div>ATPD</div>
                      </div>
                  </Tooltip>
              </div>
            );
        } else {
            return null;
        }
    };

    render() {
        return (
          <>
            <Box className={sharedStyles.scenarioStepSection}>
                {Object.keys(this.state.siteAvailableCrops).length > 0 ?
                <div>
                    <div className={pageStyles.creationContainer}>
                        <div className={pageStyles.creationLeft}>
                            <Typography variant="h6">
                                Scenario Creation
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                For each site, you can select a crop and additional options to create scenarios.
                                {/* After adding at least one scenario, a list of scenarios will display at the bottom of the page.
                        You can review this list before submitting the list of scenarios. */}
                            </Typography>
                        </div>
                        <div className={pageStyles.creationRight}>
                            {this.triangularButton()}
                        </div>
                    </div>
                    {this.props.sites.map((site) => {
                        let crops = this.state.siteAvailableCrops[site];
                        let selectedCrop = this.state.siteCropSelection[site] ? this.getCropSelectionValue(site, this.state.siteCropSelection[site]) : "";
                        let showScenarioOptions = selectedCrop !== "" && this.state.scenarioOptions[site] !== undefined;
                        let scenarioOptionsArea = null;
                        let listsDone = false;

                        // first and formost, we want an error to show if one exists
                        if (this.state.loadingState == 'error') {
                            scenarioOptionsArea = <LoadingComponent fullwidth={true} state='error' error={this.state.error} />;
                        // otherwise if nothing has been selected, don't show anything
                        } else if (!showScenarioOptions) {
                            scenarioOptionsArea = <div/>;
                        // otherwise if something has been selected and we're loading, let's show the loading bar
                        } else if (this.state.loadingState == 'loading') {
                            scenarioOptionsArea = <LoadingComponent fullwidth={true} state='loading' />;
                        // otherwise let's show the results!
                        } else {
                            scenarioOptionsArea = this.state.scenarioOptions[site].flatMap((factor) => {
                                listsDone = true;
                                const currentValues = factor.values;
                                let sortedValues;
                                if (allDates(currentValues)) {
                                    sortedValues = sortedLikeDates(currentValues);
                                } else if (allNumeric(currentValues)) {
                                    currentValues.sort( function( a , b){
                                        if(a > b) return 1;
                                        if(a < b) return -1;
                                        return 0;
                                    });

                                    sortedValues = [...currentValues];
                                } else {
                                    sortedValues = [...currentValues];
                                }
                                // Code below used to render:
                                // options={factor.values}
                                return (
                                    <CheckboxGroup
                                        key={factor.label}
                                        label={factor.label}
                                        options={sortedValues}
                                        horizontal={true}
                                        labelStyling={{ margin: "1em", marginLeft: "0em" }}
                                        isChecked={(option) => {return this.isScenarioOptionSelected(site, factor.key, option)}}
                                        onChange={this.onScenarioOptionSelection}
                                        updateIndex={JSON.stringify({
                                            "site": site,
                                            "simField": factor.key
                                        })}
                                    />
                                );
                            });
                        }

                        return (
                            <div key={site}>
                                <Box
                                    overflow="auto"
                                    sx={{ border: "1px solid", borderRadius: "15px", flexGrow: 1, marginTop: "0.5em", padding: "1em" }}
                                >
                                    <Typography variant="h5">
                                        {site}
                                    </Typography>
                                    {
                                        this.props.soilSettings[site].soil_type &&
                                            <Typography variant="caption">Soil Type: {this.parseSoilTypeLabel(this.props.soilSettings[site].soil_type, site)}</Typography>
                                    }
                                    <FormGroup row={true} sx={{ marginTop: "0.5em" }}>
                                        {/* Crop */}
                                        <FormControl fullWidth required>
                                            <InputLabel style={{minWidth: '80%'}}>Crop</InputLabel>
                                            <Select
                                                value={selectedCrop}
                                                label="Crop"
                                                onChange={this.onCropSelection}
                                            >
                                                <MenuItem value={this.getCropSelectionValue(site, "")}>
                                                    <em>None</em>
                                                </MenuItem>
                                                { this.cropsForSeason(site).map((element) => {
                                                    return (
                                                        <MenuItem
                                                            key={element}
                                                            value={this.getCropSelectionValue(site, element)}
                                                        >
                                                            {element}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>

                                        {/* Scenario Options - based on site and crop combo */}
                                        {listsDone && (
                                          <div className={pageStyles.instruction}>Select at least on option from each row.</div>
                                        )}

                                        {scenarioOptionsArea}

                                        {showScenarioOptions && <div className={pageStyles.formBottomRow}>
                                            {/* Scenario Subtitles - how many scenarios would this create? */}
                                            <Typography>This configuration will add {this.calculateNumberOfScenariosToGenerate(site, selectedCrop)} scenarios.</Typography>

                                            {this.isScenarioLimitReached(site) &&
                                                <Typography variant="caption">Adding this many scenarios would exceed the scenario limit.</Typography>}

                                            {/* Scenario Options - create scenario button */}
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={!this.isValidScenarioPresent(site) || this.isScenarioLimitReached(site)}
                                                onClick={() => this.onScenarioAdd(site, selectedCrop)}
                                                >
                                                    Add Scenario/s
                                            </Button>
                                        </div>}

                                    </FormGroup>

                                </Box>
                            </div>
                        )
                    })}
                    {/*{this.props.scenarios.length > 0 && <ScenarioList*/}
                    {/*    scenarios={this.props.scenarios}*/}
                    {/*    handleScenarioDeletion={this.props.handleScenarioDeletion}*/}
                    {/*/>}*/}
                    {this.props.scenarios.length > 0 && <ScenarioList2
                      scenarios={this.props.scenarios}
                      handleScenarioDeletion={this.props.handleScenarioDeletion}
                    />}
                </div> :
                <LoadingComponent/>
                }
            </Box>
            <TriangularDialog
              onClose={this.handleTriangularClose}
              open={this.state.triDialogOpen}
              onSubmit={this.handleTriangularSubmit} DefaultSeason={this.state.selectedSeason} />
          </>
        )
    }
}

ScenarioSelectionStep.propTypes = {
    sites: PropTypes.array,
    soilSettings: PropTypes.object,
    handleScenarioAddition: PropTypes.func,
    handleScenarioDeletion: PropTypes.func,
    scenarios: PropTypes.array,
    handleTriangularScenario: PropTypes.func,
    handleCropSelect: PropTypes.func,
};

ScenarioSelectionStep.defaultProps = {
    handleTriangularScenario: () => {},
    handleCropSelect: () => {},
};

export default ScenarioSelectionStep;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import configHelper from '../data/configHelper';

class ResultTable extends Component {

    constructor(props) {
        super(props);
        this.columnOrder = [
            'Index',
            // element at the bottom of this array will appear as the first column
        ];
        this.excludedColumns = [
            'units',
            'fullDescription',
            'cumulative_distribution_data',
            'probability_exceedance_data',
            'yearly_data',
            'phase',
            'phase_years_applied',
            'original_index',
            'ScenarioType',
            'modifier_applied',
            'seasonal_years',
            'drought_years',
            'Season', 'season',
            'sample_size',
            ...configHelper.GROSS_MARGIN_FIELDS,
        ];
    }

    getTableHeaders() {
        let tableHeaderSet = new Set();
        this.props.scenarios.forEach((scenario) => {
            let {data, ...partialScenario} = scenario;
            let scenarioData = data;
            Object.keys(partialScenario).forEach((prop) => {
                if (this.excludedColumns.indexOf(prop) === -1) {
                    tableHeaderSet.add(prop);
                }
            });
            Object.keys(scenarioData[this.props.dataField]).forEach((prop) => {
                if (this.excludedColumns.indexOf(prop) === -1) {
                    tableHeaderSet.add(prop);
                }
            });
        });

        let tableHeaderArray = Array.from(tableHeaderSet);
        tableHeaderArray.sort((a, b) => this.columnOrder.indexOf(b) - this.columnOrder.indexOf(a));

        return tableHeaderArray;
    }

    getTableRows() {

        let rows = [];
        let tableHeaders = this.getTableHeaders();

        this.props.scenarios.forEach((scenario) => {
            let row = [];
            let scenarioData = scenario.data[this.props.dataField];
            tableHeaders.forEach((header) => {
                if (scenario.hasOwnProperty(header)) {
                    let value = scenario[header];
                    row.push(value);
                } else if (scenarioData.hasOwnProperty(header)) {
                    let value = scenarioData[header];
                    row.push(value);
                } else {
                    row.push('-'); // don't want empty values in table
                }
            });
            rows.push(row);
        });

        return rows;
    }

    getScenarioTypes() {

        let types = [];
        this.props.scenarios.forEach((scenario) => {
            let scenarioType = scenario.ScenarioType || 'Unknown';
            types.push(scenarioType);
        });
        return types;

    }

    render() {
        let scenarioTypes = this.getScenarioTypes();
        return (
            <Box
                overflow="auto"
                sx={{ border: "1px solid", borderRadius: "15px", flexGrow: 1, marginTop: "1em", padding: "1em" }}
                >
                <Typography variant="caption">
                    {this.props.scenarios.length} in total
                </Typography>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {this.getTableHeaders().map((header, hIndex) => {
                                    const cellKey = `hc_${hIndex}`;
                                    if (header === 'Index') {
                                        return (
                                          <TableCell style={{position: "sticky", left: 0, backgroundColor: "white"}}
                                            align="center" key={cellKey}>
                                              {header}
                                          </TableCell>
                                        );
                                    } else {
                                        return (
                                          <TableCell align="center" key={cellKey}>{header}</TableCell>
                                        );
                                    }
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.getTableRows().map((row, index) => {
                            let styling = { '&:last-child td, &:last-child th': { border: 0 } };
                            let scenarioType = scenarioTypes[index];
                            if (scenarioType === 'td') {
                                // if the scenario is a triangular distribution, we want a special background color for that
                                styling['background-color'] = configHelper.TRIANGULAR_DISTRIBUTION_ROW_COLOR;
                            }
                            return (
                                <TableRow
                                    key={index}
                                    sx={styling}
                                >
                                    {row.map((value, cellIndex) => {
                                        const cellKey = `c_${index}_${cellIndex}`;
                                        if (cellIndex === 0) {
                                            return (
                                              <TableCell style={{position: "sticky", left: 0, backgroundColor: "white"}}
                                                align="center" key={cellKey}>
                                                  {value}
                                              </TableCell>
                                            );
                                        } else {
                                            return (
                                              <TableCell align="center" key={cellKey}>{value}</TableCell>
                                            );
                                        }
                                    })}
                                </TableRow>
                            );
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }
}

ResultTable.propTypes = {
    scenarios: PropTypes.arrayOf(PropTypes.object),
    dataField: PropTypes.string,
}

export default ResultTable;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";

import { Box, Button, Stepper, Step, StepLabel } from '@mui/material';

import SiteSelectionStep from './SiteSelectionStep';
import SoilTypeSelectionStep from './SoilTypeSelectionStep';
import ScenarioSelectionStep from './ScenarioSelectionStep';
import {
  selectSites,
  selectSite,
  selectSiteName,
  selectPAWC,
  selectSoilType,
  updateSoilType,
  updatePAWC,
  updateSite,
  updateCrop,
  clearCurrentSite} from "../features/croparmSlice";

const steps = ["Sites", "Soil Types", "Scenarios"]
const singleSiteOnly = true;

const ScenarioBuilder = (props) => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [sites, setSites] = useState([]);
    const [siteIds, setSiteIds] = useState([]);
    // const [soilSettings, setSoilSettings] = useState({});
    const [scenarios, setScenarios] = useState([]);
    const [cropSeason, setCropSeason] = useState(null);

    const allSites = useSelector(selectSites);
    const currentSiteId = useSelector(selectSite);
    const currentSiteName = useSelector(selectSiteName);
    const currentSoilType = useSelector(selectSoilType);
    const currentPAWC = useSelector(selectPAWC);

    const totalSteps = steps.length;

    const handleSubmit = (event) => {
        event.preventDefault();
        props.submitScenarios(scenarios, [], true);
    };

    const handleNext = (event) => {
        const newStep = activeStep + 1;
        setActiveStep(newStep);
    };

    const handlePrevious = (event) => {
        const newStep = activeStep - 1;
        setActiveStep(newStep);
    };

    const handleSiteSelection = (site, value) => {
      if (currentSiteName !== site) {
          dispatch(clearCurrentSite({}));
          setScenarios([]);
      }

      if (value) {
        dispatch(updateSite({name: site}));
      }
    };

    const handleCropSelection = (crop) => {
        const lcCrop = crop.toLowerCase().trim();
        dispatch(updateCrop(lcCrop));


    };

    const handleSoilSelection = (eventValue) => {
        // This is a generic function designed to accept any soilSetting adjustment
        // for a particular site.
        // We will be parsing an eventValue such as
        //  {
        //     "site": "Dalby,QLD",
        //     "soil_type": "Veritsol",
        //     "action": "update",
        //     "pawc": 100 // this could also be null
        //   }
        let eventContext = JSON.parse(eventValue);
        let site = eventContext.site;
        let soil_type = eventContext.soil_type;
        let action = eventContext.action;
        let pawc = eventContext.pawc; // this might be null

        dispatch(updateSoilType(soil_type));
        dispatch(updatePAWC(pawc));
    };

    // Keeping the format of a older version of this object.
    let soilSettings = {};
    if (currentSiteName) {
        soilSettings[currentSiteName] = {
            soil_type: currentSoilType,
            pawc: currentPAWC,
        };
    }

    const handleScenarioAddition = (site, crop, selectedSimFactors, season) => {
        let scenariosToAdd;

        if (season) {
          setCropSeason(season);
        }

        // let's make sure we take into account the overarching soil choices for this site
        if (currentSoilType) {
            scenariosToAdd = [
                {
                    Crop: crop,
                    Site: site,
                    SiteId: currentSiteId,
                    SoilType: currentSoilType,
                    ScenarioType: 'normal',
                    Season: season,
                }
            ];
        } else {
            scenariosToAdd = [
                {
                    Crop: crop,
                    Site: site,
                    SiteId: currentSiteId,
                    PAWC: currentPAWC,
                    ScenarioType: 'normal',
                    Season: season,
                }
            ];
        }

        // We need to calculate all the possible variations of scenarios to be added to our state.
        Object.keys(selectedSimFactors).forEach((factor) => {
            let updatedScenarios = [];
            let simValues = selectedSimFactors[factor];
            simValues.forEach((value) => {
                scenariosToAdd.forEach((scenario) => {
                    let scenarioObj = {...scenario};
                    scenarioObj[factor] = value;
                    updatedScenarios.push(scenarioObj);
                })
            })
            scenariosToAdd = updatedScenarios;
        });

        const scenarioList = [...scenarios, ...scenariosToAdd];
        setScenarios([...scenarioList]);
    };

    const handleScenarioDeletion = (scenarioIndex, allScenarios=false) => {
        let changedScenarioState = [...scenarios];
        let changedCropSeason = cropSeason;

        if (!allScenarios) {
            changedScenarioState.splice(scenarioIndex, 1);
        } else {
            changedScenarioState = [];
        }


        if (changedScenarioState.length === 0) {
            changedCropSeason = '';
        }
        setScenarios([...changedScenarioState]);
        setCropSeason(changedCropSeason);
    };

    const handleTriangularScenario = (triScenario) => {

        // the triScenario we receive is mostly the same to this
        // however, we want to make sure field naming is in-line
        // with other normal scenarios that are added
        let scenarioToAdd = {
            Crop: triScenario.description,
            Site: triScenario.site,
            SoilType: triScenario.SoilType,
            ScenarioType: triScenario.scenarioType, // should be 'td'
            minYield: triScenario.minYield,
            maxYield: triScenario.maxYield,
            expYield: triScenario.expYield,
            season: triScenario.season,
            description: triScenario.description,
            SiteId: triScenario.siteId,
        };

        let scenarioList = [
            ...scenarios,
            scenarioToAdd,
        ];

        setScenarios(scenarioList);
    };

    const confirmValidStepTwo = () => {
        // let sites = this.state.sites;
        // return sites.every((site) => {
        //     // does each site have a soil_type selected?
        //     return (this.state.soilSettings[site].soil_type !== null && this.state.soilSettings[site].soil_type !== "");
        // });
        return (currentSoilType !== null && currentSoilType !== "");
    }

    const getStepContent = (step) => {
        switch(step) {
            case 0:
                return <SiteSelectionStep
                            handleSiteSelection={handleSiteSelection}
                            selectedSite={currentSiteName}
                        />
            case 1:
                return <SoilTypeSelectionStep
                            soilSettings={soilSettings}
                            handleSoilTypeSelection={handleSoilSelection}
                        />
            case 2:
                return <ScenarioSelectionStep
                            sites={[currentSiteName]}
                            soilSettings={soilSettings}
                            handleScenarioAddition={handleScenarioAddition}
                            handleScenarioDeletion={handleScenarioDeletion}
                            scenarios={scenarios}
                            handleTriangularScenario={handleTriangularScenario}
                            handleCropSelect={handleCropSelection}
                        />
            default:
                return null;
        }
    };

    return (
        <div>
            <Stepper activeStep={activeStep}>
                {steps.map((value) => {
                    return (
                        <Step key={value} completed={false}>
                            <StepLabel>{value}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            {getStepContent(activeStep)}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                { activeStep !== 0 &&
                    <Button disabled={activeStep === 0} onClick={handlePrevious}>Previous</Button>
                }
                <Box sx={{ flex: '1 1 auto' }} />
                { activeStep === totalSteps - 1 ?
                    (
                        <Button
                            onClick={handleSubmit}
                            disabled={
                                (activeStep === 2 && scenarios.length === 0)
                            }>Submit</Button>
                    ) :
                    (
                        <Button
                            disabled={
                                (activeStep === 0 && currentSiteName === null) ||
                                (activeStep === 1 && !confirmValidStepTwo())
                            }
                            onClick={handleNext}>
                                Next
                        </Button>
                    )
                }
            </Box>
        </div>
    );

};

ScenarioBuilder.propTypes = {
    submitScenarios: PropTypes.func,
};

export default ScenarioBuilder;

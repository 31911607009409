import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import FAScenarioResults from './FAScenarioResults';
import LoadingComponent from "../../components/LoadingComponent";
import configHelper from '../../data/configHelper';
import axiosFallow from "../../data/axiosFallow";
import FAScenarioBuilder from "./FAScenarioBuilder";

class FAScenarioContainer extends Component {
  constructor(props) {
    super(props);
    this.GROSS_MARGIN_FIELDS = configHelper.GROSS_MARGIN_FIELDS;
    this.state = {
      scenarios: [], // array
      modifiers: [], // array
      scenarioResults: [], // array
      loadingState: '',
      error: {},
    }
  }

  submitScenarios = (scenarios, modifiers=[], fromBuilder=false) => {
    let self = this;
    if (fromBuilder) {
      // let's set the original scenarios into state here
      // we don't want analyis modifications to overwrite this list
      // only to update the scenarioResults in state
      this.setState({ scenarios: scenarios, loadingState: 'loading' });
    } else {
      this.setState({ loadingState: 'loading' });
    }

    axiosFallow.post('/scenarioSubmit', {
      scenarios: scenarios,
      modifiers: modifiers
    }).then(function (response) {
      self.setState({
        modifiers: response.data.modifiers,
        scenarioResults: response.data.scenarios,
        loadingState: 'success'
      });
    })
      .catch(function (error) {
        self.setState({
          modifiers: modifiers,
          loadingState: 'error',
          error: error
        })
        console.error(error);
      });

  }

  clearErrors = () => {
    this.setState({
      loadingState: '',
      error: {},
    });
  }

  clearScenarioResults = () => {
    this.setState({
      scenarioResults: [],
      modifiers: []
    });
  }

  scenarioResultsPresent() {
    return this.state.scenarioResults.length > 0;
  }

  render() {
    return (
      <div>
        {this.state.loadingState === 'loading' &&
          <div>
            <LoadingComponent
              fullwidth={true}
              state='loading'
            />
          </div>
        }
        {this.state.loadingState === 'error' &&
          <div>
            <LoadingComponent
              fullwidth={true}
              dismissable={true}
              state='error'
              error={this.state.error}
            />
          </div>
        }
        <div hidden={this.props.hideBuilder || this.scenarioResultsPresent()}>
          <FAScenarioBuilder
            submitScenarios={this.submitScenarios}
          />
        </div>
        { this.scenarioResultsPresent() &&
          <div>
            <FAScenarioResults
              originalScenarios={this.state.scenarios}
              results={this.state.scenarioResults}
              modifiers={this.state.modifiers}
              clearScenarioResults={this.clearScenarioResults}
              showResults={this.state.loadingState === 'success'}
            />
          </div>
        }
      </div>
    )
  }
}

FAScenarioContainer.propTypes = {
  hideBuilder: PropTypes.bool,
}

export default FAScenarioContainer;

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {selectPrices, selectFertilisers, getPricesStatus, getPricesError} from "../features/pricesSlice";
import {fetchPrices} from "../features/pricesSlice";
import CommodityPrices from "../ra_components/CommodityPrices";
import FertiliserPrices from "../ra_components/FertiliserPrices";
import axiosRotation from "../ra_data/axiosRotation";
import {Typography} from "@mui/material";
import cropHelper from "../ra_Helpers/cropHelper";
import styles from './PriceStep.module.scss';
import stepperStyles from './/RotationStepper.module.scss';
import LoadingComponent from '../ra_components/LoadingComponent';

const PriceStep = (props) => {
  const dispatch = useDispatch();
  const effectDone = useRef(false);
  //const [commodities, setCommodities] = useState([]);
  //const [fertilisers, setFertilisers] = useState([]);
  const [modCommodities, setModCommodities] = useState([]);
  const [modFertilisers, setModFertilisers] = useState([]);
  const commodities = useSelector(selectPrices);
  const fertilisers = useSelector(selectFertilisers);
  const pricesStatus = useSelector(getPricesStatus);
  const error = useSelector(getPricesError);

  useEffect(() => {
    if (effectDone.current === false) {
      if (pricesStatus === 'idle') {
        console.log('Fetching commodity data');
        dispatch(fetchPrices());
      }
    }

    return () => {
      effectDone.current = true;
    }
  }, [dispatch, pricesStatus]);

  const handleCommodityChanges = (newCommoditiesArray) => {
    console.log('New Commodities', newCommoditiesArray);
    setModCommodities([...newCommoditiesArray]);
  };

  const handleFertiliserChanges = (newFertilisersArray) => {
    console.log('New Fertilisers', newFertilisersArray);
    setModFertilisers([...newFertilisersArray]);
  };

  if (pricesStatus === 'loading') {
    return <LoadingComponent State='loading' />
  }

  if (pricesStatus === 'failed') {
    return <LoadingComponent State='error' Error={error} />
  }

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={stepperStyles.shadedTitle}>
          <Typography variant="h6" fontWeight="bold">
            A. Review the considered output prices and input costs (or update to user-defined values):
          </Typography>

        </div>
        <div >
          <Typography variant="h6" >
            Further details can be found at the
            <a style={{marginLeft: "10px"}} href="https://agmargins.net.au/">AgMargins Website.</a>
          </Typography>
          <div className={styles.tableDiv} >
            <CommodityPrices Commodities={commodities} UpdateCommodities={handleCommodityChanges} />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={stepperStyles.shadedTitle}>
          <Typography variant="h6" fontWeight="bold">
            B. Review the considered nitrogen fertiliser costs (or update to user-defined values):
          </Typography>
        </div>
        <div className={styles.tableDiv} >
          <FertiliserPrices Fertilisers={fertilisers} UpdateFertilisers={handleFertiliserChanges} />
        </div>
      </div>
    </div>
  );
}

PriceStep.propTypes = {
  SiteId: PropTypes.number.isRequired,
  SiteName: PropTypes.string.isRequired,
}

export default PriceStep;

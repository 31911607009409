import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import configHelper from '../data/configHelper';

const ScenarioList2 = (props) => {
  const [rowCount, setRowCount] = useState(0);
  const [tableRows, setTableRows] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [scenarioTypes, setScenarioTypes] = useState([]);

  // Add scenario field names here if you don't want them to show in the
  // scenario list table
  const excludedColumns = ['ScenarioType','seasonal_years', 'season', 'Season', 'sample_size'];

  useEffect(() => {
    setTableRows(getTableRows());
    setScenarioTypes(getScenarioTypes());
    setRowCount(tableRows.length);
    setTableHeaders(getTableHeaders());
  }, [props.scenarios]);


  const getTableHeaders = () => {

    let tableHeaderSet = new Set();
    props.scenarios.forEach((scenario) => {
      let scenarioProps = Object.keys(scenario);
      scenarioProps.forEach((prop) => {
        if (excludedColumns.indexOf(prop) === -1) {
          tableHeaderSet.add(prop);
        }
      })
    });

    // let tableHeaderArray = Array.from(tableHeaderSet).sort();
    let tableHeaderArray = Array.from(tableHeaderSet);
    return tableHeaderArray;

  };

  const getTableRows = () => {

    let rows = [];
    let tableHeaders = getTableHeaders();

    props.scenarios.forEach((scenario) => {
      let row = [];
      tableHeaders.forEach((header) => {
        if (scenario.hasOwnProperty(header)) {
          let value = scenario[header];
          row.push(value);
        } else {
          row.push('-'); // don't want empty values in table
        }
      });
      rows.push(row);
    });

    return rows;

  };

  const getScenarioTypes = () => {

    let types = [];
    props.scenarios.forEach((scenario) => {
      let scenarioType = scenario.ScenarioType || 'Unknown';
      types.push(scenarioType);
    });
    return types;

  };

  const onDeleteEvent = (scenarioIndex, allScenarios=false) => {
    setTableRows([]);
    setRowCount(0);

    props.handleScenarioDeletion(scenarioIndex, allScenarios);
  };

  return (
    <Box
      overflow="auto"
      sx={{ border: "1px solid", borderRadius: "15px", flexGrow: 1, marginTop: "0.5em", padding: "1em" }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, paddingTop: "0"}}>
        <Box sx={{ flex: '1 1 auto' }}>
          <Typography variant="h6">
            Created Scenarios
          </Typography>
          <Typography variant="caption">
            {props.scenarios.length} in total - nigel
          </Typography>
        </Box>
        <Chip
          label="Delete All"
          color="error"
          onClick={() => {onDeleteEvent(null, true)}}
          icon={<DeleteIcon />}
          variant="outlined"
        />
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => {
                return (
                  <TableCell key={header} align="center">{header}</TableCell>
                );
              })}
              {/* Adding a custom cell to act as the delete column */}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row, index) => {
              let styling = { '&:last-child td, &:last-child th': { border: 0 } };
              let scenarioType = scenarioTypes[index];
              if (scenarioType === 'td') {
                // if the scenario is a triangular distribution, we want a special background color for that
                styling['background-color'] = configHelper.TRIANGULAR_DISTRIBUTION_ROW_COLOR;
              }
              return (
                <TableRow
                  key={index}
                  sx={styling}
                >
                  {row.map((value, cellIndex) => {
                    return (
                      <TableCell key={value + cellIndex.toString()} align="center">{value}</TableCell>
                    );
                  })}
                  <TableCell>
                    <Chip
                      label="Delete"
                      key={() => {return index.toString() + "_delete"}}
                      onClick={() => {onDeleteEvent(index)}}
                      icon={<DeleteIcon />}
                      variant="outlined"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
};

ScenarioList2.propTypes = {
  scenarios: PropTypes.arrayOf(PropTypes.object),
  handleScenarioDeletion: PropTypes.func,
}

export default ScenarioList2;

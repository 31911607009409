import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Stepper, Step, StepLabel } from '@mui/material';

import FASiteSelectionStep from './FASiteSelectionStep';
import FASoilTypeSelectionStep from './FASoilTypeSelectionStep';
import FAScenarioSelectionStep from "./FAScenarioSelectionStep";

const steps = ["Sites", "Soil Types", "Scenarios"]

class FAScenarioBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalSteps: 3,
      activeStep: 0,
      sites: [],
      soilSettings: {},
      scenarios: [],

    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.submitScenarios(this.state.scenarios, [], true);
  }

  handleNext = (event) => {
    if (this.state.activeStep === 0) {
      if (this.state.sites.lenght > 0) {
        const mySite = this.state.sites[0];
        if (mySite !== '') {
        }
      }
    }
    this.setState({
      activeStep: this.state.activeStep + 1
    });
  }

  handlePrevious = (event) => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  }

  handleSiteSelection = (site, value) => {
    let stateSites = this.state.sites;
    let soilSettings = this.state.soilSettings;
    if (value) {
      stateSites.push(site);
      soilSettings[site] = {
        soil_type: null,
        pawc: null,
      };
    } else {
      stateSites = stateSites.filter(item => item !== site);
      delete soilSettings[site];
    }
    this.setState({
      sites: stateSites,
      soilSettings: soilSettings,
      scenarios: [],
    });
  }

  handleSoilSelection = (eventValue) => {
    // This is a generic function designed to accept any soilSetting adjustment
    // for a particular site.
    // We will be parsing an eventValue such as
    //  {
    //     "site": "Dalby,QLD",
    //     "soil_type": "Veritsol",
    //     "action": "update",
    //     "pawc": 100 // this could also be null
    //   }
    let eventContext = JSON.parse(eventValue);
    let site = eventContext.site;
    let soil_type = eventContext.soil_type;
    let action = eventContext.action;
    let pawc = eventContext.pawc; // this might be null

    if (action === "update") {
      this.state.soilSettings[site]["soil_type"] = soil_type;
      if (pawc !== null) {
        this.state.soilSettings[site]["pawc"] = pawc;
      }
    }

    // Update - 7 Feb 2023
    // This should no longer trigger, but here just in case we
    // re-add components that want to remove these properties.
    if (action === "remove") {
      this.state.soilSettings[site]["soil_type"] = null;
      this.state.soilSettings[site]["pawc"] = null;
    }

    let changedSoilSettings = this.state.soilSettings;

    this.setState({
      soilSettings: changedSoilSettings
    })

  }

  handleScenarioAddition = (site, selectedSimFactors) => {
    let scenariosToAdd;

    // let's make sure we take into account the overarching soil choices for this site
    if (this.state.soilSettings[site].soil_type) {
      scenariosToAdd = [
        {
          Site: site,
          SoilType: this.state.soilSettings[site].soil_type,
          ScenarioType: 'fallow',
        }
      ];
    } else {
      scenariosToAdd = [
        {
          Site: site,
          PAWC: this.state.soilSettings[site].pawc,
          ScenarioType: 'fallow',
        }
      ];
    }

    // We need to calculate all the possible variations of scenarios to be added to our state.
    Object.keys(selectedSimFactors).forEach((factor) => {
      let updatedScenarios = [];
      let simValues = selectedSimFactors[factor];
      simValues.forEach((value) => {
        scenariosToAdd.forEach((scenario) => {
          let scenarioObj = {...scenario};
          scenarioObj[factor] = value;
          updatedScenarios.push(scenarioObj);
        })
      })
      scenariosToAdd = updatedScenarios;
    });

    let scenarioList = [
      ...this.state.scenarios,
      ...scenariosToAdd
    ];

    this.setState({
      scenarios: scenarioList
    });
  }

  handleScenarioDeletion = (scenarioIndex, allScenarios=false) => {
    if (!allScenarios) {
      this.state.scenarios.splice(scenarioIndex, 1);
    } else {
      this.state.scenarios = [];
    }

    let changedScenarioState = this.state.scenarios;

    this.setState({
      scenarios: changedScenarioState
    });
  }

  confirmValidStepTwo() {
    let sites = this.state.sites;
    return sites.every((site) => {
      // does each site have a soil_type selected?
      return (this.state.soilSettings[site].soil_type !== null && this.state.soilSettings[site].soil_type !== "");
    });
  }

  getStepContent(step) {
    switch(step) {
      case 0:
        return <FASiteSelectionStep
          handleSiteSelection={this.handleSiteSelection}
          selectedSites={this.state.sites}
          singleSelectOnly={true}
        />
      case 1:
        return <FASoilTypeSelectionStep
          soilSettings={this.state.soilSettings}
          handleSoilTypeSelection={this.handleSoilSelection}
        />
      case 2:
        return <FAScenarioSelectionStep
          sites={this.state.sites}
          soilSettings={this.state.soilSettings}
          handleScenarioAddition={this.handleScenarioAddition}
          handleScenarioDeletion={this.handleScenarioDeletion}
          scenarios={this.state.scenarios}
        />
    }
  }

  render() {
    return (
      <div>
        <Stepper activeStep={this.state.activeStep}>
          {steps.map((value) => {
            return (
              <Step key={value} completed={false}>
                <StepLabel>{value}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {this.getStepContent(this.state.activeStep)}
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          { this.state.activeStep !== 0 &&
            <Button disabled={this.state.activeStep === 0} onClick={this.handlePrevious}>Previous</Button>
          }
          <Box sx={{ flex: '1 1 auto' }} />
          { this.state.activeStep === this.state.totalSteps - 1 ?
            (
              <Button
                onClick={this.handleSubmit}
                disabled={
                  (this.state.activeStep === 2 && this.state.scenarios.length === 0)
                }>Submit</Button>
            ) :
            (
              <Button
                disabled={
                  (this.state.activeStep === 0 && this.state.sites.length === 0) ||
                  (this.state.activeStep === 1 && !this.confirmValidStepTwo())
                }
                onClick={this.handleNext}>
                Next
              </Button>
            )
          }
        </Box>
      </div>
    );
  }
}

FAScenarioBuilder.propTypes = {
  submitScenarios: PropTypes.func,
};

export default FAScenarioBuilder;

import React,  { Component } from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import analysisDataHelper from '../data/analysisDataHelper';

import componentStyles from './BarGraph.module.scss';

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

class BarGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overlay: 'Local',    // None, Seasonal, National
      // seasonalYears: [],
      hideSeasonalButton: false,
    };
  }

  componentDidMount() {
    console.log('BarGraph Props', this.props);
    if (this.props.WaterBalanceData.length === 0) {
      console.log('hiding button');
      this.setState({hideSeasonalButton: true})
    } else {
      console.log('showing button');
      this.setState({hideSeasonalButton: false})
    }
  }

  // componentDidMount() {
  //   if (this.props.SOIActive) {
  //     this.setState({droughtType: 'None'});
  //   }
  // }

  showNoDroughts() {
    this.setState({overlay: 'None'});
    this.props.OverlayChange('None');
  }

  showSeasonalYears() {
    this.setState({overlay: 'Seasonal'});
    this.props.OverlayChange('Seasonal');
  }

  showLocalDroughts() {
    // console.log('Local Droughts');
    this.setState({overlay: 'Local'});
    this.props.OverlayChange('Local');
  }

  showNationalDroughts() {
    // console.log('National Droughts');
    this.setState({overlay: 'National'});
    this.props.OverlayChange('National');
  }

  // getSeasonalYears() {
  //   let results = [];
  //   if (this.props.WaterBalanceData && this.props.SelectedConditions) {
  //     if (this.props.WaterBalanceData.length > 0) {
  //       results = analysisDataHelper.filteredSeasonalConditions(
  //         this.props.WaterBalanceData,
  //         this.props.SelectedConditions
  //       );
  //     }
  //
  //     this.setState({seasonalYears: [...results]});
  //   }
  // }

  getBarGraphData() {
      return this.props.scenarios.map((scenario, index) => {
          return {
              name: "#" + scenario['Index'],
              data: scenario.data[this.props.dataField]['yearly_data'],
              color: this.getColorOptions(index),
              marker: {
                  enabled: false
              },
          }
      });
  }

  getColorOptions(index) {
      var colors = Highcharts.getOptions().colors; // this only gives us 10 colors, boo!
      if (index > 9) {
          var randomColor = "#" + Math.floor(Math.random()*16777215).toString(16);
          colors.push(randomColor);
      }
      var perShapeGradient = {
          x1 : 0,
          y1 : 0,
          x2 : 1,
          y2 : 0,
      };
      const myStopColour = new Highcharts.Color(colors[index]);
      // return {
      //     linearGradient : perShapeGradient,
      //     stops : [[0, colors[index]], [1, Highcharts.Color(colors[index]).brighten(0.3).get('rgb')]]
      // };
      return {
        linearGradient : perShapeGradient,
        stops : [[0, colors[index]], [1, myStopColour.brighten(0.3).get('rgb')]]
      };
  }

  getUnits() {
      return this.props.scenarios[0].data[this.props.dataField].units;
  }

  getTitle() {
      var fullDescription = this.props.scenarios[0].data[this.props.dataField].fullDescription || this.props.dataField;
      var units = this.getUnits();
      if (fullDescription && units) {
          return [fullDescription, units].join(' - ');
      }
      return fullDescription;
  }

  getDroughtLabel(droughtType) {
    let result = '';

    switch (droughtType.toLowerCase()) {
      case 'seasonal':
        result = 'Selected Seasons';
        break
      case 'local':
        result = 'Local Droughts';
        break
      case 'national':
        result = 'National Droughts';
        break
      default:
        break
    }

    return result;
  }

  getBandColour(droughtType) {
    // default to local droughts colour.
    let bandColour = 'rgba(255,255,255,1)';
    if (droughtType) {
      if (droughtType.toLowerCase() === 'local') {
        bandColour = 'rgba(255,20,20,0.1)';
      }
      if (droughtType.toLowerCase() === 'national') {
        bandColour = 'rgba(255,198,188,0.2)';
      }
      if (droughtType.toLowerCase() === 'seasonal') {
        bandColour = 'rgba(88,220,88,0.1)';
      }
    }

    return bandColour;
  }

  getTextColour(droughtType) {
    let textColour = 'rgba(255,255,255)';
    if (droughtType.toLowerCase() === 'national') {
      textColour = 'rgba(0, 0, 0)';
    }
    if (droughtType.toLowerCase() === 'none') {
      textColour = 'rgba(255, 255, 255)';
    }

    return textColour;
  }

  setupPlotBands (droughts) {
    // Expecting the droughts to be ordered by StartYear property.
    const arryBands = [];

    if (this.state.overlay !== 'None') {
      // let bandColour = 'rgba(255,20,20,0.3)';
      // if (this.state.overlay === 'National') {
      //   bandColour = 'rgba(255,198,188,0.5)';
      // }
      // if (this.state.overlay === 'Seasonal') {
      //   bandColour = 'rgba(80,200,80,0.3)';
      // }
      let bandColour = this.getBandColour(this.state.overlay);

      // const bandColour = 'rgba(255,198,188,0.5)';
      droughts.forEach((dr, index) => {
        let vertOffset = 10;
        if (index % 2 === 1) {
          vertOffset = 25;
        }
        let horizAlign = 'left';
        if (index === droughts.length - 1) {
          horizAlign = 'center';
        }

        if (dr.StartYear === dr.EndYear) {
          dr.EndYear = dr.StartYear + .5;
        }

        const newBand = {
          color: bandColour,
          from: dr.StartYear,
          to: dr.EndYear,
          tooltipText: dr.FriendlyName,
          label: {
            text: dr.FriendlyName,
            align: horizAlign,
            textAlign: horizAlign,
            verticalAlign: 'top',
            y: vertOffset,
            style: {
              color: '#aaaaaa',
              fontWeight: 'bold'
            }
          },
        };

        arryBands.push(newBand);
      });
    }

    return arryBands;
  }

  getPlotBands () {
    let myPlotBands = null;

    // Don't generate plot bands for droughts when we are
    // dealing with SOI Analysis.
    if (!this.props.SOIActive) {

      // if (this.state.overlay === 'Seasonal') {
      //   if (this.props.WaterBalanceData) {
      //     if (this.props.SelectedConditions) {
      //       const localDroughts = analysisDataHelper.getSeasonalConditionsYearsAsDroughts(
      //         this.props.WaterBalanceData,
      //         this.props.SelectedConditions
      //       );
      //       myPlotBands = this.setupPlotBands(localDroughts);
      //     }
      //   }
      // }
      //
      // if (this.state.overlay === 'National') {
      //   // console.log('BarChart getPlotBands', this.props.NationalDroughts);
      //   if (this.props.NationalDroughts) {
      //     myPlotBands = this.setupPlotBands(this.props.NationalDroughts);
      //   }
      // }

      const {overlay} = this.state;
      switch(overlay.toLowerCase()) {
        case 'seasonal':
          if (this.props.WaterBalanceData) {
            myPlotBands = this.setupPlotBands(this.props.WaterBalanceData);
          }
          break;
        case 'national':
          if (this.props.NationalDroughts) {
            myPlotBands = this.setupPlotBands(this.props.NationalDroughts);
          }
          break;
        case 'local':
          if (this.props.LocalDroughts) {
            myPlotBands = this.setupPlotBands(this.props.LocalDroughts);
          }
          break;
      }

    }

    return myPlotBands;
  }

  getChartOptions() {
    let parent = this;
    return {
      chart : {
        type : 'column',
        zoomType : 'x',
        spacingRight : 20,
        events: {
          render: function() {
            const chart = this;
            const droughtType = parent.state.overlay;
            const legendAttr = chart.legend.box.getBBox();
            const legendBox = chart.legend.box;
            const chartLegendX = legendBox.parentGroup.alignAttr.translateX;
            const chartLegendY = legendBox.parentGroup.alignAttr.translateY;
            // Due to rendered issues when the page is resized, the drought
            // buttons have been moved to the left side of the chart.
            const chartButtonPosition = 'left';
            const {x, y, width} = chart.exportingGroup.getBBox();
            const buttonY = y;
            const textY = buttonY + 15;
            const legendWidth = legendAttr.width;
            const legendHeight = legendAttr.height;
            let nationalX;
            let seasonalX;
            let localX;
            let noneX;
            let textX;
            let legendX;
            let legendY;
            if (chartButtonPosition === 'left') {
              textX = 5;
              noneX = textX + 72;
              nationalX = noneX + 55;
              localX = nationalX + 70;
              seasonalX = localX + 75;
              legendX = 10
              legendY = chartLegendY + (legendHeight / 2) - 8;
            } else {
              nationalX = x - width - 60;
              localX = nationalX - 68;
              seasonalX = localX - 68;
              noneX = seasonalX - 54;
              textX = noneX - 65;
              legendX = chartLegendX + legendWidth + 10;
              legendY = chartLegendY + (legendHeight / 2) - 8;
            }
            // const bandColour = parent.state.droughtType === 'National' ? 'rgba(255,198,188,0.5)' : 'rgba(255,20,20,0.5)';
            const bandColour = parent.getBandColour(droughtType);
            const droughtLabel = parent.getDroughtLabel(droughtType);
            const textColour = parent.getTextColour(droughtType);
            // chart.plus = chart.renderer.box()
            chart.drText = chart.renderer.text('Droughts:', textX, textY)
              .attr({zIndex: 8})
              .css({color: '#000000'})
              .add();

            if (!chart.hasOwnProperty("drLabel")) {
              console.log('Creating the label');
              chart.drLabel = chart.renderer.label(droughtLabel, legendX, legendY)
                .attr({
                  fill: bandColour,
                  padding: 2,
                  r: 5,
                  zIndex: 8
                })
                .css({
                  color: parent.getTextColour(droughtType),
                })
                .add();
            }

            chart.drHide = chart.renderer.button('Hide', noneX, buttonY)
              .attr({zIndex: 8, width: 30, height: 10})
              .css({color: '#000000'})
              .on('click', () => {
                parent.showNoDroughts();
                chart.drLabel.attr({
                  text: '',
                  fill: parent.getBandColour('None')
                });
                chart.drLabel.css({color: parent.getTextColour("None")})
              })
              .add();

            chart.drLocal = chart.renderer.button('Local', localX, buttonY)
              .attr({zIndex: 8, width: 50, height: 10})
              .css({color: '#000000'})
              .on('click', () => {
                parent.showLocalDroughts();
                chart.drLabel.attr({
                  text: parent.getDroughtLabel('Local'),
                  fill: parent.getBandColour('Local')
                })
                chart.drLabel.css({color: '#505050'});
              })
              .add();

            chart.drNational = chart.renderer.button('National', nationalX, buttonY)
              .attr({zIndex: 8, width: 45, height: 10})
              .css({color: parent.getTextColour('National')})
              .on('click', () => {
                parent.showNationalDroughts();
                chart.drLabel.attr({
                  text: parent.getDroughtLabel('National'),
                  fill: parent.getBandColour('National')
                });
                chart.drLabel.css({color: '#505050'});
              })
              .add();

            chart.drSeasonal = chart.renderer.button('Seasonal', seasonalX, buttonY)
              .attr({zIndex: 8, width: 50, height: 10})
              .css({color: '#000000'})
              .on('click', () => {
                parent.showSeasonalYears();
                if (chart.drLabel) {
                  chart.drLabel.attr({
                    text: parent.getDroughtLabel('Seasonal'),
                    fill: parent.getBandColour('Seasonal')
                  })
                  chart.drLabel.css({color: '#505050'});
                }
              })
              .add();

            if (parent.state.hideSeasonalButton) {
              chart.drSeasonal.hide();
            } else {
              chart.drSeasonal.show();
            }
          },
          redraw: function() {
            const chart = this;
            if (parent.state.hideSeasonalButton) {
              chart.drSeasonal.hide();
            } else {
              chart.drSeasonal.show();
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      title : {
        text : this.getTitle()
      },
      rangeSelector : {
        selected : 3
      },
      legend : {
        enabled : true
      },
      plotOptions: {
        series: {
          pointWidth: 1,
          pointPlacement: 'between',
        }
      },
      xAxis : {
        enabled : true,
        maxZoom : 2,
        title : {
          text : "Year"
        },
        plotBands: this.getPlotBands(),
      },
      yAxis : {
        title : {
          text : this.getTitle()
        }
      },
      tooltip : {
        headerFormat : '<span style="font-size:10px;font-weight:bold">{point.key}</span><table>',
        pointFormat : '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} ' + this.getUnits() + '</b></td></tr>',
        footerFormat : '</table>',
        shared : true,
        useHTML : true,
        valueDecimals : 2
      },
      series: this.getBarGraphData(),
    }
  }

  render() {
    const chartOptions = this.getChartOptions();
    return (
      <div class={componentStyles.graphContainer}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          constructorType={ 'chart' }
        />
      </div>
    )
  }
}

BarGraph.propTypes = {
  scenarios: PropTypes.arrayOf(PropTypes.object),
  dataField: PropTypes.string,
  NationalDroughts: PropTypes.array,
  LocalDroughts: PropTypes.array,
  OverlayChange: PropTypes.func,
  SOIActive: PropTypes.bool,
  DroughtActive: PropTypes.bool,
  SeasonalConditionsList: PropTypes.array,
  SelectedConditions: PropTypes.array,
  WaterBalanceData: PropTypes.array,      // aka seasonal conditions
};

BarGraph.defaultProps = {
  NationalDroughts: [],
  LocalDroughts: [],
  OverlayChange: () => {},
  SOIActive: false,
  DroughtActive: false,
  SeasonalConditionsList: [],
  SelectedConditions: [],
  WaterBalanceData: [],
};

export default BarGraph;

import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";

import { Box, Typography, Tabs, Tab } from '@mui/material';

import CheckboxGroup from '../components/CheckboxGroup';
import siteDataHelper from '../data/siteDataHelper';
import MapWrapper from '../components/MapWrapper';
import pageStyles from './SiteSelectionStep.module.scss';
import sharedStyles from "./sharedPageStyles.module.scss";
import LoadingComponent from '../components/LoadingComponent';
import {selectSites, selectSite} from "../features/croparmSlice";
import {getSitesStatus, getSitesError} from "../features/croparmSlice";

const SiteSelectionStep = (props) => {
    const mapChild = useRef();
    const [currentState, setCurrentState] = useState('NSW');
    const allSites = useSelector(selectSites);
    const selectedSiteId = useSelector(selectSite);
    const sitesStatus = useSelector(getSitesStatus);
    const sitesError = useSelector(getSitesError);


    const sitesByState = useMemo(() => {
        return siteDataHelper.sitesByState(allSites);
    }, [allSites]);

    const siteLatLongInfo = useMemo(() => {
        return siteDataHelper.siteLatLongInfo(allSites)
    }, [allSites]);

    useEffect(() => {
        if (props.selectedSite) {
            const thisSite = allSites.find(s => s.Name === props.selectedSite);
            if (thisSite) {
                setCurrentState(thisSite.State);
            }
        }
    }, [allSites, props.selectedSite]);

    const onTabChange = (event, value) => {
        setCurrentState(value);
    };

    const onSiteSelection = (event, value) => {
        let locationName = event.target.value;
        let locationCoordinates = siteLatLongInfo.find(element => element.site == locationName);
        mapChild.current.setMapCenter(locationCoordinates.lat, locationCoordinates.lng);

        props.handleSiteSelection(locationName, value);
    }

    const isChecked = (site) => {
        return props.selectedSite === site;
    }

    const isDisabled = (site) => {
        // For the moment no sites will be disabled. Selecting a site
        // will now automatically deselect all others.
        return false;
    }

    const handleMapIconClick = (event, value) => {
        console.log(value);
        const {site, state, selected} = value;

        // If this site is already selected do nothing.
        if (selected) {
            return;
        } else {
            props.handleSiteSelection(site, true);
        }

        // let us also change the active tab group, so the proper state is highlighted
        // when the map icon has been clicked
        onTabChange(null, state);
    }

    if (sitesStatus === 'loading') {
        return (
          <LoadingComponent state='loading' />
        );
    }

    if (sitesStatus === 'failed') {
        return (
          <LoadingComponent state='error' error={sitesError} />
        );
    }

    return (
        <Box className={sharedStyles.scenarioStepSection}>
            <div>
                <Typography variant="h6">
                    Site Selection
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Select site to analyse.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", alignItems: "stretch" }}>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tabs
                            orientation="vertical"
                            value={currentState}
                            variant="scrollable"
                            onChange={onTabChange}
                            sx={{ borderRight: 1, borderColor: 'divider' }}>
                            {Object.keys(sitesByState).map((state) => {
                                return (
                                    <Tab value={state} key={state} label={state} />
                                )
                            })}
                        </Tabs>
                    </Box>
                    <Box sx={{ flexGrow: 0, flex: 1 }}>
                    {Object.keys(sitesByState).map((state) => {
                        return (
                            <div
                              key={state}
                              hidden={currentState !== state}
                              id={`vertical-tabpanel-${state}`}
                              className={pageStyles.siteSelectionSection}>
                                <Box overflow="auto" sx={{ flexGrow: 1, padding: "0.5em"}}>
                                    <CheckboxGroup
                                        options={sitesByState[state]}
                                        horizontal={false}
                                        isChecked={isChecked}
                                        isDisabled={isDisabled}
                                        onChange={onSiteSelection}
                                    />
                                </Box>
                            </div>
                        )
                    })}
                    </Box>
                    <Box overflow="auto" sx={{ flexGrow: 2, padding: "0.5em"}} className={pageStyles.hiddenMobile}>
                        <MapWrapper
                            ref={mapChild}
                            markerPoints={siteLatLongInfo}
                            handleIconClick={handleMapIconClick}
                            selectedSites={[props.selectedSite]}
                            // center={mapCenter}
                        />
                    </Box>
                </Box>
                <Box sx={{ width: "100%", marginTop: "1em", color: 'grey', textAlign: 'center' }}>
                    If some of the usual sites are missing, they are probably being updated and will be available again shortly.
                </Box>
                <Box sx={{ width: "100%", marginTop: "1em" }}>
                    <Typography variant="body1">
                        Site Selected: {props.selectedSite}
                    </Typography>
                </Box>
            </div>
        </Box>
    )
};

SiteSelectionStep.propTypes = {
    handleSiteSelection: PropTypes.func,
    selectedSite: PropTypes.string,
};

export default SiteSelectionStep;

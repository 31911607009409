import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import ScenarioBuilder from './ScenarioBuilder';
import ScenarioResults from './ScenarioResults';
import LoadingComponent from '../components/LoadingComponent';
import axiosClient from '../data/axiosClient';
import configHelper from '../data/configHelper';
import analysisDataHelper from "../data/analysisDataHelper";
import {anycaseEquals} from "../functions";
import _ from 'lodash';

class ScenarioContainer extends Component {
    constructor(props) {
        super(props);
        this.GROSS_MARGIN_FIELDS = configHelper.GROSS_MARGIN_FIELDS;
        this.state = {
            scenarios: [], // array
            modifiers: [], // array
            scenarioResults: [], // array
            nationalDroughts: [],   // array of drought objects
            localDroughts: [],      // array of years
            waterBalanceData: [],   // array of years
            cropSeasons: [],
            loadingState: '',
            error: {},
        };
    }

    componentDidMount() {
        var self = this;
        analysisDataHelper.getDroughtsList()
          .then(function(response) {
              try {
                  const droughts = response.data.droughts;

                  // sort droughts using the Start Year of each drought.
                  droughts.sort((a,b) => parseInt(a.StartYear) - parseInt(b.StartYear));

                  self.setState({
                      nationalDroughts: [...droughts],
                      loadingState: false,
                  });
              }
              catch (err) {
                  console.log('Error sorting national droughts');
                  console.log(err);
              }
          })
          .catch(function(error) {
              // TODO: What do we do in the case of an error here?
              // Not particularly expected...
          });
        analysisDataHelper.getCropSeasonList()
          .then(function(response) {
              try {
                  const crops = response.data.crops;
                  self.setState({
                      cropSeasons: [...crops],
                      loadingState: false,
                  });
              }
              catch (err) {
                  console.log('Error retrieving crop season lists');
                  console.log(err);
              }
          })
          .catch(function(error) {
              // TODO: What do we do in the case of an error here?
              // Not particularly expected...
          });
    }

    submitScenarios = (scenarios, modifiers=[], fromBuilder=false) => {
        let self = this;
        if (fromBuilder) {
            // let's set the original scenarios into state here
            // we don't want analyis modifications to overwrite this list
            // only to update the scenarioResults in state
            this.setState({ scenarios: scenarios, loadingState: 'loading' });
        } else {
            this.setState({ loadingState: 'loading' });
        }
        axiosClient.post('/scenarioSubmit', {
            scenarios: scenarios,
            modifiers: modifiers
        }).then(function (response) {
            // console.log(response);
            // const formattedDroughts = analysisDataHelper.formatLocalDroughts(response.data.localDroughts);
            // console.log('ScenarioContainer API Results', response.data);
            self.setState({
                modifiers: response.data.modifiers,
                scenarioResults: response.data.scenarios,
                localDroughts: response.data.localDroughts,
                waterBalanceData: response.data.waterBalance,
                loadingState: 'success'
            });
        })
        .catch(function (error) {
            self.setState({
                modifiers: modifiers,
                loadingState: 'error',
                error: error
            })
            console.error(error);
        });

    }

    clearErrors = () => {
        this.setState({
            loadingState: '',
            error: {},
        });
    }

    clearScenarioResults = () => {
        this.setState({
            scenarioResults: [],
            modifiers: []
        });
    }

    getCropSeason = (cropName) => {
        let result = null;
        const crop =  this.state.cropSeasons.find(c => anycaseEquals(c.Crop, cropName));
        if (crop) {
            result = crop.Season;
        }

        return result;
    }

    applySoiAnalysis = (month, phases) => {
        // remove any existing analysis modifiers applied, as we're applying a new version of this analysis.
        let modifiers = this.state.modifiers.filter(modifier => modifier.type !== 'soi');

        if (month && phases.length > 0) {
            modifiers.push({
                type: 'soi',
                month: month,
                phases: phases,
            });
        }

        // we only want to trigger an API call if things are changing here
        if (JSON.stringify(modifiers) !== JSON.stringify(this.state.modifiers)) {
            this.submitScenarios(this.state.scenarios, modifiers);
        }
    }

    applySeasonalAnalysis = (conditions) => {
        // remove any existing analysis modifiers applied, as we're applying a new version of this analysis.
        let modifiers = this.state.modifiers.filter(modifier => modifier.type !== 'drought' && modifier.type !== 'seasonal');

        const allConditions = analysisDataHelper.getSeasonalConditionsList();

        let siteId = null;
        if (this.state.scenarios.length > 0) {
            siteId = this.state.scenarios[0].SiteId;
        }

        // Once a crop is selected, the user can only select crops which grow in the same season.
        // Therefore, we can select the crop from the first scenario to determine which season is
        // being considered.
        const crop = this.state.scenarios[0].Crop;
        const cropSeason = this.getCropSeason(crop);

        if (conditions.length > 0) {
            conditions.forEach(condition => {
                const conditionObj = allConditions.find(c => anycaseEquals(c.display, condition));
                if (conditionObj) {
                    modifiers.push({
                        type: 'seasonal',
                        value: condition,
                        lower: conditionObj.lower,
                        upper: conditionObj.upper,
                        season: cropSeason,
                        siteId: siteId,
                    });
                }

            });
        }

        // we only want to trigger an API call if things are changing here
        if (JSON.stringify(modifiers) !== JSON.stringify(this.state.modifiers)) {
            this.submitScenarios(this.state.scenarios, modifiers);
        }
    }

    removeSeasonalAnalysis = () => {
        let modifiers = this.state.modifiers.filter(modifier => modifier.type !== 'seasonal');

        this.submitScenarios(this.state.scenarios, modifiers);
    }

    getDroughtYears = (droughtName, droughts) => {
        let result = [];

        try {
            const dr = droughts.find(d => d.DroughtName === droughtName);
            if (dr) {
                const fromYr = parseInt(dr.StartYear);
                const endYr = parseInt(dr.EndYear);
                for(let i = fromYr; i <= endYr; i++) {
                    result.push(i)
                }
            }
        }
        catch (err) {
            console.log('Error extracting drought year information.')
        }

        return result;
    }

    applyDroughtAnalysis = (droughts) => {
        // remove any existing analysis modifiers applied, as we're applying a new version of this analysis.
        // also remove any previous seasonal condition modifiers.
        let modifiers = this.state.modifiers.filter(modifier => modifier.type !== 'drought' && modifier.type !== 'seasonal');

        if (droughts.length > 0) {
            modifiers.push({
                type: 'drought',
                droughts: droughts,
                subtype: '',
            })
        }
        // we only want to trigger an API call if things are changing here
        if (JSON.stringify(modifiers) !== JSON.stringify(this.state.modifiers)) {
            this.submitScenarios(this.state.scenarios, modifiers);
        }
    }

    applyGrossMarginAnalysis = (updatedScenarios) => {
        // remove any existing analysis modifiers applied, as we're applying a new version of this analysis.
        let modifiers = this.state.modifiers.filter(modifier => modifier.type !== 'grossMargin');

        modifiers.push({
            type: 'grossMargin',
            enabled: true
        });

        // we only want to trigger an API call if things are changing here
        const diffModifiers = _.differenceWith(modifiers, this.state.modifiers, _.isEqual);
        const diffScenarios = _.differenceWith(updatedScenarios, this.state.scenarios, _.isEqual);
        // console.log('DiffModifiers', diffModifiers);
        // console.log('DiffScenarios', diffScenarios);

        if (diffModifiers.length > 0 || diffScenarios.length > 0) {

            this.setState({
                scenarios: updatedScenarios
            });

            this.submitScenarios(updatedScenarios, modifiers);
        }
        // if (JSON.stringify(modifiers) !== JSON.stringify(this.state.modifiers) &&
        //     JSON.stringify(updatedScenarios) !== JSON.stringify(this.state.scenarios)) {
        //     this.setState({
        //         scenarios: updatedScenarios
        //     });
        //     console.log('submit apply Gross Margin');
        //     this.submitScenarios(updatedScenarios, modifiers);
        // }
    }

    removeGrossMarginAnalysis = () => {
        let modifiers = this.state.modifiers.filter(modifier => modifier.type !== 'grossMargin');

        // explicitly removing the gross margin fields from each scenario
        // as we don't want them to show in the results table if the
        // analysis has not been applied
        let updatedScenarios = this.state.scenarios.map((scenario) => {
            this.GROSS_MARGIN_FIELDS.forEach((field) => {
                delete scenario[field];
            })
            return scenario;
        });

        this.setState({
            scenarios: updatedScenarios
        });
        this.submitScenarios(updatedScenarios, modifiers);

    }

    scenarioResultsPresent() {
        return this.state.scenarioResults.length > 0;
    }

    render() {
        if (this.state.loadingState === 'loading') {
            return (
              <div>
                  <LoadingComponent
                    fullwidth={true}
                    state='loading'
                  />
              </div>
            );
        }

        if (this.state.loadingState === 'error') {
            return (
                <div>
                    <LoadingComponent
                      fullwidth={true}
                      dismissable={true}
                      state='error'
                      error={this.state.error}
                    />
                </div>
            );
        }

        return (
            <div>
                <div hidden={this.props.hideBuilder || this.scenarioResultsPresent()}>
                    <ScenarioBuilder
                        submitScenarios={this.submitScenarios}
                    />
                </div>
                { this.scenarioResultsPresent() &&
                    <div>
                        <ScenarioResults
                            originalScenarios={this.state.scenarios}
                            results={this.state.scenarioResults}
                            modifiers={this.state.modifiers}
                            clearScenarioResults={this.clearScenarioResults}
                            applySoiAnalysis={this.applySoiAnalysis}
                            applyDroughtAnalysis={this.applyDroughtAnalysis}
                            applyGrossMarginAnalysis={this.applyGrossMarginAnalysis}
                            removeGrossMarginAnalysis={this.removeGrossMarginAnalysis}
                            applySeasonalAnalysis={this.applySeasonalAnalysis}
                            clearSeasonalAnalysis={this.removeSeasonalAnalysis}
                            showResults={this.state.loadingState === 'success'}
                            Droughts={this.state.nationalDroughts}
                            LocalDroughts={this.state.localDroughts}
                            WaterBalanceData={this.state.waterBalanceData}
                        />
                    </div>
                }
            </div>
        )
    }
}

ScenarioContainer.propTypes = {
    hideBuilder: PropTypes.bool,
}

export default ScenarioContainer;

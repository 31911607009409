import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Training.module.scss';
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import TrainingLine from "./TrainingLine";
import {makeAColour} from "../functions";

// Background images
import Workshops from '../images/Workshops.png';
import Video from '../images/Video.png';

const leftSide = {
  position: 'relative',
};

const TrainingItem = (props) => {
  const effectDone = useRef(false);
  const [useOverlay, setUseOverlay] = useState(false);
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [overlayStyle, setOverlayStyle] = useState(
    {
    position: 'absolute',
    fontSize: '32px',
    fontWeight:'bolder',
  })

  useEffect(() => {
    if (props.Item) {
      if (props.Item?.Location !== '') {
        setUseOverlay(true);
      }

      setup_styles();
    }
  }, []);

  const setup_styles = () => {
    const overlayTemp = {...overlayStyle};

    if (props.Item?.OverlayPosition === 'TL') {
      overlayTemp.top = '10px';
      overlayTemp.left = '10px';
    }
    if (props.Item?.OverlayPosition === 'TR') {
      overlayTemp.top = '10px';
      overlayTemp.right = '10px';
    }
    if (props.Item?.OverlayPosition === 'BL') {
      overlayTemp.bottom = '10px';
      overlayTemp.left = '10px';
    }
    if (props.Item?.OverlayPosition === 'BR') {
      overlayTemp.bottom = '10px';
      overlayTemp.right = '10px';
    }
    overlayTemp.zindex = 4;
    overlayTemp.color = makeAColour(props.Item?.OverlayColour);

    setOverlayStyle({...overlayTemp});
  };

  const getImage = (image) => {
    if (image) {
      let myImage = null;
      if (image.toLowerCase() === 'workshops') {
        myImage = Workshops;
      }
      if (image.toLowerCase() === 'video') {
        myImage = Video;
      }

      return (
        <img
          src={myImage}
          alt="No image available"
          style={{height: "300px"}}
          zindex={0}
        />
      );
    } else {
      return null;
    }
  };

  const render_LeftSide = () => {
    // const imageFile = "../images/" + props.Item.BackgroundImage;
    // const imageName = require(imageFile)

    return (
      <div style={{...leftSide}}>
        <Tooltip title={props.Item?.ToolTip}>
          {getImage(props.Item.BackgroundImage)}
          <div style={{...overlayStyle}}>
            {props.Item?.Location}
          </div>
        </Tooltip>
      </div>
    );

  };

  const render_details = () => {
    if (props.Item) {
      const {Title, TrainingDate, TrainingTime, Cost, Venue, Registration} = props.Item;
      const itemURL = props.Item.URL;

      let formattedDate = '';
      if (TrainingDate) {
        const date = new Date(TrainingDate);

        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = date.getFullYear();
        var month = months[date.getMonth()];
        var dateVal = date.getDate();

        formattedDate = dateVal + '-' + month + '-' + year;
      }

      let timeLabel = 'Time: ';
      if (TrainingTime.toLowerCase().indexOf('minute') !== -1) {
        timeLabel = 'Duration: ';
      }
      if (TrainingTime.toLowerCase().indexOf('hour') !== -1) {
        timeLabel = 'Duration: ';
      }

      if (Venue.indexOf(',') !== -1) {
        const venueParts = Venue.split(',');
      }

      return (
        <div key={`item_${props.Item.id}`} >
          <TrainingLine Label='Title:' Value={Title} Index={props.Item.id} />
          {props.Item.TrainingDate && <TrainingLine Label='Date:' Value={formattedDate} Index={props.Item.id} />}
          {props.Item.TrainingTime && <TrainingLine Label={timeLabel} Value={TrainingTime} Index={props.Item.id} />}
          {props.Item.Cost && <TrainingLine Label='Cost:' Value={Cost} Index={props.Item.id} />}
          {props.Item.Venue && <TrainingLine Label='Venue:' Value={Venue} Index={props.Item.id} />}
          {props.Item.Registration && <TrainingLine Label='Registration:' Value={Registration} Index={props.Item.id} />}
          <TrainingLine Label='' Value={itemURL} IsURL={true} Index={props.Item.id} />
        </div>
      );
    }
  };

  return (
    <div className={styles.itemContainer} >
      {render_LeftSide()}
      {/*<div className={styles.leftSide}>*/}
      {/*  {render_LeftSide()}*/}
      {/*</div>*/}
      <div className={styles.rightSide}>
        {render_details()}
      </div>
    </div>
  );
}

TrainingItem.propTypes = {
  Item: PropTypes.object.isRequired,
}

export default TrainingItem;

import React, { useEffect, useState } from 'react';
import styles from './LogoSection.module.scss';
import PropTypes from 'prop-types';

import usqLogo from '../images/usq-logo-black.png';
import qldGovLogo from '../images/qg_logo_mar.png';

const logoDetails = [
    {name: 'USQ', image: usqLogo, URL: "http://www.usq.edu.au/", alt: "University of Southern Queensland" },
    {name: 'QGov', image: qldGovLogo, URL: "http://www.daff.qld.gov.au/", alt: "Queensland Government Department of Agriculture and Fisheries" },
];


const LogoSection = (props) => {
    const [logoList, setLogoList] = useState([]);

    useEffect(() => {
        const result = [];
        if (props.Logos) {
            // Default logos is none specified.
            if (props.Logos.length !== 0) {
                props.Logos.forEach(logo => {
                    const xLogo = logoDetails.find(ld => ld.name === logo);
                    if (xLogo) {
                        result.push(xLogo);
                    }
                });

                setLogoList([...result]);
            }
        }

    }, [props.Logos]);

    return (
        <div className={styles.container}>
            {logoList.map(logo => (
                <a href={logo.URL} key={logo.name} target="_blank" rel="noreferrer">
                    <img
                        src={logo.image}
                        className={styles.logo}
                        alt={logo.alt}
                    />
                </a>
            ) )}
        </div>
    )

}

LogoSection.propTypes = {
    Logos: PropTypes.array,
};

LogoSection.defaultProps = {
    Logos: ['USQ', 'QGov'],
};

export default LogoSection;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './HomeAppRow.module.scss';
import {useNavigate} from "react-router-dom";
import {Typography} from '@mui/material';

// Expecting an object with this structure:
//   {
//     order: int,
//     name: string,
//     description: string,
//     image: string,
//     link: string,
//   },

const HomeAppRow = (props) => {
  const navigate = useNavigate();

  const navToApp = () => {
    navigate(props.ARMApp.link);
  };

  let image = null;
  if (props.ARMApp.image) {
    image = require("../images/" +  props.ARMApp.image);
  }

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={image} alt={props.ARMApp.name} onClick={() => navToApp()} />
      </div>
      <div className={styles.description}>
        <Typography variant="h6" gutterBottom>
          <div dangerouslySetInnerHTML={{__html: props.ARMApp.description}} onClick={() => navToApp()} ></div>
        </Typography>
      </div>
    </div>
  );
}

HomeAppRow.propTypes = {
  ARMApp: PropTypes.object.isRequired,
}

export default HomeAppRow;

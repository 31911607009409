import React, { useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {updateResultIndicator} from "../features/rotationSlice";
import { Button, Modal, Typography, Box, Divider, Tooltip } from '@mui/material';
import componentStyle from './ResultIndicatorModal.module.scss';
import CheckboxList from "./CheckboxList";
import {anycaseEquals} from "../functions";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '80%',
  overflow: 'auto',
};

const buttonBaseStyle =  {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
};

const ResultIndicatorModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const closeModal = () => {
    setShowModal(false);
  };

  const handleResultIndicatorChange = (result) => {
    // if (result.length > 0) {
    //   dispatch(updateResultIndicator(result[0]));
    // }
    props.OnResultIndicatorChange(props.Index, result[0]);
  };

  const handleClearIndicator = () => {
    setShowModal(false);
    props.OnResultIndicatorChange(props.Index, '');
  };

  const getButtonVariant = () => {
    if (props.CurrentIndicator !== '') {
      return "contained";
    } else {
      return "outlined";
    }
  };

  const nonExcludedIndicators = (indicators, excluded) => {
    let result = [];

    if (indicators) {
      result = [...indicators];

      if (excluded) {
        const nonExcluded = indicators.filter(i => excluded.indexOf(i.value) === -1);
        result = [...nonExcluded];
      }

    }

    return result;
  };

  const currentList = nonExcludedIndicators(props.SelectableIndicators, props.ExcludeIndicators);

  // The value of currentIndicatorDesc appears on the button.
  let currentIndicatorDesc = "Please select";
  if (props.CurrentIndicator !== '') {
    const indObj = props.SelectableIndicators.find(si => anycaseEquals(si.value, props.CurrentIndicator));
    if (indObj) {
      currentIndicatorDesc = indObj.display;
    }
  }

  if (props.LabelBefore) {
    buttonBaseStyle.flexDirection = 'row';
  } else {
    buttonBaseStyle.flexDirection = 'column';
  }

  if (props.WithBorder) {
    buttonBaseStyle.border = '1px solid #a0a0a0';
    buttonBaseStyle.borderRadius = '1px';
    buttonBaseStyle.padding = '4px';
    buttonBaseStyle.margin = '4px';
  } else {
    buttonBaseStyle.border = 'none';
    buttonBaseStyle.padding = '0px';
    buttonBaseStyle.margin = '0px';
  }

  const showClearIndicatorButton = () => {
    let result = false;
    if (props.CanClear) {
      if (props.CurrentIndicator !== '') {
        result = true;
      }
    }

    return result;
  };

  return (
    <div className={componentStyle.container}>
      <div style={{...buttonBaseStyle}}>
        <div className={componentStyle.buttonHeading} >
          {props.Label}
        </div>
        <Tooltip title="Click to select a different result indicator">
          <Button
            sx={{ml: 1, minWidth: '180px', maxHeight: '32px'}}
            variant={getButtonVariant()}
            disabled={props.Disabled}
            onClick={() => {setShowModal(true)}}
          >
            {currentIndicatorDesc}
          </Button>
        </Tooltip>
      </div>

      <Modal
        open={showModal}
        onClose={() => {setShowModal(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className={componentStyle.header}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Result Indicators
            </Typography>
            <Typography id="modal-modal-description" sx={{mt: 2}}>
              Select a result indicator.
            </Typography>
            <Divider sx={{margin: "1em 0"}}/>
          </div>
          <div className={componentStyle.detail}>
            <CheckboxList
              Options={currentList}
              Columns={1}
              Title=""
              MaxSelectCount={1}
              TitleBackground="#dfdfdf"
              Selected={[props.CurrentIndicator]}
              OnSelect={handleResultIndicatorChange}
              InsideModal={true}
            />
          </div>
          <div className={componentStyle.footer}>
            <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
              {showClearIndicatorButton() && <Button
                variant={"contained"}
                onClick={() => handleClearIndicator()}>
                Clear Indicator
                </Button> }
              <Button
                style={{marginLeft: '10px'}}
                variant={"contained"}
                onClick={() => closeModal()}
              >
                Close
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

ResultIndicatorModal.propTypes = {
  CurrentIndicator: PropTypes.string.isRequired,
  SelectableIndicators: PropTypes.array.isRequired,
  ExcludeIndicators: PropTypes.array,
  Index: PropTypes.number,
  Disabled: PropTypes.bool,
  Label: PropTypes.string,
  LabelBefore: PropTypes.bool,
  WithBorder: PropTypes.bool,
  OnResultIndicatorChange: PropTypes.func,
  CanClear: PropTypes.bool,
};

ResultIndicatorModal.defaultProps = {
  ExcludeIndicators: [],
  Index: -1,
  Label: 'Performance indicator: ',
  LabelBefore: false,
  WithBorder: false,
  CanClear: false,
  OnResultIndicatorChange: () => {},
};

export default ResultIndicatorModal;

import React, { Component } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

class DisclaimerFooter extends Component {
    render() {
        return (
            <Container maxWidth="lg" sx={{ paddingBottom: '1em' }}>
                <Box sx={{ width: '100%' }}>
                    <Link href="/copyright" underline="hover">
                        Copyright
                    </Link>
                    {" | "}
                    <Link href="/disclaimer" underline="hover">
                        Disclaimer
                    </Link>
                    {" | "}
                    <Link href="https://www.unisq.edu.au/privacy/privacy-statement" underline="hover">
                        Privacy
                    </Link>
                    {" | "}
                    <Link href="/acknowledgements" underline="hover">
                        Acknowledgements
                    </Link>
                </Box>
            </Container>
        )
    }
}

export default DisclaimerFooter;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Maintenance.module.scss';

const Maintenance = (props) => {
  let toolDescription = 'item you selected';
  if (props.ToolName !== '') {
    toolDescription = `${props.ToolName} component`;
  }

  let contactDescription = '';
  if (props.Contact !== '') {
    contactDescription = `Please contact ${props.Contact} for further information.`;
  }

  return (
    <div className={styles.container}>
      <div className={styles.sorry}>
        Sorry for any inconvenience, but the {toolDescription} is
      </div>
      <div className={styles.maintenance}>Currently Under Maintenance</div>
      <div className={styles.contact}>
        {contactDescription}
      </div>
    </div>
  );
}

Maintenance.propTypes = {
  ToolName: PropTypes.string,
  Contact: PropTypes.string,
}

Maintenance.defaultProps = {
  ToolName: '',
  Contact: '',
}

export default Maintenance;

import axios from 'axios';

const axiosFallow = axios.create({

  // Production
  baseURL: 'https://armonline.net.au/fallow',

  // Development
  // baseURL: 'http://localhost:3002/',

  timeout: 30000,
  headers: {}
});

export default axiosFallow;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, FormGroup, FormControl, InputLabel, Select, MenuItem, Typography, Divider } from '@mui/material';

import siteOptionsDataHelper from '../data/siteOptionsDataHelper';

import pageStyles from "./SoilTypeSelectionStep.module.scss";
import sharedStyles from "./sharedPageStyles.module.scss";
import LoadingComponent from '../components/LoadingComponent';

class SoilTypeSelectionStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sites: Object.keys(this.props.soilSettings),
            availableSoils: {},
            soilPawcMapping: {},
            loadingState: 'loading',
            error: {},
        }
    }

    onSoilTypeSelection = (event, value) => {
        this.props.handleSoilTypeSelection(event.target.value);
    }

    getSoilTypeSelectionValue = (site, soil_type) => {
        return JSON.stringify({
            site: site,
            soil_type: soil_type,
            action: "update",
            pawc: this.state.soilPawcMapping[site][soil_type] // this might be null
        });
    }

    componentWillMount() {
        var self = this;
        var apiCalls = [];
        // The API endpoint for siteOptions has been designed to take in information
        // for a specific site.
        // However, even though we don't support it at the moment, this step
        // could realistically have multiple sites present.
        this.state.sites.forEach(function(site) {
            // I want to be prepared for this situation, so what we are doing is
            // proactively making the API call for each site that exists in the props
            // passed to this component.
            apiCalls.push(
                siteOptionsDataHelper.siteOptionsFromAPI(site)
            );
        });

        // We are chaining all of these API calls together in the one Promise.all()
        // So once they are all complete, we can take the data from each API call
        // and add to a state property which can then be accessed when performing the render.
        Promise.all(apiCalls).then((values) => {
            var availableSoils = {};
            var soilPawcMapping = {};
            for (var i=0; i<values.length; i++) {
                var site = this.state.sites[i];
                availableSoils[site] = values[i].data.soil;
                soilPawcMapping[site] = values[i].data.pawcSoilMapping;
                // TODO: What do we do in the case of an error occurring here instead of
                // data being available? We don't expect an error, but it could happen!
            }
            self.setState({
                availableSoils: availableSoils,
                soilPawcMapping: soilPawcMapping,
                loadingState: 'success',
            });
        }).catch((error) => {
            self.setState({
                loadingState: 'error',
                error: error,
            });
        });
    }

    render() {
        return (
            <Box className={sharedStyles.scenarioStepSection}>
                {this.state.loadingState == 'loading' &&
                    <div>
                        <LoadingComponent
                            fullwidth={true}
                            state='loading'
                        />
                    </div>
                }
                {this.state.loadingState == 'error' &&
                    <div>
                        <LoadingComponent
                            fullwidth={true}
                            state='error'
                            error={this.state.error}
                        />
                    </div>
                }
                {this.state.loadingState == 'success' &&
                <div>
                    <Typography variant="h6">
                        Soil Type Selection
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Select Soil Type to analyse for the selected site.
                        (Note: PAWC =
                        <span style={{fontWeight: "bold"}}> P</span>lant
                        <span style={{fontWeight: "bold"}}> A</span>vailable
                        <span style={{fontWeight: "bold"}}> W</span>ater
                        <span style={{fontWeight: "bold"}}> C</span>apacity)
                    </Typography>
                    {Object.keys(this.props.soilSettings).map((site) => {
                        let name = site;
                        let soilTypes = this.state.availableSoils[site];
                        let selectedSoilType = this.props.soilSettings[name].soil_type ? this.getSoilTypeSelectionValue(site, this.props.soilSettings[name].soil_type) : "";
                        return (
                            <div key={name}>
                                <Box
                                    overflow="auto"
                                    sx={{ border: "1px solid", borderRadius: "15px", flexGrow: 1, marginTop: "0.5em", padding: "0.5em", paddingBottom: "1em"}}
                                >
                                    <Typography variant="body1" sx={{margin: "0.5em"}}>
                                        {name}
                                    </Typography>
                                    <Divider sx={{width: "100%"}}/>
                                    <FormGroup row={true}>
                                        {/* Soil Type */}
                                        <FormControl fullWidth sx={{ margin: "0 1em", marginTop: "1em" }}>
                                            <InputLabel>Soil Type</InputLabel>
                                            <Select
                                                value={selectedSoilType}
                                                label="Soil Type"
                                                disabled={false}
                                                onChange={this.onSoilTypeSelection}
                                            >
                                                <MenuItem value={this.getSoilTypeSelectionValue(site, "")}>
                                                    <em>None</em>
                                                </MenuItem>
                                                { soilTypes.map((soil) => {
                                                    // We handle how the options will APPEAR here.
                                                    // When selections are made, the value sent through is as is being returned by the API.
                                                    // This is also defined within ScenarioSelectionStep.js
                                                    // This could be split out into it's own unique component.
                                                    let soilNameSplit = soil.split('_PAWC')[0].split('_');
                                                    let soilName = soilNameSplit.map( val => val.charAt(0).toUpperCase() + val.substr(1)).join(" ");
                                                    let label = soilName;
                                                    if (this.state.soilPawcMapping[site].hasOwnProperty(soil)) {
                                                        let pawc_value = this.state.soilPawcMapping[site][soil];
                                                        if (pawc_value) {
                                                            // pawc_value could be blank so we only want to show it if it exists!
                                                            label = soilName + " (PAWC: " + pawc_value.toString() + "mm)";
                                                        }
                                                    }
                                                    return (
                                                        <MenuItem
                                                            key={soil}
                                                            value={this.getSoilTypeSelectionValue(site, soil)}>
                                                                {label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Box>
                            </div>
                        )
                    })}
                </div>
                }
                <Box sx={{ width: "100%", marginTop: "1em" }}>
                    <Typography variant="body1">
                        Total Location Configurations: {Object.keys(this.props.soilSettings).length}
                    </Typography>
                </Box>
            </Box>
        )
    }
}

SoilTypeSelectionStep.propTypes = {
    soilSettings: PropTypes.object,
    handleSoilTypeSelection: PropTypes.func,
}

export default SoilTypeSelectionStep;
